/*
*   File : fund-detail.js
*   Author : https://evoqins.com
*   Description : Fund detail page
*   Version : 1.0.0
*/


// import packages
import { toast } from "react-toastify";
import { Fragment, useEffect, useRef, useState } from "react";
import { Modal } from "bootstrap"
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// import components
import { Header } from "../../Components/Header";
import { Breadcrumb, EmptyScreen, Loader } from "../../Components/Other";
import { AreaChart, GaugeChart, HorizontalStackedBar } from "../../Components/Charts";
import { CustomTabBar } from "../../Components/Tab";
import { CustomAmountInput, CustomSelectBox } from "../../Components/FormElements";
import { FundInvestModal, RiskConfirmationModal, SIPDateModal, RiskKycConfirmationModal } from "../../Components/Modal";
import { LinkButton, PrimaryButton } from "../../Components/Buttons"
import { DataTableContainer } from "../../Components/Table"
import { Calculator } from "../../Components/Calculator"
import { Icon } from "../../Components/Icon";

// import styles
import Color from "../../Styles/color.module.scss";

// import services
import APIService from "../../Services/api-service";
import { _formatSip, _findNearestDate, useWindowSize, _validatingKycStatus, _getTomorrowDate, _reverseDateFormat } from "../../Helper/helper";
import { _checkMandate } from "../../Helper/api";

const TAB_DATA = [
    {
        id: 1,
        label: "Monthly SIP"
    },
    {
        id: 2,
        label: "Lumpsum"
    }
];

const SIP_DATA = [
    {
        id: 1,
        label: "Monthly SIP"
    },
];

const LUMPSUM_DATA = [
    {
        id: 2,
        label: "Lumpsum"
    }
];

const AMOUNT_CHIP = [{
    id: 1,
    label: "+₹10,000",
    amount: 10000
},
{
    id: 2,
    label: "+₹20,000",
    amount: 20000
},
{
    id: 3,
    label: "+₹50,000",
    amount: 50000
}];

// Table style
const HOLDING_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
            backgroundColor: 'transparent'
        },
    },

    headRow: {
        style: {
            backgroundColor: 'transparent',
            borderBottomColor: 'transparent',
            paddingLeft: "0px",
            paddingRight: "0px",
            color: Color.eerie_black,
            fontFamily: "Montserrat-SemiBold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "16px",
            paddingBottom: '0px',

        }
    },
    headCells: {
        style: {
            padding: "0px"
        }
    },

    rows: {
        style: {
            color: Color.eerie_black,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            minHeight: "unset",
            paddingTop: "12px",
            '&:not(:last-child)': {
                padding: '12px 0px 12px 0',
                borderBottom: `1px solid ${Color.chinese_white}`
            },
            backgroundColor: 'transparent',
        },
    },
    cells: {
        style: {
            paddingLeft: '0px !important',
            paddingRight: "0px"
        }
    }

}

const HOLDING_STYLE_MOBILE = {
    table: {
        style: {
            borderRadius: "0px",
            backgroundColor: 'transparent'
        },
    },

    headRow: {
        style: {
            minHeight: "unset",
            backgroundColor: 'transparent',
            borderBottomColor: 'transparent',
            paddingLeft: "0px",
            paddingRight: "0px",
            color: Color.eerie_black,
            fontFamily: "Montserrat-SemiBold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "16px",

        }
    },
    headCells: {
        style: {
            padding: "0px"
        }
    },

    rows: {
        style: {
            color: Color.eerie_black,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            minHeight: "unset",
            paddingTop: "12px",
            '&:not(:last-child)': {
                padding: '10px 0px 10px 0',
                borderBottom: `1px solid ${Color.chinese_white}`
            },
            backgroundColor: 'transparent',
        },
    },
    cells: {
        style: {
            paddingLeft: '0px !important',
            paddingRight: "0px"
        }
    }

}

const PEER_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
            backgroundColor: 'transparent'
        },
    },

    headRow: {
        style: {
            backgroundColor: 'transparent',
            borderBottomColor: 'transparent',
            paddingLeft: "0px",
            paddingRight: "0px",
            color: Color.eerie_black,
            fontFamily: "Montserrat-SemiBold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "16px",
            paddingBottom: '16px',

        }
    },
    headCells: {
        style: {
            padding: "0px"
        }
    },

    rows: {
        style: {
            color: Color.eerie_black,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            minHeight: "unset",
            paddingTop: "12px",
            cursor: "pointer",
            '&:not(:last-child)': {
                padding: '12px 0px 12px 0',
                borderBottom: `1px solid ${Color.chinese_white}`
            },
            backgroundColor: 'transparent',
        },
    },
    cells: {
        style: {
            paddingLeft: '0px !important',
            paddingRight: '0px !important'
        }
    }

}

const PEER_STYLE_MOBILE = {
    table: {
        style: {
            borderRadius: "0px",
            backgroundColor: 'transparent'
        },
    },

    headRow: {
        style: {
            minHeight: "unset",
            backgroundColor: 'transparent',
            borderBottomColor: 'transparent',
            paddingLeft: "0px",
            paddingRight: "0px",
            color: Color.eerie_black,
            fontFamily: "Montserrat-SemiBold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "14px",

        }
    },
    headCells: {
        style: {
            padding: "0px"
        }
    },

    rows: {
        style: {
            color: Color.eerie_black,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            minHeight: "unset",
            paddingTop: "12px",
            cursor: "pointer",
            '&:not(:last-child)': {
                padding: '10px 0px 10px 0',
                borderBottom: `1px solid ${Color.chinese_white}`
            },
            backgroundColor: 'transparent',
        },
    },
    cells: {
        style: {
            paddingLeft: '0px !important',
            paddingRight: '0px !important'
        }
    }

}


const FundDetail = () => {

    const { width } = useWindowSize();
    const location = useLocation();
    const navigate = useNavigate();
    const holdingsRef = useRef(null);

    const SIGNUP_PROGRESS = useSelector(state => state.Reducer.SIGNUP_PROGRESS);
    const PROFILE_DETAILS = useSelector(state => state.Reducer.PROFILE_DETAILS);


    const [fundDetail, setFundDetail] = useState({});
    const [selectedTab, setSelectedTab] = useState(TAB_DATA[0].id);
    const [amount, setAmount] = useState("");
    const [lumpsumAmount, setLumpsumAmount] = useState("");
    const [amountError, setAmountError] = useState("");
    const [lumpsumAmountError, setLumpsumAmountError] = useState("");
    const [toggleDateModal, setToggleDateModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(_getTomorrowDate());
    const [selectedDateString, setSelectedDateString] = useState(_formatSip(_getTomorrowDate()));
    const [fundLoader, setFundLoader] = useState(true);
    const [holdingAllocations, setHoldingAllocations] = useState([]);
    const [filterType, setFilterType] = useState(null);
    const [allTypeReturns, setAllTypeReturns] = useState([]);
    const [selectedTypeReturn, setSelectedTypeReturn] = useState({});
    const [performanceFilter, setPerformanceFilters] = useState([]);
    const [performanceData, setPerformanceData] = useState({});
    const [chartLoader, setChartLoader] = useState(true);
    const [invest, setInvest] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [chartLabels, setChartLabels] = useState([]);
    const [showSection, setShowSection] = useState({
        returnCalculator: false,
        holdings: false,
        ranking: false,
        comparison: false,
        expenseRatio: false,
        fundManagement: false,
        fundHouse: false,
        otherPlans: false,
        riskometer: false,
    });

    const [seeHolding, setSeeHolding] = useState(false);
    const [mfSummary, setMfSummary] = useState({});
    const [mandateLoader, setMandateLoader] = useState(false);
    const [trailingReturnSelected, setTrailingReturnSelected] = useState({});
    const [peerOptions, setPeerOptions] = useState([]);
    const [peerSelected, setPeerSelected] = useState({});
    const [peerMfData, setPeerMfData] = useState([]);
    const [selectedHoldings, setSelectedHoldings] = useState(0);
    const [selectedHoldingsLabel, setSelectedHoldingsLabel] = useState(0);
    const [holdingOptions, setHoldingOptions] = useState([]);
    const [riskCheck, setRiskCheck] = useState(false);
    const [riskKycCheck, setRiskKycCheck] = useState(false);
    const [modalData, setModalData] = useState({});
    const [paymentType, setPaymentType] = useState(null);

    // useEffect(() => {

    //     function resizeChart() {
    //         setChartLoader(true);
    //         setTimeout(() => {
    //             setChartLoader(false);
    //         }, 1000)
    //     }

    //     // Add the resize event listener
    //     window.addEventListener('resize', resizeChart);

    //     // Cleanup function to remove the event listener
    //     return () => {
    //         window.removeEventListener('resize', resizeChart);
    //     };
    // });

    useEffect(() => {
        if (riskCheck === true) {
            const modal = new Modal(document.getElementById("risk-confirm"));
            modal.show();
        }
    }, [riskCheck]);

    useEffect(() => {
        if (invest === true) {
            const modal = new Modal(document.getElementById("fund-invest"));
            modal.show();
        }
    }, [invest]);

    useEffect(() => {
        if (riskKycCheck === true) {
            const modal = new Modal(document.getElementById("risk-kyc-check"));
            modal.show();
        }
    }, [riskKycCheck]);

    useEffect(() => {
        if (location.state !== null) {
            _fundDetail(location.state.data.mf_id);
            window.scrollTo(0, 0);
        }
        else {
            navigate("/explore");
        }

        //eslint-disable-next-line
    }, [location]);

    // useEffect(() => {
    //     if (Object.keys(mfSummary).length > 0) {
    //         setAmount(mfSummary.min_investment.sip);
    //         setLumpsumAmount(mfSummary.min_investment.lumpsum);
    //     }
    // }, [mfSummary])

    // useEffect(() => {
    //     if (location.state !== null) {
    //         _getPerformanceData(location.state.data.mf_id);
    //         console.log(filterType)
    //         setChartLoader(true);
    //     }
    //     //eslint-disable-next-line
    // }, [location, filterType]);

    useEffect(() => {
        if (toggleDateModal === true) {
            const modal = new Modal(document.getElementById("sip-date"), {});
            modal.show();
        }
    }, [toggleDateModal]);

    useEffect(() => {

        const _handleBackNavigation = (event) => {
            setFundLoader(true);
            window.scrollTo(0, 0)
            // // This function will be called when the back button is pressed
            // navigate("/explore", {
            //     state: {
            //         query: location.state.query,
            //         chipList: location.state.chipList,
            //         pageNumber: location.state.pageNumber,
            //         sort: location.state.sort,
            //         recommended: location.state.recommended
            //     }
            // })

            // You can perform additional actions here, such as showing a confirmation dialog
        };

        _handleBackNavigation()
        // Add the event listener
        window.addEventListener('popstate', _handleBackNavigation);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('popstate', _handleBackNavigation);
        };
        //eslint-disable-next-line
    }, []);

    // navigate to home screen
    function _handleParentNavigate() {
        navigate("/");
    }

    // navigate to parent screen
    function _handleCurrentNavigate() {
        navigate("/explore", {
            state: {
                query: location.state.query,
                chipList: location.state.chipList,
                pageNumber: location.state.pageNumber,
                sort: location.state.sort,
                recommended: location.state.recommended
            }
        });
    }

    // handle Returns
    function _handleTabChange(value) {
        setSelectedTab(value);
    }

    // handle amount
    function _handleAmount(input_value) {
        setAmount(input_value);
        setAmountError("");
    }

    function _handleLumpsumAmount(input_value) {
        setLumpsumAmount(input_value);
        setLumpsumAmountError("");
    }

    function _handleChipClick(item) {
        if (selectedTab === 1) {
            setAmount(prevAmount => (prevAmount !== "" ? prevAmount : 0) + (item.amount));
            setAmountError("");
        } else {
            setLumpsumAmount(prevAmount => (prevAmount !== "" ? prevAmount : 0) + (item.amount));
            setLumpsumAmountError("");
        }
    }

    // show sip date modal
    function _handleCloseModal(status) {
        setToggleDateModal(status);

    }

    // handle chart filter
    function _handleFilter(id) {
        setFilterType(id);
        _getPerformanceData(id, fundDetail.mf_id);
    }

    // handle sip date
    function _handleSelectDate(date, dateString) {
        setSelectedDate(date);
        setSelectedDateString(dateString)
    }

    function _handleRowClick(row) {
        setShowSection({
            returnCalculator: false,
            holdings: false,
            ranking: false,
            comparison: false,
            expenseRatio: false,
            fundManagement: false,
            fundHouse: false,
            otherPlans: false,
            riskometer: false,
        })
        setFundLoader(true);
        navigate("/explore/detail", {
            state: {
                data: {
                    mf_id: row.mf_id
                }
            }
        });
        window.scrollTo(0, 0);
    }

    // fundtion get Returns
    function _getReturns(range_value) {
        let return_type = {};

        // eslint-disable-next-line
        return_type = (allTypeReturns.filter((item) => item.year <= range_value)).at(-1)

        // allTypeReturns.map((item) => {
        //     if (range_value <= 1) {
        //         return_type = allTypeReturns[0]
        //     } else if (range_value <= 3) {
        //         return_type = allTypeReturns[1]
        //     }
        //     else if (range_value <= 5) {
        //         return_type = allTypeReturns[2]
        //     }
        //     else if (range_value <= 10) {
        //         return_type = allTypeReturns[3]
        //     }
        //     else {
        //         return_type = allTypeReturns[4]
        //     }
        // });

        setSelectedTypeReturn(return_type)
    }


    // see all holdings
    function _handleSeeHolding() {
        setSeeHolding(!seeHolding);
        if (seeHolding === true) {
            holdingsRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    }

    function _confirmRiskKyc(status) {
        setRiskKycCheck(status)
    }

    // handle return calculator
    function _handleShowReturnCalculator(type) {
        setShowSection(prevState => ({
            ...prevState,
            [type]: !prevState[type]
        }));
    };


    function _handleNavigateToScreen() {
        if (PROFILE_DETAILS.risk_assessment_done === false) {
            navigate("/risk-profile", {
                state: {
                    from_fund: true,
                    id: fundDetail.mf_id,
                    step: 1
                }
            })
        } else if (_validatingKycStatus(SIGNUP_PROGRESS.kyc_data.status) === 1) {
            navigate("/kyc");
        } else if (_validatingKycStatus(SIGNUP_PROGRESS.kyc_data.status) === 2) {
            navigate("/kyc");
        }
    }

    function _investNow(status) {
        if (PROFILE_DETAILS.risk_assessment_done === false) {
            _confirmRiskKyc(true);
            setModalData({
                title: "Complete Risk Profile",
                content: "Please complete your Risk Profile to start with an Investment."
            })
        } else if (_validatingKycStatus(SIGNUP_PROGRESS.kyc_data.status) === 1) {
            _confirmRiskKyc(true);
            setModalData({
                title: "Open an Investment Account Now",
                content: "You are halfway through your account creation. Investment Account need to start with Investment."
            })
        } else if (_validatingKycStatus(SIGNUP_PROGRESS.kyc_data.status) === 4) {
            navigate("/");
        } else if (_validatingKycStatus(SIGNUP_PROGRESS.kyc_data.status) === 2) {
            _confirmRiskKyc(true);
            setModalData({
                title: "Open an Investment Account Now",
                content: "You are halfway through your account creation. Investment Account need to start with Investment."
            })
        } else {
            if (selectedTab === 1) {
                if (mfSummary.mandate_status === false) {
                    _createMandate();
                } else {

                    let valid = true;
                    if (amount === "") {
                        setAmountError("Amount required to continue");
                        valid = false;
                        return;
                    }

                    if (valid) {
                        if (fundDetail.risk_disclaimer_required === true) {
                            setRiskCheck(true)
                        } else {
                            setInvest(status);
                        }
                    }


                }
            }
            else {

                let valid = true;
                if (lumpsumAmount === "") {
                    setLumpsumAmountError("Amount required to continue");
                    valid = false;
                    return;
                }
                if (valid) {
                    if (fundDetail.risk_disclaimer_required === true) {
                        setRiskCheck(true)
                    } else {
                        setInvest(status);
                    }
                }

            }
        }
    }

    // Holding Table columns
    const HOLDING_COLUMN = [
        {
            name: <span className="e-font-14 e-line-height-30">Top Holdings</span>,
            selector: row => <span className="e-font-14">{row.company_name}</span>,
            sortable: false,
            width: "450px"
        },
        {
            name: <span className="e-font-14 e-line-height-30">Sector</span>,
            selector: row => <span className="e-font-14">{row.sector}</span>,
            sortable: false,
            center: true,
            width: "100px"
        },
        {
            name: <span className="e-font-14 e-line-height-30">%</span>,
            selector: row => <span className="e-font-14">{row.percentage + "%"}</span>,
            sortable: false,
            right: true,
            width: "100px"
            // width: "60px",
        },
    ]

    const _handlePeerOptions = (value) => {
        setPeerSelected(value);
    }

    // Peer comparison
    const PEER_COLUMN = [
        {
            name: <span className="e-font-14 e-line-height-30">Funds</span>,
            selector: row => row.name,
            sortable: false,
            cell: row => <div className="d-flex align-items-center gap-8px" onClick={() => _handleRowClick(row)}>
                <img src={row.image}
                    alt={row.name}
                    width={36}
                    height={36}
                    draggable={false}
                    className="border-radius-8px object-fit-contain border-1px border-bright-gray d-sm-block d-none" />
                <img src={row.image}
                    alt={row.name}
                    width={32}
                    height={32}
                    draggable={false}
                    className="border-radius-8px object-fit-contain border-1px border-bright-gray d-sm-none d-block" />
                <p className="mb-0 e-font-14">{row.name}</p>
            </div>,
            width: "60%"
        },
        {
            name: <div className="position-absolute pe-3">

                <div className="d-sm-flex d-none justify-content-end align-items-center gap-8px ">
                    <div className="d-sm-block d-none">
                        <CustomSelectBox placeholder=""
                            value={peerSelected}
                            width={true}
                            options={peerOptions}
                            onSelectChange={_handlePeerOptions} />
                    </div>
                </div>
                <div className="d-sm-none d-flex justify-content-end">
                    <CustomSelectBox placeholder=""
                        value={peerSelected}
                        options={peerOptions}
                        type={2}
                        isSearchable={false}
                        onSelectChange={_handlePeerOptions} />
                </div>
            </div>,
            selector: row => row.data === null ? "-" : row.data,
            sortable: false,
            right: true,
            cell: row => <p className={`e-font-14 pe-3 ${row.data === null ? "color-eerie-black" : peerSelected?.value > 9 ? "color-eerie-black" : row.data < 0 ? "color-deep-carmine-pink" : row.data > 0 ? "color-green" : "color-eerie-black"} mb-0`}>{row.data === null ? "-" : peerSelected?.value > 10 ? row.data : `${row.data}%`}</p>
            // width: "60px",
        }
    ];

    useEffect(() => {
        if (Object.keys(peerSelected).length !== 0 && peerSelected) {
            _peerComparision();
        }
        // eslint-disable-next-line
    }, [peerSelected])

    const _handleTrailingReturn = (value) => {
        setTrailingReturnSelected(value);
    }



    // handle holdings tab
    function _handleHoldingsTab(value, label) {
        setSelectedHoldings(value);
        setSelectedHoldingsLabel(label);
    }

    function _handleInvest() {
        setInvest(true);
        setRiskCheck(false);
    }

    // handle payment type
    function _handlePayment(item) {
        setPaymentType(item);
    }



    function _handleInvested() {
        setFundLoader(true);
        _fundDetail(mfSummary.mf_id)
    }
    // API - fund detail
    const _fundDetail = (mf_id) => {
        const url = "/mf-data/details";
        const request = {
            mf_id: mf_id,
            filter_type: null
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setFundDetail(response.data);
                if (response.data.sip_available === true && response.data.lumpsum_available === true) {
                    setSelectedTab(1);
                } else if (response.data.sip_available === false && response.data.lumpsum_available === true) {
                    setSelectedTab(2);
                } else {
                    setSelectedTab(1)
                }
                setPerformanceFilters(response.data.fund_perf_filters); // filter data for performance chart
                const allocations = response.data.holdings_statistic.map((item) => {
                    return (
                        {
                            name: item.label,
                            allocation: item.value,
                            color: item.color
                        }
                    )
                })

                setHoldingAllocations(allocations);

                if (response.data.fund_perf_filters.length !== 0) { // for adding selected style to selected filter type
                    let filter_type = response.data.fund_perf_filters.filter((item) => item.period === "1Y");
                    if (filter_type.length > 0) {
                        setFilterType(filter_type[0].filter_type);
                        _getPerformanceData(filter_type[0].filter_type, response.data.mf_id);
                    } else {
                        setFilterType(response.data.fund_perf_filters[0].filter_type);
                        _getPerformanceData(response.data.fund_perf_filters[0].filter_type, response.data.mf_id);
                    }

                }
                setAllTypeReturns(response.data.returns_for_calculation);

                let three_year_return = response.data.returns_for_calculation.filter(item => item.period === "1Y");
                let selected_return = {};

                if (three_year_return.length !== 0) {
                    selected_return = three_year_return[0];
                } else {
                    selected_return = response.data.trailing_returns.at(-1);
                }
                setSelectedTypeReturn(selected_return);


                setTrailingReturnSelected(response.data.trailing_returns.filters[0]);
                let peer_options = response.data.peer_comparison_filter.map(item => ({
                    value: item.filter_type,
                    label: item.label
                }));

                setPeerOptions(peer_options);
                const filter_type = peer_options.find(item => item.value === response.data.peer.filter_type)
                if (filter_type !== undefined) {
                    setPeerSelected(filter_type);
                } else {
                    setPeerSelected(peer_options[0]);
                }
                setPeerMfData(response.data.peer.data);

                const holding_tab = response.data.holdings.holdings_headings.map((item, index) => ({
                    id: index,
                    label: item
                }));

                setHoldingOptions(holding_tab);
                setSelectedHoldings(holding_tab[0].id);
                setSelectedHoldingsLabel(holding_tab[0].label);
                _getMFSummary(response.data.mf_id);
            } else {
                navigate("/explore");
            }
            // window.scrollTo({
            //     top: 0,
            //     behavior: 'smooth'
            // });

        });
    }

    // API - performance details of selected fund
    const _getPerformanceData = (filter_type, mf_id) => {
        setChartLoader(true);
        let url = "/mf-data/performance"
        let data = {
            "mf_id": mf_id,
            "filter_type": filter_type,
        };
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                let chart_data = []
                let labels = [];

                chart_data = response.data.datasets.filter((item) => item !== 0);


                const index = response.data.datasets.indexOf(0)
                if (index > -1) {
                    labels = response.data.labels.splice(index)
                } else {
                    labels = response.data.labels
                }
                setChartData(chart_data);
                setChartLabels(labels);
                setPerformanceData(response.data);
            } else {
                setChartData([]);
                setPerformanceData({});
            }
            setChartLoader(false);

        });
    };

    // API - mf summary
    const _getMFSummary = (mf_id, is_mandate) => {
        const url = "/mf/summary";

        const request = {
            mf_id: mf_id
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setMfSummary(response.data);
                setPaymentType(response.data.payment_types[0])
                if (response.data.sip_dates !== null) {
                    setSelectedDate(_findNearestDate(response.data.sip_dates, selectedDate));

                    const date_string = _formatSip(_findNearestDate(response.data.sip_dates, selectedDate));
                    setSelectedDateString(date_string);
                }
                if (is_mandate) {
                    if (response.data.mandate_status === true) {
                        toast.success("Mandates Created Successfully", {
                            type: "success",
                        });
                        setInvest(true);
                    } else {
                        toast.error("Mandate Creation Failed", {
                            type: "error",
                        });
                    }
                    setTimeout(() => {
                        setMandateLoader(false);
                    }, 3000);
                }
            }
            setFundLoader(false);

        });
    }

    // API - Create mandate
    const _createMandate = () => {
        setMandateLoader(true);
        _checkMandate(true, paymentType.slug).then((response) => {
            if (response.status_code === 200) {
                let mandate_window = window.open(response.data.mandate_url, '_blank', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600');
                if (!mandate_window || typeof mandate_window.closed == 'undefined') {
                    toast.dismiss();
                    toast.error("Please enable pop-up permission");
                    return;
                };

                let timer = setInterval(function () {
                    if (mandate_window.closed) {
                        clearInterval(timer);
                        _getMFSummary(mfSummary.mf_id, true);
                    }
                }, 1000);
            } else {
                setMandateLoader(false);
            }
        })
    }

    // API - peer comparision
    const _peerComparision = () => {
        const url = "/mf-data/comparison";
        const request = {
            mf_ids: peerMfData.map(item => item.mf_id),
            filter_type: peerSelected.value
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setPeerMfData(response.data.data);
            } else {
                setPeerMfData([]);
            }
        });
    }

    const ExpenseDetail = (props) => {
        return (
            <Fragment>
                <div className="col-md-6">
                    <p className={`mb-0 e-montserrat-medium e-line-height-30 color-outer-space e-font-16`}>{props.heading}</p>
                </div>
                <div className="col-md-6">
                    <p className={`mb-0 e-montserrat-semi-bold e-line-height-30 color-eerie-black e-font-16`}>{props.detail}</p>
                </div>
                <div className="col-md-6">
                    {props.date && <p className={`mb-0 e-montserrat-medium e-line-height-30 color-outer-space e-font-14`}>as on {props.date}</p>}
                </div>
            </Fragment>
        )
    }

    const RISK_DATA = {
        1: 10,
        2: 26,
        3: 42,
        4: 58,
        5: 74,
        6: 90
    }
    const NavDetail = (props) => {
        return (
            <Fragment>
                <div className="col-md-6">
                    <p className={`mb-0 e-montserrat-medium e-line-height-30 color-outer-space e-font-16`}>{props.heading}</p>
                </div>
                <div className="col-md-6">
                    <p className={`mb-0 e-montserrat-semi-bold e-line-height-30 color-eerie-black e-font-16`}>{props.detail}</p>
                </div>
                <div className="col-md-6">
                    {props.date && <p className={`mb-0 e-montserrat-medium e-line-height-30 color-outer-space e-font-14 `}>as on {props.date}</p>}
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {
                fundLoader === true ?
                    <Loader height="h-80vh" />
                    :
                    <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                        <Header type={2} />
                        <div className="row ">
                            <div className="col-lg-11 pb-5">

                                {/* Header */}

                                {/* Goal list */}
                                <section className="px-4 ">
                                    <Breadcrumb type={2}
                                        mainPage="Home"
                                        currentPage="Explore"
                                        childPage={fundDetail.fund_name}
                                        handleParentNavigate={_handleParentNavigate}
                                        handleCurrentNavigate={_handleCurrentNavigate} />

                                    <div className="row mt-md-4 mt-3">
                                        <div className="col-xl-8 col-lg-10 col-md-10">

                                            {/* Chart section */}
                                            <div className="e-fund-detail-chart border-radius-24px ">
                                                <div className="p-sm-4 p-3">
                                                    <div className="d-flex gap-8px">
                                                        <img src={fundDetail.image}
                                                            width={48}
                                                            height={48}
                                                            alt={fundDetail.title}
                                                            draggable={false}
                                                            className="border-radius-8px object-fit-contain border-1px border-bright-gray d-sm-block d-none" />
                                                        <img src={fundDetail.image}
                                                            width={38}
                                                            height={38}
                                                            alt={fundDetail.title}
                                                            draggable={false}
                                                            className="border-radius-8px object-fit-contain border-1px border-bright-gray d-sm-none d-block" />

                                                        {/* fund name and asset categories */}
                                                        <div className="d-flex gap-8px flex-column">
                                                            <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0">{fundDetail.fund_name}</p>
                                                            <p className="e-montserrat-medium e-font-14 e-line-height-20 mb-0 color-outer-space"><span className="e-type position-relative me-3">{fundDetail.asset_class}</span><span className="e-type position-relative me-3">{fundDetail.scheme_primary_category}</span>{fundDetail.tag}</p>
                                                        </div>
                                                    </div>

                                                    {/* performance chart 
                                                        Note : checking for area chart labels to decide whether or not to show the area chart section
                                                    */}

                                                    {



                                                        <div className="position-relative">
                                                            {
                                                                performanceData.labels !== undefined &&
                                                                <p className={`e-montserrat-semi-bold e-font-28 e-line-height-30 my-sm-4 my-2 ${performanceData.returns.percentage > 0 ? "color-go-green" : performanceData.returns.percentage < 0 ? "color-deep-carmine-pink" : "color-eerie-black"}`}>{performanceData.returns.percentage}% <span className="e-font-16 color-davys-grey"> {performanceData.returns.type} Returns</span></p>
                                                            }

                                                            <div className={`e-goal-chart-height w-100 ${chartLoader === true ? "opacity-2 disabled" : ""}`}>
                                                                <AreaChart data={performanceData}
                                                                    dataset1={chartData}
                                                                    // dataset2={dataset2}
                                                                    labels={chartLabels}
                                                                    borderColor1={Color.rebecca_purple}
                                                                    filters={performanceFilter}
                                                                    tenure={filterType}
                                                                    setYear={_handleFilter} />
                                                            </div>

                                                            {chartLoader === true &&
                                                                <Loader height="h-240px position-absolute top-0 bottom-0 m-auto start-0 end-0 w-100 pe-3 " />
                                                            }

                                                        </div>
                                                    }
                                                </div>

                                                {/* fund basic details */}
                                                <div className=" border-chinese-white border-top-1px p-sm-4 p-3">
                                                    <div className="row">
                                                        <div className="row">
                                                            <div className=" col-6 ">
                                                                <NavDetail heading={`NAV`}
                                                                    date={fundDetail.modified_dates.latest_fund_nav_date}
                                                                    detail={`₹${fundDetail.nav}`} />
                                                            </div>
                                                            <div className=" col-6 ">
                                                                <NavDetail heading={`Fund Size`}
                                                                    date={fundDetail.modified_dates.latest_fund_aum_date}
                                                                    detail={fundDetail.aum} />
                                                            </div>
                                                        </div>
                                                        <div className="row pt-lg-3 pt-2">
                                                            <div className=" col-6 ">
                                                                <NavDetail heading={`Min SIP`}
                                                                    // date={fundDetail.modified_dates.latest_fund_nav_date}
                                                                    detail={fundDetail.min_sip > 0 ? `₹${fundDetail.min_sip.toLocaleString("en-IN")}` : "No SIP Allowed"} />
                                                            </div>
                                                            <div className=" col-6 ">
                                                                <NavDetail heading={`Min Lumpsum`}
                                                                    // date={fundDetail.modified_dates.latest_fund_nav_date}
                                                                    detail={`₹${fundDetail.min_lumpsum.toLocaleString("en-IN")}`} />
                                                            </div>
                                                        </div>
                                                        <div className="row pt-xl-4 pt-lg-3 pt-2">

                                                            <div className="d-flex gap-8px">
                                                                <p className={`mb-0 e-montserrat-medium e-line-height-30 color-outer-space e-font-16`}>Inception Date</p>
                                                                <p className={`mb-0 e-montserrat-semi-bold e-line-height-30 color-eerie-black e-font-16`}>{fundDetail.fund_inception_date == null ? '-' : fundDetail.fund_inception_date}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-xl-none d-block mt-3">
                                                <div className="e-fund-detail-chart border-radius-24px position-sticky top-50px">
                                                    <div className="border-bottom-point5px border-chinese-white px-sm-4 px-3 padding-10px-top">
                                                        <CustomTabBar data={fundDetail.sip_available === false && fundDetail.lumpsum_available === true ? LUMPSUM_DATA :
                                                            fundDetail.sip_available === true && fundDetail.lumpsum_available === false ? SIP_DATA : TAB_DATA}
                                                            selectedTab={selectedTab}
                                                            onSelectTab={_handleTabChange} />
                                                    </div>
                                                    <div className="pt-3 mx-sm-4 mx-3 pb-4">
                                                        {
                                                            selectedTab === 1 ?
                                                                <CustomAmountInput
                                                                    value={amount}
                                                                    key={"SipAmount"}
                                                                    label="Investment Amount"
                                                                    placeholder="50,000"
                                                                    error={amountError}
                                                                    className="mb-4 pb-1"
                                                                    handleChange={_handleAmount} />
                                                                :
                                                                <CustomAmountInput
                                                                    value={lumpsumAmount}
                                                                    key={"LumpsumAmount"}
                                                                    label="Lumpsum Amount"
                                                                    placeholder="50,000"
                                                                    className="mb-4 pb-1"
                                                                    error={lumpsumAmountError}
                                                                    handleChange={_handleLumpsumAmount} />
                                                        }
                                                        <div className="d-flex flex-wrap gap-12px mt-3 mb-3">
                                                            {
                                                                AMOUNT_CHIP.map((item, key) =>
                                                                    <span className="px-2 py-2 border-bright-gray border-1px e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black border-radius-20px cursor-pointer user-select-none"
                                                                        key={key}
                                                                        onClick={() => _handleChipClick(item)}>{item.label}</span>
                                                                )
                                                            }
                                                        </div>
                                                        {
                                                            mfSummary.mandate_status === false && selectedTab === 1 ?
                                                                <Fragment>
                                                                    <h5 className='e-font-14  color-eerie-black e-montserrat-semi-bold e-line-height-22 mb-0'>
                                                                        {selectedTab === 1 ? "Mandate Method" : "Payment Method"}
                                                                    </h5>
                                                                    {
                                                                        mfSummary.payment_types.map((item, index) => {
                                                                            return (
                                                                                <div key={index}
                                                                                    className={`py-2 padding-12px-lr d-flex align-items-center justify-content-between border-1px cursor-pointer mt-3 border-radius-12px  ${item.id === paymentType.id ? "border-purple e-bg-light-lavender" : "border-bright-gray"}`} onClick={() => _handlePayment(item)}>
                                                                                    <div className="d-flex align-items-center gap-8px">
                                                                                        <img src={item.image} alt={"Basket"}
                                                                                            draggable={false}
                                                                                            width={46}
                                                                                            height={32} />
                                                                                        <span className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">
                                                                                            {item.label}
                                                                                        </span>
                                                                                    </div>
                                                                                    <Icon icon={item.id === paymentType.id ? 'radio-checked1' : 'radio-unchecked'}
                                                                                        size={24} />
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </Fragment>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            mfSummary.mandate_status === false && selectedTab === 1 &&
                                                            <div className="d-flex align-items-start padding-12px-all  e-bg-light-blue border-radius-12px mb-4 color-ocean-blue e-font-12 e-line-height-20 gap-4px e-montserrat-medium mt-3">
                                                                <span>
                                                                    <Icon icon="info-circle"
                                                                        size={16} />
                                                                </span>
                                                                The mandate is for the automatic debit of your monthly SIP. It's important to know that the mandate allows a maximum value of {paymentType.slug === "UPI" ? mfSummary.mandate_limit.UPI : mfSummary.mandate_limit.E_MANDATE}. However, the auto-debit will occur at the exact SIP order value. There will be no additional charges for the mandate's auto-debit feature.
                                                            </div>
                                                        }

                                                        <div className="d-md-none d-flex justify-content-center">
                                                            <div className="col-sm-6 col-7">
                                                                <PrimaryButton
                                                                    label={
                                                                        selectedTab === 1 ? mfSummary.mandate_status === true
                                                                            ? "Invest Now" :
                                                                            "Create Mandate"
                                                                            :
                                                                            "Invest Now"
                                                                    }
                                                                    className="padding-12px-tb w-100"
                                                                    disabled={Object.keys(SIGNUP_PROGRESS).length === 0 ? true : (fundDetail.sip_available === false && fundDetail.lumpsum_available === false) ? true : mandateLoader}
                                                                    showLoader={Object.keys(SIGNUP_PROGRESS).length === 0 ? false : (fundDetail.sip_available === false && fundDetail.lumpsum_available === false) ? false : mandateLoader}
                                                                    onPress={() => _investNow(true)}
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className="d-md-block d-none">
                                                            <PrimaryButton
                                                                label={
                                                                    selectedTab === 1 ? mfSummary.mandate_status === true
                                                                        ? "Invest Now" :
                                                                        "Create Mandate"
                                                                        :
                                                                        "Invest Now"
                                                                }
                                                                className="padding-12px-tb w-100"
                                                                disabled={Object.keys(SIGNUP_PROGRESS).length === 0 ? true : mandateLoader}
                                                                showLoader={Object.keys(SIGNUP_PROGRESS).length === 0 ? false : mandateLoader}
                                                                onPress={() => _investNow(true)} />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            {/* Holding section */}
                                            <div className="e-fund-detail-chart border-radius-24px mt-sm-4 mt-3">
                                                <div className={`p-sm-4 p-3 d-flex align-items-center gap-4px justify-content-between cursor-pointer ${showSection.holdings === true && "border-bottom-point5px border-chinese-white"}`}
                                                    onClick={() => _handleShowReturnCalculator("holdings")}>
                                                    <div className="d-flex align-items-center gap-8px">
                                                        <img src={require("../../Assets/Images/Fund-detail/holding.png")}
                                                            alt="Return calculator"
                                                            width={36}
                                                            height={36}
                                                            className="d-sm-block d-none" />
                                                        <img src={require("../../Assets/Images/Fund-detail/holding.png")}
                                                            alt="Return calculator"
                                                            width={28}
                                                            height={28}
                                                            className="d-sm-none d-block" />
                                                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-eerie-black mb-0">Holdings{showSection.holdings === true && ` (${fundDetail.holdings_count})`}</p>
                                                    </div>
                                                    <Icon icon="header-drop-down"
                                                        width="16px"
                                                        height="16px"
                                                        className={showSection.holdings === true ? "rotate-up" : ""} />
                                                </div>
                                                {
                                                    showSection.holdings === true &&
                                                    <div className="px-sm-4 px-3 pb-sm-4 pb-3 mt-sm-4 mt-3" ref={holdingsRef}>
                                                        <HorizontalStackedBar allocations={holdingAllocations} />
                                                        <p className="e-montserrat-regular e-font-14 e-line-height-26 color-davy-gray mb-0  py-2 border-bottom-point5px border-onyx">Holdings as on {fundDetail.modified_dates.latest_holdings_date}</p>
                                                        <div className="border-bottom-1px border-chinese-white ">
                                                            <CustomTabBar data={holdingOptions}
                                                                selectedTab={selectedHoldings}
                                                                onSelectTab={_handleHoldingsTab} />
                                                        </div>
                                                        <DataTableContainer columns={HOLDING_COLUMN}
                                                            data={seeHolding === true ? fundDetail.holdings[`${selectedHoldingsLabel}`]
                                                                : (fundDetail.holdings[`${selectedHoldingsLabel}`]).slice(0, 10)}
                                                            customStyles={width > 576 ? HOLDING_STYLE : HOLDING_STYLE_MOBILE} />
                                                        {
                                                            fundDetail.holdings[`${selectedHoldingsLabel}`].length > 5 &&
                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-rebecca-purple mb-0 margin-32px-top cursor-pointer"
                                                                onClick={_handleSeeHolding}>{seeHolding === true ? "Show less" : "See all Holdings"}</p>
                                                        }
                                                    </div>
                                                }
                                            </div>

                                            {/* Returns and rankings */}
                                            <div className="e-fund-detail-chart border-radius-24px mt-sm-4 mt-3">
                                                <div className={`p-sm-4 p-3 d-flex align-items-center gap-4px justify-content-between cursor-pointer ${showSection.ranking === true && "border-bottom-point5px border-chinese-white"}`}
                                                    onClick={() => _handleShowReturnCalculator("ranking")}>
                                                    <div className="d-flex gap-8px align-items-center">
                                                        <img src={require("../../Assets/Images/Fund-detail/returns-ranking.png")}
                                                            alt="Return calculator"
                                                            width={36}
                                                            height={36}
                                                            className="d-sm-block d-none" />
                                                        <img src={require("../../Assets/Images/Fund-detail/returns-ranking.png")}
                                                            alt="Return calculator"
                                                            width={28}
                                                            height={28}
                                                            className="d-sm-none d-block" />

                                                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-eerie-black mb-0">Trailing Returns</p>
                                                    </div>

                                                    <Icon icon="header-drop-down"
                                                        width="16px"
                                                        height="16px"
                                                        className={showSection.ranking === true ? "rotate-up" : ""} />
                                                </div>

                                                {
                                                    showSection.ranking === true &&
                                                    <div className="px-sm-4 px-3 pb-sm-4 pb-3">
                                                        <div className="d-flex justify-content-between mt-3 align-items-center flex-wrap gap-16px">
                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-24 color-eerie-black mb-0">Category: {fundDetail.scheme_primary_category}</p>
                                                            <div className="d-flex gap-16px align-items-center">
                                                                {
                                                                    fundDetail.trailing_returns.filters.map((item, key) =>
                                                                        <span key={key}
                                                                            className={`cursor-pointer e-font-16 e-line-height-20 p-sm-2 p-1
                                                                                 ${trailingReturnSelected.value === item.value ?
                                                                                    " color-rebecca-purple  border-1px border-platinum  e-montserrat-semi-bold border-radius-8px" :
                                                                                    " color-blue-gray  e-montserrat-medium "}`}
                                                                            onClick={() => _handleTrailingReturn(item)}>
                                                                            {item.label}
                                                                        </span>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between  border-bottom-point5px border-color-chinese-white mt-sm-3 mt-2 pb-2">
                                                            <p className="color-eerie-black e-montserrat-medium e-font-14 e-line-height-24 mb-0">Fund returns(%)</p>
                                                            <p className={`${fundDetail.trailing_returns.data[trailingReturnSelected.value].returns < 0 ? "color-deep-carmine-pink" : fundDetail.trailing_returns.data[trailingReturnSelected.value].returns > 0 ? "color-green" : "color-eerie-black"} e-montserrat-medium e-font-14 e-line-height-24 mb-0`}>{fundDetail.trailing_returns.data[trailingReturnSelected.value].returns ? `${fundDetail.trailing_returns.data[trailingReturnSelected.value].returns} %` : "-"}</p>
                                                        </div>
                                                        <div className="d-flex justify-content-between mt-2">
                                                            <p className="color-eerie-black e-montserrat-medium e-font-14 e-line-height-24 mb-0">Category Avg.(%)</p>
                                                            <p className={`${fundDetail.trailing_returns.data[trailingReturnSelected.value].category_avg < 0 ? "color-deep-carmine-pink" : fundDetail.trailing_returns.data[trailingReturnSelected.value].category_avg > 0 ? "color-green" : "color-eerie-black"} e-montserrat-medium e-font-14 e-line-height-24 mb-0`}>{fundDetail.trailing_returns.data[trailingReturnSelected.value].category_avg ? `${fundDetail.trailing_returns.data[trailingReturnSelected.value].category_avg} %` : "-"}</p>
                                                        </div>

                                                    </div>
                                                }
                                            </div>

                                            {/* Peer comparison */}
                                            <div className="e-fund-detail-chart border-radius-24px mt-sm-4 mt-3">
                                                <div className={`p-sm-4 p-3 d-flex align-items-center gap-4px justify-content-between cursor-pointer ${showSection.comparison === true && "border-bottom-point5px border-chinese-white"}`}
                                                    onClick={() => _handleShowReturnCalculator("comparison")}>
                                                    <div className="d-flex gap-8px align-items-center">
                                                        <img src={require("../../Assets/Images/Fund-detail/peer-comparison.png")}
                                                            alt="Return calculator"
                                                            width={36}
                                                            height={36}
                                                            className="d-sm-block d-none" />
                                                        <img src={require("../../Assets/Images/Fund-detail/peer-comparison.png")}
                                                            alt="Return calculator"
                                                            width={28}
                                                            height={28}
                                                            className="d-sm-none d-block" />


                                                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-eerie-black mb-0">Peer Comparison</p>
                                                    </div>
                                                    <Icon icon="header-drop-down"
                                                        width="16px"
                                                        height="16px"
                                                        className={showSection.comparison === true ? "rotate-up" : ""} />
                                                </div>

                                                {
                                                    showSection.comparison === true &&
                                                    <div className="px-sm-4 px-3 pb-sm-4 pb-3 mt-sm-4 mt-3">

                                                        {peerMfData.length !== 0 ?
                                                            <DataTableContainer columns={PEER_COLUMN}
                                                                data={peerMfData}
                                                                customStyles={width > 576 ? PEER_STYLE : PEER_STYLE_MOBILE}
                                                                rowClick={(row) => _handleRowClick(row)} />
                                                            :
                                                            <EmptyScreen className="my-5 mx-4"
                                                                title="No funds found"
                                                                type={1}
                                                                width={120}
                                                                description="" />
                                                        }
                                                    </div>
                                                }
                                            </div>

                                            {/* Expense Ratio, exit load */}
                                            <div className="e-fund-detail-chart border-radius-24px mt-sm-4 mt-3">
                                                <div className={`p-sm-4 p-3 d-flex align-items-center gap-4px justify-content-between cursor-pointer ${showSection.expenseRatio === true && "border-bottom-point5px border-chinese-white"}`}
                                                    onClick={() => _handleShowReturnCalculator("expenseRatio")}>
                                                    <div className="d-flex gap-8px align-items-center">
                                                        <img src={require("../../Assets/Images/Fund-detail/expense-ratio.png")}
                                                            alt="Peer comparison"
                                                            width={36}
                                                            height={36}
                                                            className="d-sm-block d-none" />
                                                        <img src={require("../../Assets/Images/Fund-detail/expense-ratio.png")}
                                                            alt="Peer comparison"
                                                            width={28}
                                                            height={28}
                                                            className="d-sm-none d-block" />

                                                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-eerie-black mb-0">Expense Ratio, Exit Load</p>
                                                    </div>
                                                    <Icon icon="header-drop-down"
                                                        width="16px"
                                                        height="16px"
                                                        className={showSection.expenseRatio === true ? "rotate-up" : ""} />
                                                </div>
                                                {
                                                    showSection.expenseRatio === true &&
                                                    <div className="px-sm-4 px-3 mt-3 pb-sm-4 pb-3">
                                                        <div className="row row-gap-24px">
                                                            <div className="col-6">

                                                                <ExpenseDetail heading="Expense Ratio"
                                                                    date={`${fundDetail.modified_dates.latest_fund_expense_ratio}`}
                                                                    detail={`${fundDetail.expense_ratio}%`} />


                                                            </div>
                                                            <div className="col-6 ">

                                                                <ExpenseDetail heading="Stamp Duty"
                                                                    detail={`${fundDetail.stamp_duty}`} />
                                                            </div>

                                                            <div className="col-6">

                                                                <ExpenseDetail heading="Fund Inception Date"
                                                                    detail={`${fundDetail.fund_inception_date}`} />
                                                            </div>


                                                            <div className="col-6">

                                                                <ExpenseDetail heading="ISIN"
                                                                    detail={`${fundDetail.isin}`} />
                                                            </div>


                                                            <div className="col-12 ">
                                                                <p className=" mb-0 e-montserrat-medium e-font-14 e-line-height-24 color-eerie-black">Exit Load</p>
                                                                <p className=" mb-0 e-montserrat-semi-bold e-font-14 e-line-height-24 color-eerie-black">{fundDetail.exit_load}</p>
                                                                <p className={`mb-0 e-montserrat-medium e-line-height-24 color-outer-space e-font-14`}>As on {fundDetail.modified_dates.latest_fund_exit_load}</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            {/* Fund management */}
                                            <div className="e-fund-detail-chart border-radius-24px mt-sm-4 mt-3">
                                                <div className={`p-sm-4 p-3 d-flex align-items-center gap-4px justify-content-between cursor-pointer ${showSection.fundManagement === true && "border-bottom-point5px border-chinese-white"}`}
                                                    onClick={() => _handleShowReturnCalculator("fundManagement")}>
                                                    <div className="d-flex gap-8px align-items-center">
                                                        <img src={require("../../Assets/Images/Fund-detail/Fund-Management.png")}
                                                            alt="Fund management"
                                                            width={36}
                                                            height={36}
                                                            className="d-sm-block d-none" />
                                                        <img src={require("../../Assets/Images/Fund-detail/Fund-Management.png")}
                                                            alt="Fund management"
                                                            width={28}
                                                            height={28}
                                                            className="d-sm-none d-block" />

                                                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-eerie-black mb-0">Fund Manager(s)</p>
                                                    </div>
                                                    <Icon icon="header-drop-down"
                                                        width="16px"
                                                        height="16px"
                                                        className={showSection.fundManagement === true ? "rotate-up" : ""} />
                                                </div>
                                                {
                                                    showSection.fundManagement === true &&
                                                    <Fragment>
                                                        {
                                                            fundDetail.fund_manager.map((item, index) => {
                                                                return (
                                                                    <div key={index} className={`mx-sm-4 mx-3 ${index > 0 ? "border-top-point5px border-chinese-white pt-sm-3 pt-2 padding-12px-bottom" : "pt-sm-3 pt-2 padding-12px-bottom"}`}>
                                                                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-30 color-eerie-black mb-0">{item.name}</p>
                                                                        <p className="e-montserrat-medium e-font-16 e-line-height-30 color-outer-space mb-0">{_reverseDateFormat(item.date_from)} - Present</p>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                        {
                                                            fundDetail.modified_dates.latest_fund_manager_date != null &&
                                                            <p className="e-montserrat-regular e-font-14 e-line-height-26 color-davy-gray mb-0 ps-sm-4 ps-3 pb-2">As on {fundDetail.modified_dates.latest_fund_manager_date}</p>
                                                        }
                                                    </Fragment>
                                                }
                                            </div>

                                            {/* Fund house */}
                                            <div className="e-fund-detail-chart border-radius-24px mt-sm-4 mt-3">
                                                <div className={`p-sm-4 p-3 d-flex align-items-center gap-4px justify-content-between cursor-pointer ${showSection.fundHouse === true && "border-bottom-point5px border-chinese-white"}`}
                                                    onClick={() => _handleShowReturnCalculator("fundHouse")}>
                                                    <div className="d-flex gap-8px align-items-center">
                                                        <img src={require("../../Assets/Images/Fund-detail/Fund-house.png")}
                                                            alt="Fund house"
                                                            width={36}
                                                            height={36}
                                                            className="d-sm-block d-none" />
                                                        <img src={require("../../Assets/Images/Fund-detail/Fund-house.png")}
                                                            alt="Fund house"
                                                            width={28}
                                                            height={28}
                                                            className="d-sm-none d-block" />

                                                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-eerie-black mb-0">Fund House</p>
                                                    </div>
                                                    <Icon icon="header-drop-down"
                                                        width="16px"
                                                        height="16px"
                                                        className={showSection.fundHouse === true ? "rotate-up" : ""} />
                                                </div>

                                                {
                                                    showSection.fundHouse === true &&
                                                    <div className="p-sm-4 p-3">

                                                        <div className="d-flex gap-8px align-items-center">
                                                            <img src={fundDetail.image}
                                                                alt={fundDetail.fund_name}
                                                                width={36}
                                                                height={36}
                                                                className="border-radius-8px object-fit-contain border-1px border-bright-gray" />
                                                            <p className="e-montserrat-medium e-font-16 e-line-height-18px color-eerie-black mb-0">{fundDetail.amc_name}</p>

                                                        </div>

                                                        <div className="row mt-4">
                                                            <div className="col-6 mb-lg-0 mb-3">
                                                                <NavDetail heading="Total AUM"
                                                                    detail={fundDetail.func_house_aum} />
                                                            </div>
                                                            <div className="col-6 ps-lg-5 mb-lg-4 mb-3">
                                                                <NavDetail heading="Date of Inc"
                                                                    detail={fundDetail.date_of_inc} />
                                                            </div>

                                                        </div>
                                                        <p className="e-montserrat-medium e-font-14 e-line-height-28 mb-0 color-eerie-black white-space-pre-wrap" dangerouslySetInnerHTML={{ __html: fundDetail.amc_description }}></p>
                                                    </div>
                                                }
                                            </div>

                                            {/* Return calculator section */}
                                            <div className="e-fund-detail-chart border-radius-24px mt-sm-4 mt-3">
                                                <div className={`p-sm-4 p-3 d-flex align-items-center gap-4px justify-content-between cursor-pointer ${showSection.returnCalculator === true && "border-bottom-point5px border-chinese-white"}`}
                                                    onClick={() => _handleShowReturnCalculator("returnCalculator")}>
                                                    <div className="d-flex gap-8px align-items-center">
                                                        <img src={require("../../Assets/Images/Fund-detail/Return-Calculator.png")}
                                                            alt="Return calculator"
                                                            width={36}
                                                            height={36}
                                                            className="d-sm-block d-none" />
                                                        <img src={require("../../Assets/Images/Fund-detail/Return-Calculator.png")}
                                                            alt="Return calculator"
                                                            width={28}
                                                            height={28}
                                                            className="d-sm-none d-block" />

                                                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-eerie-black mb-0">Scheme Return Calculator</p>
                                                    </div>
                                                    <Icon icon="header-drop-down"
                                                        width="16px"
                                                        height="16px"
                                                        className={showSection.returnCalculator === true ? "rotate-up" : ""} />
                                                </div>
                                                {
                                                    showSection.returnCalculator === true &&
                                                    <div className="padding-10px-top pb-sm-4 pb-3">
                                                        <Calculator showReturns={false}
                                                            min_investment={fundDetail}
                                                            returns_percentage={selectedTypeReturn.returns}
                                                            period={selectedTypeReturn.period_number}
                                                            getReturns={_getReturns} />
                                                        <p className="e-font-14 e-montserrat-medium e-line-height-22 color-eerie-black mb-0 px-sm-4 px-3 pt-3 ">Fund Inception Date is <span className="e-montserrat-semi-bold">{fundDetail.fund_inception_date}</span>. Kindly select time period for calculation post inception date.</p>
                                                    </div>
                                                }
                                            </div>

                                            {/* Riskometer */}
                                            <div className="e-fund-detail-chart border-radius-24px mt-sm-4 mt-3">
                                                <div className={`p-sm-4 p-3 d-flex align-items-center gap-4px justify-content-between cursor-pointer ${showSection.riskometer === true && "border-bottom-point5px border-chinese-white"}`}
                                                    onClick={() => _handleShowReturnCalculator("riskometer")}>
                                                    <div className="d-flex gap-8px align-items-center">
                                                        <img src={require("../../Assets/Images/Fund-detail/riskomter-icon.png")}
                                                            alt="Risk meter"
                                                            width={36}
                                                            height={36}
                                                            className="d-sm-block d-none" />
                                                        <img src={require("../../Assets/Images/Fund-detail/riskomter-icon.png")}
                                                            alt="Risk meter"
                                                            width={28}
                                                            height={28}
                                                            className="d-sm-none d-block" />

                                                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-eerie-black mb-0">Riskometer</p>
                                                    </div>
                                                    <Icon icon="header-drop-down"
                                                        width="16px"
                                                        height="16px"
                                                        className={showSection.riskometer === true ? "rotate-up" : ""} />
                                                </div>

                                                {
                                                    showSection.riskometer === true &&
                                                    <div className="p-4 d-flex justify-content-center position-relative">
                                                        <GaugeChart value={RISK_DATA[fundDetail.risk_meter]} show_labels={true} />
                                                        <div className="position-absolute  top-60-percent text-center">
                                                            {/* <span className="e-font-14 e-line-height-20 e-montserrat-regular color-black-olive" >
                                                                Risk score
                                                            </span> */}
                                                            <h6 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-jungle-green  mb-0" >{fundDetail.risk}</h6>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            {/* fact sheet */}
                                            <div className="d-flex gap-4px align-items-center mt-4"
                                                onClick={() => window.open(`${process.env.REACT_APP_FACTSHEET_URL}/?mf_id=${fundDetail.mf_id}`, "_blank")}>
                                                <div className="e-bg-light-blue rounded-circle p-1 d-flex justify-content-center align-items-center">
                                                    <Icon icon="pdf" size={20} />
                                                </div>
                                                <LinkButton label="Download Factsheet"
                                                    className="e-font-14" />
                                            </div>


                                            {/* Disclaimer */}
                                            <p className="e-montserrat-regular e-font-16 e-line-height-30 color-davy-gray mb-0 mt-3">Disclaimer:</p>
                                            <p className="e-montserrat-regular e-font-16 e-line-height-30 color-davy-gray mb-0">Past performance may or may not be delivered in future. Mutual fund investments are subject to market risks, read scheme related documents carefully. I assure that I will
                                                read <LinkButton className="e-font-16 e-line-height-30"
                                                    onPress={() => window.open("https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doMutualFund=yes&mftype=3", "_blank")}
                                                    label="KIM" /> & <LinkButton className="e-font-16 e-line-height-30"
                                                        onPress={() => window.open("https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doMutualFund=yes&mftype=2", "_blank")}
                                                        label="SID" /> of the funds before investments.</p>
                                            <p className="e-montserrat-regular e-font-14 e-line-height-26 color-davy-gray mb-0 mt-1">Data source: Value Research </p>

                                        </div>
                                        <div className="col-xl-4 col-lg-6 d-xl-block d-none">
                                            <p className="e-montserrat-regular e-font-18 e-line-height-30 color-davy-gray mt-sm-4 mt-2 d-xl-none d-block">Invest</p>
                                            <div className="e-fund-detail-chart border-radius-24px position-sticky top-50px">
                                                <div className="border-bottom-point5px border-chinese-white px-sm-4 px-3 padding-10px-top">
                                                    <CustomTabBar data={fundDetail.sip_available === false && fundDetail.lumpsum_available === true ? LUMPSUM_DATA :
                                                        fundDetail.sip_available === true && fundDetail.lumpsum_available === false ? SIP_DATA : TAB_DATA}
                                                        selectedTab={selectedTab}
                                                        onSelectTab={_handleTabChange} />
                                                </div>
                                                <div className="pt-3 mx-sm-4 mx-3 pb-4">
                                                    {
                                                        selectedTab === 1 ?
                                                            <CustomAmountInput
                                                                value={amount}
                                                                key={"SipAmount"}
                                                                label="Investment Amount"
                                                                placeholder="50,000"
                                                                error={amountError}
                                                                className="mb-4 pb-1"
                                                                handleChange={_handleAmount} />
                                                            :
                                                            <CustomAmountInput
                                                                value={lumpsumAmount}
                                                                key={"LumpsumAmount"}
                                                                label="Investment Amount"
                                                                placeholder="50,000"
                                                                error={lumpsumAmountError}
                                                                className="mb-4 pb-1"
                                                                handleChange={_handleLumpsumAmount} />
                                                    }
                                                    <div className="d-flex flex-wrap gap-12px mt-3 mb-4">
                                                        {
                                                            AMOUNT_CHIP.map((item, key) =>
                                                                <span className="px-3 py-2 border-bright-gray border-1px e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black border-radius-20px cursor-pointer user-select-none"
                                                                    key={key}
                                                                    onClick={() => _handleChipClick(item)}>{item.label}</span>
                                                            )
                                                        }
                                                    </div>

                                                    {
                                                        mfSummary.mandate_status === false && selectedTab === 1 ?
                                                            <Fragment>
                                                                <h5 className='e-font-14  color-eerie-black e-montserrat-semi-bold e-line-height-22 mb-0'>
                                                                    {selectedTab === 1 ? "Mandate Method" : "Payment Method"}
                                                                </h5>
                                                                {
                                                                    mfSummary.payment_types.map((item, index) => {
                                                                        return (
                                                                            <div key={index}
                                                                                className={`py-2 padding-12px-lr d-flex align-items-center justify-content-between border-1px cursor-pointer mt-3 border-radius-12px  ${item.id === paymentType.id ? "border-purple e-bg-light-lavender" : "border-bright-gray"}`} onClick={() => _handlePayment(item)}>
                                                                                <div className="d-flex align-items-center gap-8px">
                                                                                    <img src={item.image} alt={"Basket"}
                                                                                        draggable={false}
                                                                                        width={46}
                                                                                        height={32} />
                                                                                    <span className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">
                                                                                        {item.label}
                                                                                    </span>
                                                                                </div>
                                                                                <Icon icon={item.id === paymentType.id ? 'radio-checked1' : 'radio-unchecked'}
                                                                                    size={24} />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Fragment>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        mfSummary.mandate_status === false && selectedTab === 1 &&
                                                        <div className="d-flex align-items-start padding-12px-all  e-bg-light-blue border-radius-12px mb-4 color-ocean-blue e-font-12 e-line-height-20 gap-4px e-montserrat-medium mt-3">
                                                            <span>
                                                                <Icon icon="info-circle"
                                                                    size={16} />
                                                            </span>
                                                            The mandate is for the automatic debit of your monthly SIP. It's important to know that the mandate allows a maximum value of {paymentType.slug === "UPI" ? mfSummary.mandate_limit.UPI : mfSummary.mandate_limit.E_MANDATE}. However, the auto-debit will occur at the exact SIP order value. There will be no additional charges for the mandate's auto-debit feature.
                                                        </div>
                                                    }

                                                    <div className="d-md-none d-flex justify-content-center">
                                                        <div className="col-sm-6 col-7">
                                                            <PrimaryButton
                                                                label={
                                                                    selectedTab === 1 ? mfSummary.mandate_status === true
                                                                        ? "Invest Now" :
                                                                        "Create Mandate"
                                                                        :
                                                                        "Invest Now"
                                                                }
                                                                className="padding-12px-tb w-100"
                                                                disabled={Object.keys(SIGNUP_PROGRESS).length === 0 ? true : (fundDetail.sip_available === false && fundDetail.lumpsum_available === false) ? true : mandateLoader}
                                                                showLoader={Object.keys(SIGNUP_PROGRESS).length === 0 ? false : (fundDetail.sip_available === false && fundDetail.lumpsum_available === false) ? false : mandateLoader}
                                                                onPress={() => _investNow(true)}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="d-md-block d-none">
                                                        <PrimaryButton label={
                                                            selectedTab === 1 ? mfSummary.mandate_status === true
                                                                ? "Invest Now" :
                                                                "Create Mandate"
                                                                :
                                                                "Invest Now"
                                                        }
                                                            className="padding-12px-tb w-100"
                                                            disabled={Object.keys(SIGNUP_PROGRESS).length === 0 ? true : (fundDetail.sip_available === false && fundDetail.lumpsum_available === false) ? true : mandateLoader}
                                                            showLoader={Object.keys(SIGNUP_PROGRESS).length === 0 ? false : (fundDetail.sip_available === false && fundDetail.lumpsum_available === false) ? false : mandateLoader}
                                                            onPress={() => _investNow(true)} />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>
                    </div>
            }

            {
                (toggleDateModal === true) &&
                <SIPDateModal apiDates={mfSummary.sip_dates !== null ? mfSummary.sip_dates : []}
                    selectedSIPDate={selectedDate}
                    handleSelect={_handleSelectDate}
                    handleCloseDateModal={() => _handleCloseModal(false)} />
            }

            {
                invest === true &&

                <FundInvestModal closeModal={() => setInvest(false)}
                    isReinvest={false}
                    amount={amount}
                    lumpsumAmount={lumpsumAmount}
                    selectedDate={selectedDate}
                    selectedDateString={selectedDateString}
                    isFirstInstallment={1}
                    summary={mfSummary}
                    status={fundLoader}
                    folioData={fundDetail.folio_data}
                    frequencies={fundDetail.swp_frequency_options}
                    frequencyData={fundDetail.sip_frequency}
                    paymentType={paymentType}
                    createNew={true}
                    invested={_handleInvested}
                    handleTabChange={_handleTabChange}
                    selected={selectedTab}
                    checkStatus={() => _getMFSummary(mfSummary.mf_id, true)} />

            }
            {
                riskCheck === true && <RiskConfirmationModal
                    description="Your risk profile does not match with the risk parameters of the selected fund. Do you still wish to continue with this investment?"
                    closeModal={() => setRiskCheck(false)}
                    label="Continue"
                    handleConfirm={_handleInvest} />
            }

            {
                riskKycCheck === true && <RiskKycConfirmationModal
                    title={modalData.title}
                    content={modalData.content}
                    closeModal={() => _confirmRiskKyc(false)}
                    handleConfirm={_handleNavigateToScreen} />
            }
        </Fragment>
    )
}

export default FundDetail 