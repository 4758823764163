/*
 *   File : investment.js
 *   Author : https://evoqins.com
 *   Description : Investment card component
 *   Integrations : null
 *   Version : 1
*/

// import packages
import { Fragment } from "react"
import { CustomTabBar } from "../Tab"
import { HorizontalStackedBar } from "../Charts";
import { LinkButton, PrimaryButton } from "../Buttons";

import style from '../../Styles/Components/investment-card.module.scss';
import { useNavigate } from "react-router-dom";
import { EmptyScreen } from "../Other";


const InvestmentCard = (props) => {

    const navigate = useNavigate();

    const FUND_ALLOCATION_DATA = props.data.funds !== undefined && props.data.funds.graph_data !== undefined && props.data.funds.graph_data.map((item) => {
        return (
            {
                name: item.asset_class,
                ...item
            }
        )
    })

    const CATEGORY_ALLOCATION_DATA = props.data.categories !== undefined && props.data.categories.graph_data !== undefined && props.data.categories.graph_data.map((item) => {
        return (
            {
                name: item.asset_class,
                ...item
            }
        )
    })

    const _handleClick = () => {
        navigate("/portfolio", {
            state: {
                investment_type: props.selected,
                show: true,
                fund_type: 1
            }
        })
    }


    return (
        <Fragment>
            <div className="position-relative h-100">
                <div className={`${style.e_investment_card} d-flex flex-column padding-12px-tb px-3 border-radius-24px h-100 w-100 ${props.isInvested === false ? 'blur-3px pointer-events-none' : ''}`}>
                    <div className="border-bottom-1px border-onyx">
                        <CustomTabBar data={props.tabs}
                            selectedTab={props.selected}
                            onSelectTab={props.onSelectTab} />
                    </div>
                    <div className={`${props.isInvested === true ? "min-h-250px" : "h-100"} d-flex flex-column`}>
                        {
                            props.selected === 1 ?
                                <Fragment>
                                    <div className="d-flex align-items-center justify-content-between border-bottom-1px border-onyx padding-12px-tb">
                                        <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                                            <img src={require('../../Assets/Images/Home/total-returns.png')}
                                                alt="total-returns"
                                                width={28}
                                                draggable={false} />
                                            Total Returns
                                        </div>
                                        <span className={`${props.data.total_investment.returns_amount > 0 ? 'color-emerald-green' : props.data.total_investment.returns_amount < 0 ? 'color-deep-carmine-pink' : 'color-eerie-black'} e-font-16 e-line-height-24 e-montserrat-semi-bold `}>  {props.data.total_investment.returns_amount_text} <span className={`e-font-14 ${props.data.total_investment.returns_percentage > 0 ? 'color-emerald-green' : props.data.total_investment.returns_percentage < 0 ? 'color-deep-carmine-pink' : 'color-eerie-black'}`}>({props.data.total_investment.returns_percentage_text})</span></span>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between padding-12px-top">
                                        <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                                            <img src={require('../../Assets/Images/Home/current.png')}
                                                alt="total-returns"
                                                width={28}
                                                draggable={false} />
                                            Current
                                        </div>
                                        <span className="color-eerie-black e-font-14 e-line-height-24 e-montserrat-semi-bold">{props.data.total_investment.current_amount_text}</span>
                                    </div>

                                    {props.isInvested === true && <div className="d-flex align-items-center justify-content-between  padding-12px-top">
                                        <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                                            <img src={require('../../Assets/Images/Home/invested.png')}
                                                alt="total-returns"
                                                width={28}
                                                draggable={false} />
                                            Invested
                                        </div>
                                        <span className="color-eerie-black e-font-14 e-line-height-24 e-montserrat-semi-bold">{props.data.total_investment.invested_amount_text}</span>
                                    </div>}

                                    <div className="d-flex align-items-center justify-content-between  padding-12px-top">
                                        <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                                            <img src={require('../../Assets/Images/Home/xirr.png')}
                                                alt="total-returns"
                                                width={28}
                                                draggable={false} />
                                            XIRR
                                        </div>
                                        <span className={`${props.data.total_investment.xirr > 0 ? "color-emerald-green" : props.data.total_investment.xirr < 0 ? "color-deep-carmine-pink" : 'color-eerie-black'} e-font-14 e-line-height-24 e-montserrat-semi-bold d-flex align-items-center `}>{props.data.total_investment.xirr_text}
                                            {props.data.total_investment.xirr !== 0 &&
                                                <img width={16} alt="xirr" src={props.data.total_investment.xirr > 0 ? require("../../Assets/Images/Home/xirr-high.svg").default : require("../../Assets/Images/Home/xirr-low.png")} />
                                            }
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between  padding-12px-top pb-2 ">
                                        <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                                            <img src={require('../../Assets/Images/Home/1-day-return.png')}
                                                alt="total-returns"
                                                width={28}
                                                draggable={false} />
                                            1 Day Return
                                        </div>
                                        <span className={`${props.data.total_investment.one_day_returns > 0 ? "color-emerald-green" : props.data.total_investment.one_day_returns < 0 ? "color-deep-carmine-pink" : 'color-eerie-black'} e-font-14 e-line-height-24 e-montserrat-semi-bold `}>{props.data.total_investment.one_day_returns_text} <span className={`e-font-12 ${props.data.total_investment.one_day_returns_percentage > 0 ? "color-emerald-green" : props.data.total_investment.one_day_returns_percentage < 0 ? "color-deep-carmine-pink" : 'color-eerie-black'}`}>({props.data.total_investment.one_day_returns_percentage_text})</span></span>
                                    </div>
                                </Fragment>
                                :
                                props.selected === 2 ?
                                    Object.keys(props.data.categories).length > 0 ?
                                        <Fragment>

                                            <div className="pt-2">
                                                <HorizontalStackedBar height="h-16px" allocations={CATEGORY_ALLOCATION_DATA} />
                                            </div>
                                            {/* <HorizontalStackedBar height="8px" allocations={}/> */}
                                            <div className="row">
                                                <div className="col-sm-6 col-5">
                                                    <span className="color-black opacity-6 e-font-12 e-line-height-16 e-montserrat-semi-bold">Category</span>
                                                </div>
                                                <div className="col-3">
                                                    <span className="color-black opacity-6 e-font-12 e-line-height-16 e-montserrat-semi-bold">Amount</span>
                                                </div>
                                                <div className="col-3 text-center">
                                                    <span className="color-black opacity-6 e-font-12 e-line-height-16 e-montserrat-semi-bold">Allocation(%)</span>
                                                </div>
                                            </div>
                                            {
                                                props.data.categories.allocation_data !== undefined && props.data.categories.allocation_data.slice(0, 3).map((item, index) => {
                                                    return (
                                                        <div className={`py-2 ${index > 0 ? 'border-top-point5px border-onyx' : ''}`}>
                                                            <div className={`row`}>
                                                                <div className="col-sm-6 col-5 d-flex align-items-center gap-4px">
                                                                    <span style={{ background: item.color }}
                                                                        className="d-block rounded-circle min-w-10px min-h-10px "></span>
                                                                    <p className="color-eerie-black e-font-14 e-line-height-14 e-montserrat-medium mb-0">{item.name}</p>
                                                                </div>
                                                                <div className="col-3">
                                                                    <span className="color-eerie-black e-font-14 e-line-height-14 e-montserrat-semi-bold">{item.current_amount_text}</span>
                                                                </div>
                                                                <div className="col-3 text-center">
                                                                    <span className="color-eerie-black e-font-14 e-line-height-14 e-montserrat-semi-bold">{item.allocation}%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {props.data.categories.allocation_data !== undefined && props.data.categories.allocation_data.length > 3 && <div className="mt-auto text-center">
                                                <LinkButton label="View more"
                                                    className='e-font-14 e-line-height-20 e-montserrat-bold mt-auto'
                                                    onPress={_handleClick} />
                                            </div>}
                                        </Fragment>
                                        :
                                        <div className="d-block">
                                            <EmptyScreen className="mt-4"
                                                title={"No Investment Found"}
                                                type={6}
                                                width={120}
                                                height="w-auto"
                                                description="You haven't made any investments, so there is no portfolio summary to view" />
                                            <div className="w-100 text-center pt-2">
                                                <PrimaryButton label="Explore Now" className="px-3 mx-auto w-max-content mb-3" onPress={() => navigate("/explore")} />
                                            </div>
                                        </div>
                                    :
                                    Object.keys(props.data.funds).length > 0 ?
                                        <Fragment>
                                            <div className="pt-2">
                                                <HorizontalStackedBar height="h-16px" allocations={FUND_ALLOCATION_DATA} />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-5">
                                                    <span className="color-black opacity-6 e-font-12 e-line-height-16 e-montserrat-semi-bold">Fund name</span>
                                                </div>
                                                <div className="col-3">
                                                    <span className="color-black opacity-6 e-font-12 e-line-height-16 e-montserrat-semi-bold">Amount</span>
                                                </div>
                                                <div className="col-3 text-center">
                                                    <span className="color-black opacity-6 e-font-12 e-line-height-16 e-montserrat-semi-bold">Allocation(%)</span>
                                                </div>
                                            </div>
                                            {
                                                props.data.funds.allocation_data !== undefined && props.data.funds.allocation_data.slice(0, 2).map((item, index) => {
                                                    return (
                                                        <div className={`py-2 ${index > 0 ? 'border-top-point5px border-onyx' : ''}`}>
                                                            <div className={`row align-items-center`}>
                                                                <div className="col-sm-6 col-5 d-flex align-items-start gap-4px">
                                                                    <span style={{ background: item.color }}
                                                                        className="d-block rounded-circle min-w-10px min-h-10px "></span>
                                                                    <p className="color-eerie-black e-font-14 e-line-height-14 e-montserrat-medium mb-0">{item.mf_name}</p>
                                                                </div>
                                                                <div className="col-3">
                                                                    <span className="color-eerie-black e-font-14 e-line-height-14 e-montserrat-semi-bold">{item.current_amount_text}</span>
                                                                </div>
                                                                <div className="col-3 text-center">
                                                                    <span className="color-eerie-black e-font-14 e-line-height-14 e-montserrat-semi-bold">{item.allocation}%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {props.data.funds.allocation_data !== undefined && props.data.funds.allocation_data.length > 2 && <div className="mt-auto text-center">
                                                <LinkButton label="View more"
                                                    className='e-font-14 e-line-height-20 e-montserrat-bold mt-auto'
                                                    onPress={_handleClick} />
                                            </div>}
                                        </Fragment>
                                        :
                                        <div className="d-block">
                                            <EmptyScreen className="mt-4"
                                                title={"No Investment Found"}
                                                type={6}
                                                width={120}
                                                height="w-auto"
                                                description="You haven't made any investments, so there is no portfolio summary to view" />
                                            <div className="w-100 text-center pt-2">
                                                <PrimaryButton label="Explore Now" className="px-3 mx-auto w-max-content mb-3" onPress={() => navigate("/explore")} />
                                            </div>
                                        </div>}
                    </div>
                </div>
                {props.isInvested === false && <div className={`${style.e_pending} position-absolute z-index-2 py-2 text-center e-font-14 e-line-height-24 e-montserrat-medium color-eerie-black px-4 top-0 bottom-0 start-0 end-0 m-auto py-2 w-240px h-max-content`}>
                    You haven't made any investments, so there is no portfolio summary to view.
                </div>}
            </div>
        </Fragment>
    )
}
export default InvestmentCard