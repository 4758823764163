/*
 *   File : toast.js
 *   Author URI : https://evoqins.com
 *   Description : Alert messages
 *   Integrations : null
 *   Version : 1
 */

import { ToastContainer } from "react-toastify";
import Icon from "../Icon/icon";

import "react-toastify/dist/ReactToastify.css";

function Toast(props) {

    function CloseButton() {
        return (
            <Icon icon='close'
                size={18}
                className="position-absolute right-8px top-4px" />
        )
    }
    return (
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            closeButton={CloseButton}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={true}
        />
    );
}

export default Toast;
