/*
 *   File : header.js
 *   Author : https://evoqins.com
 *   Description : Header component
 *   Integrations : NA
 *   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";

// import components
import { Icon } from "../Icon";
import { ProfileModal } from "../Modal";

import ProfileBackground from '../../Assets/Images/Header/profile-bg.png';

// import styles
import style from "../../Styles/Components/header.module.scss"
import { _getNotificationCount } from "../../Helper/api";

const Header = (props) => {

    const PROFILE_DETAILS = useSelector(state => state.Reducer.PROFILE_DETAILS);
    const COUNT = useSelector(state => state.Reducer.NOTIFICATION_COUNT);

    const [showDropDown, setShowDropDown] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        _getNotificationCount();
    }, []);


    // close module popup listener
    useEffect(() => {
        if (!showDropDown) {
            document.body.removeAttribute("style");
        }
    }, [showDropDown]);


    useEffect(() => {
        if (!Cookies.get("mintit_refresh_token")) {
            sessionStorage.clear();
            window.location.href = "/"
        }
        //eslint-disable-next-line
    }, [Cookies.get("mintit_refresh_token")]);

    const _openDropDown = () => {
        setShowDropDown(true);
        document.body.style.overflow = 'hidden';
    }

    const _closeDropDown = () => {
        setShowDropDown(false);
    };

    const _handleLinkNavigate = (link) => {
        navigate(link);
        _closeDropDown();
    };

    return (
        <Fragment>
            <div className={props.type === 2 ? "border-bottom-1px border-light-periwinkle margin-22px-bottom" : ""}>
                <div className="row">
                    <div className="col-lg-11 pe-lg-0">

                        <div className="d-flex py-3 px-4 justify-content-between align-items-center">
                            <div className="d-flex align-items-center gap-10px">
                                <div className={`p-1 bg-white  cursor-pointer border-radius-100px d-lg-none d-flex`}>
                                    <img src={require('../../Assets/Images/Global/menu.png')}
                                        alt="menu"
                                        className="bg-white border-radius-100px"
                                        width={24}
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#left-navigation-offcanvas"
                                        onClick={() => document.body.style.padding = "0px"} />
                                </div>
                                <p className={`e-montserrat-semi-bold e-font-20 e-line-height-40  mb-0 ${props.type === 1 ? "color-white" : "color-eerie-black"} d-flex align-items-start gap-2px`}><span>Hello {PROFILE_DETAILS.name}</span> <span className="margin--3px-top">👋</span></p>
                            </div>
                            <div className="d-flex align-items-center gap-16px position-relative">
                                <div className='position-relative cursor-pointer d-lg-block d-none e-fund-detail-chart rounded-circle' onClick={() => {
                                    if (location.pathname !== "/notifications") {
                                        navigate("/notifications")
                                    }
                                }}>
                                    <Icon icon="notification"
                                        size={40} />
                                    {COUNT > 0 && (
                                        <span className="position-absolute top--8px end-0 rounded-pill e-bg-crimpson-red e-font-10 e-line-height-12 color-white e-montserrat-bold w-26px h-26px padding-1px-top d-flex align-items-center justify-content-center">
                                            {COUNT > 99 ? '99+' : COUNT}
                                        </span>
                                    )}


                                </div>
                                <div className={`p-1 bg-white d-flex align-items-center gap-10px cursor-pointer e-fund-detail-chart border-radius-40px `}
                                    onClick={_openDropDown}>
                                    <p className="e-montserrat-semi-bold e-font-16 e-line-height-18 mb-0 color-american-purple w-32px h-32px rounded-circle e-bg-lavender-web overflow-hidden text-nowrap text-ellipsis d-flex justify-content-center align-items-center">
                                        {PROFILE_DETAILS.short_name}</p>
                                    <Icon icon="header-drop-down"
                                        size={16}
                                        className="margin-6px-right" />
                                </div>

                                {
                                    showDropDown === true &&
                                    <Fragment>
                                        <ProfileModal navigateLink={_handleLinkNavigate} background={ProfileBackground} />
                                        <div className={`position-fixed w-100 h-100 e-bg-black ${style.e_header_dropdown} z-index-50 top-0 start-0`}
                                            onClick={_closeDropDown}></div>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Header;