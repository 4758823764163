/*
*   File : stp.js
*   Author : https://evoqins.com
*   Description : A common listing page for all STP's in the platform. 
*   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal } from "bootstrap";
import ReactPaginate from "react-paginate";
import Popup from "reactjs-popup";

// Components
import { CustomTabBar } from "../../Components/Tab";
import { EmptyScreen, Loader } from "../../Components/Other";
import { PrimaryButton } from "../../Components/Buttons";
import { Icon } from "../../Components/Icon";
import { CancelSTPModal, EditSTP, PauseSTPModal } from "../../Components/Modal";

// Colors
import Color from "../../Styles/color.module.scss";

// Services
import APIService from "../../Services/api-service";
import { useWindowSize } from "../../Helper/helper";

const STP_TABS = [
    {
        label: "Active",
        id: 1,
        icon: require("../../Assets/Images/Systematic-transaction/active-inactive.svg").default,
        active: require("../../Assets/Images/Systematic-transaction/active.svg").default,
    },
    {
        label: "Executed",
        id: 2,
        icon: require("../../Assets/Images/Systematic-transaction/executed-inactive.svg").default,
        active: require("../../Assets/Images/Systematic-transaction/executed-active.svg").default,
    },
    {
        label: "Cancelled",
        id: 3,
        icon: require("../../Assets/Images/Systematic-transaction/cancelled-inactive.svg").default,
        active: require("../../Assets/Images/Systematic-transaction/cancelled-active.svg").default,
    },
    {
        label: "Paused",
        id: 4,
        icon: require("../../Assets/Images/Systematic-transaction/paused-inactive.svg").default,
        active: require("../../Assets/Images/Systematic-transaction/paused-active.svg").default,
    },
    {
        label: "Completed",
        id: 5,
        icon: require("../../Assets/Images/Systematic-transaction/completed-inactive.svg").default,
        active: require("../../Assets/Images/Systematic-transaction/completed-inactive.svg").default,
    }
]

const NextIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple} />
    )
}
const PrevIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple}
            className="e-transform-left-arrow" />
    )
}
const BreakLabel = () => {
    return (
        <span>...</span>
    )
}
const STP = () => {
    const location = useLocation();

    const { width } = useWindowSize();
    const [stpTabs, setStpTabs] = useState(STP_TABS[0].id);
    const [pageCount, setPageCount] = useState(0); //total number of pages
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [activeStpLoader, setActiveStpLoader] = useState(false);
    const [activeExpandedRow, setActiveExpandedRow] = useState({});
    const [showCancelSTPModal, setShowCancelSTPModal] = useState(null);
    const [showEditSTPModal, setShowEditSTPModal] = useState(null);
    const [showRestartModal, setShowRestartModal] = useState(null);
    const [showPauseStpModal, setShowPauseStpModal] = useState(null);
    const [activeStpList, setActiveStpList] = useState([]);
    const [executedList, setExecutedList] = useState([]);
    const [cancelledList, setCancelledList] = useState([]);
    const [pausedList, setPausedList] = useState([]);
    const [completedList, setCompletedList] = useState([]);

    useEffect(() => {
        if (location.state === null) {
            setActiveStpLoader(true);
            _activeList();
        }
        //eslint-disable-next-line
    }, [currentPage, stpTabs]);

    useEffect(() => {
        if (location.state !== null) {
            setActiveStpLoader(true);
            _activeList();
        }
        //eslint-disable-next-line
    }, [currentPage, stpTabs]);

    useEffect(() => {
        if (showEditSTPModal !== null) {
            const modal = new Modal(document.getElementById("edit-stp"));
            modal.show();
        }
    }, [showEditSTPModal]);

    useEffect(() => {
        if (showCancelSTPModal !== null) {
            const modal = new Modal(document.getElementById("cancel-stp"));
            modal.show();
        }
        //eslint-disable-next-line
    }, [showCancelSTPModal]);

    useEffect(() => {
        if (showRestartModal !== null) {
            const modal = new Modal(document.getElementById("edit-stp"));
            modal.show();
        }
    }, [showRestartModal]);

    useEffect(() => {
        if (showPauseStpModal !== null) {
            const modal = new Modal(document.getElementById("pause-stp"));
            modal.show();
        }
    }, [showPauseStpModal]);


    function _handleStpTabs(tab_index) {
        setStpTabs(tab_index);
        setCurrentPage(1);
        setActiveExpandedRow({});
    }

    const _handleRowClick = (row) => {
        setActiveExpandedRow(row);
    }

    // Handle page change
    const _handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber + 1);
        window.scrollTo(0, 0)
    };
    const _handleEditActiveStp = (fund) => {
        setShowEditSTPModal(fund);
    }

    const _handleRestartStp = (fund) => {
        setShowRestartModal(fund);
    }

    // API - consent call
    const _handleSave = (investment_type) => {
        setActiveStpLoader(true);
        setActiveExpandedRow({});
        _activeList(investment_type);
    }

    // API - Active swp
    const _activeList = (type) => {
        let url = "";
        switch (stpTabs) {
            case 1:
                url = "/stp/active-list"
                break;
            case 2:
                url = "/stp/processed-list"
                break;
            case 3:
                url = "/stp/cancelled-list"
                break;
            case 4:
                url = "/stp/paused-list"
                break;
            case 5:
                url = "/stp/completed-list"
                break;

            default:
                break;
        }
        const request = {
            "page_num": currentPage,
            "page_size": 12,
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                switch (stpTabs) {
                    case 1:
                        setActiveStpList(response.data.stps);
                        break;
                    case 2:
                        setExecutedList(response.data.stps);
                        break;
                    case 3:
                        setCancelledList(response.data.stps);
                        break;
                    case 4:
                        setPausedList(response.data.stps);
                        break;
                    case 5:
                        setCompletedList(response.data.stps);
                        break;

                    default:
                        break;
                }

                setPageCount(response.data.total_page);
            } else {
                setActiveStpList([]);
                setExecutedList([]);
                setCancelledList([]);
                setPausedList([]);
                setCompletedList([]);
                setPageCount(0);
            }
            setActiveStpLoader(false);
        })
    }

    return (
        <Fragment>
            <CustomTabBar data={STP_TABS}
                selectedTab={stpTabs}
                onSelectTab={_handleStpTabs}
                type={3} />
            <div className="d-flex flex-wrap gap-16px justify-content-between align-items-center border-top-1px border-bright-gray pt-4">
                <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-eerie-black mb-0 ">{STP_TABS.find(item => item.id === stpTabs).label} STPs</p>
            </div>
            <div className="d-md-block d-none">
                {
                    stpTabs === 1 ?
                        <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                            {
                                activeStpLoader === true ?
                                    <Loader height="min-h-500px" />
                                    :
                                    activeStpList.length === 0 ?
                                        <EmptyScreen className="my-5"
                                            title="No STPs"
                                            type={3}
                                            description="" />
                                        :
                                        <Fragment>
                                            <div className="mb-4 border-radius-tlr-16px d-md-block d-none">
                                                {
                                                    activeStpList.map((item, key) =>
                                                        <div className={`p-4 border-bottom-1px border-gainsboro cursor-pointer ${activeExpandedRow.stp_id === item.stp_id ? "e-bg-pastel-purple" : ""} ${key === 0 ? "border-radius-tlr-16px" : ""}`}
                                                            key={key}
                                                            onClick={() => _handleRowClick(item)}>

                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2">Transfer from</p>
                                                            <div className={`row gx-3 ${activeExpandedRow.stp_id === item.stp_id ? "border-bottom-1px border-bright-gray pb-3" : ""}`}>
                                                                <div className="col-xl-5 col-5">
                                                                    {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                        <Icon icon="external" size={18}
                                                                        /> External
                                                                    </div>}
                                                                    <div className="d-flex gap-8px align-items-center">
                                                                        <img src={item.fund_out.icon}
                                                                            width={48}
                                                                            height={48}
                                                                            alt={item.fund_out.name}
                                                                            draggable={false}
                                                                            className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                                        <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.fund_out.name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="d-flex  gap-26px align-items-center justify-content-between">
                                                                        <div className="w-max-content">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>STP Schedule</p>
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.date_str}</p>
                                                                        </div>
                                                                        <img src={require("../../Assets/Images/Systematic-transaction/active-sip-arrow.png")}
                                                                            alt="Arrow"
                                                                            width={28}
                                                                            height={28}
                                                                            draggable={false} />
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="w-max-content ms-auto">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>No. of installment</p>
                                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 `}>{item.number_of_installments}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="w-max-content ms-auto">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Amount</p>
                                                                        <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0">{item.amount_text}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                activeExpandedRow.stp_id === item.stp_id &&
                                                                <Fragment>
                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 color-rebecca-purple mb-2 mt-3">Transfer to</p>
                                                                    <div className="d-flex gap-16px justify-content-between  e-bg-pastel-purple ">

                                                                        <div className="col-xl-6 col-5">
                                                                            <div className="d-flex gap-8px align-items-center">
                                                                                <img src={item.fund_in.icon}
                                                                                    width={48}
                                                                                    height={48}
                                                                                    alt={item.fund_in.name}
                                                                                    draggable={false}
                                                                                    className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                                                <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.fund_in.name}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="d-flex align-items-center gap-16px ">

                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-STP-20 color-deep-carmine-pink mb-0 padding-10px-tb px-2"
                                                                                onClick={() => setShowCancelSTPModal(item)}>Cancel STP</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-rebecca-purple cursor-pointer mb-0"
                                                                                onClick={() => setShowPauseStpModal(item)}>Pause STP</p>
                                                                            <PrimaryButton label="Edit"
                                                                                className="padding-10px-tb px-4"
                                                                                onPress={() => _handleEditActiveStp(item)} />
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            <ReactPaginate
                                                previousLabel={<PrevIcon />}
                                                nextLabel={<NextIcon />}
                                                breakLabel={<BreakLabel />}
                                                pageCount={pageCount}
                                                pageRangeDisplayed={2}
                                                marginPagesDisplayed={1}
                                                onPageChange={(value) => _handlePageChange(value.selected)}
                                                renderOnZeroPageCount={null}
                                                forcePage={currentPage - 1}
                                                containerClassName="e-pagination"
                                                activeClassName="e-pagination-active"
                                            />
                                        </Fragment>
                            }
                        </div>
                        :
                        stpTabs === 2 ?
                            // Executed tab
                            <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                {
                                    activeStpLoader === true ?
                                        <Loader height="min-h-500px" />
                                        :
                                        executedList.length === 0 ?
                                            <EmptyScreen className="my-5"
                                                title="No STPs"
                                                type={3}
                                                description="" />
                                            :
                                            <Fragment>
                                                <div className="mb-4 border-radius-tlr-16px ">
                                                    {
                                                        executedList.map((item, key) =>
                                                            <div className={`p-4 border-bottom-1px border-bright-gray`}>
                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2">Transfer from</p>
                                                                <div className={`row gx-3 row-gap-16px`}
                                                                    key={key}>
                                                                    <div className="col-xl-12 col-lg-12">
                                                                        {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                            <Icon icon="external" size={18}
                                                                            /> External
                                                                        </div>}
                                                                        <div className="d-flex gap-8px align-items-center">
                                                                            <img src={item.fund_out.icon}
                                                                                width={48}
                                                                                height={48}
                                                                                alt={item.fund_out.name}
                                                                                draggable={false}
                                                                                className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                                            <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.fund_in.name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-4 col-lg-4 col-md-6">
                                                                        <p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2">Transfer to</p>
                                                                        <div className="d-flex gap-8px align-items-center">
                                                                            <img src={item.fund_in.icon}
                                                                                width={48}
                                                                                height={48}
                                                                                alt={item.fund_in.name}
                                                                                draggable={false}
                                                                                className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                                            <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.fund_in.name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>STP Schedule</p>
                                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.stp_date}</p>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Amount</p>
                                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.amount_text}</p>
                                                                    </div>
                                                                    <div className="col-sm-2 col-6">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>No. of installment</p>
                                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 `}>{item.number_of_installments}</p>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Status</p>
                                                                        <div className="d-flex gap-4px align-items-center">
                                                                            <img src={item.status === "Successful" ? require("../../Assets/Images/Systematic-transaction/success.svg").default
                                                                                :
                                                                                item.status === "Pending" ?
                                                                                    require("../../Assets/Images/Orders/pending.svg").default
                                                                                    :
                                                                                    require("../../Assets/Images/Systematic-transaction/failed.svg").default
                                                                            } alt={item.status}
                                                                                width={20}
                                                                                height={20}
                                                                                draggable={false} />
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 ${item.status === "Successful" ? "color-go-green" : item.status === "Pending" ? "color-golden-orange" : "color-deep-carmine-pink"}`}>{item.status}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeStpList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                </div>
                                                <ReactPaginate previousLabel={<PrevIcon />}
                                                    nextLabel={<NextIcon />}
                                                    breakLabel={<BreakLabel />}
                                                    pageCount={pageCount}
                                                    pageRangeDisplayed={2}
                                                    marginPagesDisplayed={1}
                                                    onPageChange={(value) => _handlePageChange(value.selected)}
                                                    renderOnZeroPageCount={null}
                                                    forcePage={currentPage - 1}
                                                    containerClassName="e-pagination "
                                                    activeClassName="e-pagination-active" />
                                            </Fragment>
                                }
                            </div>
                            :
                            stpTabs === 3 ?
                                // Cancelled tab
                                <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                    {
                                        activeStpLoader === true ?
                                            <Loader height="min-h-500px" />
                                            :
                                            cancelledList.length === 0 ?
                                                <EmptyScreen className="my-5"
                                                    title="No STPs"
                                                    type={3}
                                                    description="" />
                                                :
                                                <Fragment>
                                                    <div className="mb-4 border-radius-tlr-16px ">
                                                        {
                                                            cancelledList.map((item, key) =>
                                                                <div className={`p-4 border-bottom-1px border-bright-gray cursor-pointer ${activeExpandedRow.stp_id === item.stp_id && width > 768 ? "e-bg-pastel-purple" : ""} ${key === 0 ? "border-radius-tlr-16px" : ""}`}
                                                                    key={key}
                                                                    onClick={() => _handleRowClick(item)}>
                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2">Transfer from</p>
                                                                    <div className={`row gx-3 row-gap-16px ${activeExpandedRow.stp_id === item.stp_id ? "border-bottom-1px border-bright-gray pb-3" : ""}`}>
                                                                        <div className="col-xl-5">
                                                                            {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                                <Icon icon="external" size={18}
                                                                                /> External
                                                                            </div>}
                                                                            <div className="d-flex gap-8px align-items-center">
                                                                                <img src={item.fund_out.icon}
                                                                                    width={48}
                                                                                    height={48}
                                                                                    alt={item.fund_out.name}
                                                                                    draggable={false}
                                                                                    className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                                                <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.fund_out.name}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>STP Schedule</p>
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.stp_date}</p>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Created on</p>
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.created}</p>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Amount</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">{item.amount_text}</p>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Cancelled on</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-deep-carmine-pink">{item.cancelled}</p>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        width > 768 ?
                                                                            activeExpandedRow.stp_id === item.stp_id &&
                                                                            <Fragment>
                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 color-rebecca-purple mb-2 mt-3">Transfer to</p>
                                                                                <div className="d-flex gap-16px justify-content-between  e-bg-pastel-purple ">

                                                                                    <div className="col-xl-6 col-5">
                                                                                        <div className="d-flex gap-8px align-items-center">
                                                                                            <img src={item.fund_in.icon}
                                                                                                width={48}
                                                                                                height={48}
                                                                                                alt={item.fund_in.name}
                                                                                                draggable={false}
                                                                                                className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                                                            <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.fund_in.name}</p>
                                                                                        </div>
                                                                                    </div>


                                                                                    <PrimaryButton label="Restart STP"
                                                                                        className="padding-10px-tb px-4"
                                                                                        onPress={() => _handleRestartStp(item)} />
                                                                                </div>
                                                                            </Fragment>
                                                                            :
                                                                            <div className={`d-flex justify-content-end pt-3 `}>
                                                                                <PrimaryButton label="Restart STP"
                                                                                    className="padding-10px-tb px-4"
                                                                                    onPress={() => _handleRestartStp(item)} />
                                                                            </div>
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeStpList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                    </div>
                                                    <ReactPaginate previousLabel={<PrevIcon />}
                                                        nextLabel={<NextIcon />}
                                                        breakLabel={<BreakLabel />}
                                                        pageCount={pageCount}
                                                        pageRangeDisplayed={2}
                                                        marginPagesDisplayed={1}
                                                        onPageChange={(value) => _handlePageChange(value.selected)}
                                                        renderOnZeroPageCount={null}
                                                        forcePage={currentPage - 1}
                                                        containerClassName="e-pagination "
                                                        activeClassName="e-pagination-active" />
                                                </Fragment>
                                    }
                                </div>
                                :
                                stpTabs === 4 ?
                                    // Paused tab
                                    <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                        {
                                            activeStpLoader === true ?
                                                <Loader height="min-h-500px" />
                                                :
                                                pausedList.length === 0 ?
                                                    <EmptyScreen className="my-5"
                                                        title="No STPs"
                                                        type={3}
                                                        description="" />
                                                    :
                                                    <Fragment>
                                                        <div className="mb-4 border-radius-tlr-16px ">
                                                            {
                                                                pausedList.map((item, key) =>
                                                                    <div className={`p-4 border-bottom-1px border-bright-gray cursor-pointer ${activeExpandedRow.stp_id === item.stp_id && width > 768 ? "e-bg-pastel-purple" : ""} ${key === 0 ? "border-radius-tlr-16px" : ""}`}
                                                                        key={key}
                                                                        onClick={() => _handleRowClick(item)}>
                                                                        <p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2">Transfer from</p>
                                                                        <div className={`row gx-3 row-gap-16px ${activeExpandedRow.stp_id === item.stp_id ? "border-bottom-1px border-bright-gray pb-3" : ""}`}>
                                                                            <div className="col-xl-5">
                                                                                {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                                    <Icon icon="external" size={18}
                                                                                    /> External
                                                                                </div>}
                                                                                <div className="d-flex gap-8px align-items-center">
                                                                                    <img src={item.fund_out.icon}
                                                                                        width={48}
                                                                                        height={48}
                                                                                        alt={item.fund_out.name}
                                                                                        draggable={false}
                                                                                        className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                                                    <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.fund_out.name}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>STP Schedule</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.stp_date}</p>
                                                                            </div>
                                                                            <div className="col-md-2 col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Created on</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.created}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Amount</p>
                                                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">{item.amount_text}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Paused on</p>
                                                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-cyan-cornflower-blue">{item.paused}</p>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            width > 768 ?
                                                                                activeExpandedRow.stp_id === item.stp_id &&
                                                                                <Fragment>
                                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 color-rebecca-purple mb-2 mt-3">Transfer to</p>
                                                                                    <div className="d-flex gap-16px justify-content-between  e-bg-pastel-purple ">

                                                                                        <div className="col-xl-6 col-5">
                                                                                            <div className="d-flex gap-8px align-items-center">
                                                                                                <img src={item.fund_in.icon}
                                                                                                    width={48}
                                                                                                    height={48}
                                                                                                    alt={item.fund_in.name}
                                                                                                    draggable={false}
                                                                                                    className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                                                                <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.fund_in.name}</p>
                                                                                            </div>
                                                                                        </div>


                                                                                        <PrimaryButton label="Resume STP"
                                                                                            className="padding-10px-tb px-4"
                                                                                            onPress={() => _handleRestartStp(item)} />
                                                                                    </div>
                                                                                </Fragment>
                                                                                :
                                                                                <div className="d-flex justify-content-end pt-3 border-top-1px border-gainsboro mt-3 ">
                                                                                    <PrimaryButton label="Resume STP"
                                                                                        className="padding-10px-tb px-4"
                                                                                        onPress={() => _handleRestartStp(item)} />
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeStpList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                        </div>
                                                        <ReactPaginate previousLabel={<PrevIcon />}
                                                            nextLabel={<NextIcon />}
                                                            breakLabel={<BreakLabel />}
                                                            pageCount={pageCount}
                                                            pageRangeDisplayed={2}
                                                            marginPagesDisplayed={1}
                                                            onPageChange={(value) => _handlePageChange(value.selected)}
                                                            renderOnZeroPageCount={null}
                                                            forcePage={currentPage - 1}
                                                            containerClassName="e-pagination "
                                                            activeClassName="e-pagination-active" />
                                                    </Fragment>
                                        }
                                    </div>
                                    :
                                    // Completed tab
                                    <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                        {
                                            activeStpLoader === true ?
                                                <Loader height="min-h-500px" />
                                                :
                                                completedList.length === 0 ?
                                                    <EmptyScreen className="my-5"
                                                        title="No STPs"
                                                        type={3}
                                                        description="" />
                                                    :
                                                    <Fragment>
                                                        <div className="mb-4 border-radius-tlr-16px ">
                                                            {
                                                                completedList.map((item, key) =>
                                                                    <div className={`p-4 border-bottom-1px border-bright-gray cursor-pointer ${activeExpandedRow.stp_id === item.stp_id && width > 768 ? "e-bg-pastel-purple" : ""} ${key === 0 ? "border-radius-tlr-16px" : ""}`}
                                                                        key={key}
                                                                        onClick={() => _handleRowClick(item)}>
                                                                        <p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2">Transfer from</p>
                                                                        <div className={`row gx-3 row-gap-16px ${activeExpandedRow.stp_id === item.stp_id ? "border-bottom-1px border-bright-gray pb-3" : ""}`}>
                                                                            <div className="col-md-12">
                                                                                {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                                    <Icon icon="external" size={18}
                                                                                    /> External
                                                                                </div>}
                                                                                <div className="d-flex gap-8px align-items-center">
                                                                                    <img src={item.fund_out.icon}
                                                                                        width={48}
                                                                                        height={48}
                                                                                        alt={item.fund_out.name}
                                                                                        draggable={false}
                                                                                        className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                                                    <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.fund_out.name}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2">Transfer to</p>
                                                                                <div className="d-flex gap-8px align-items-center">
                                                                                    <img src={item.fund_in.icon}
                                                                                        width={48}
                                                                                        height={48}
                                                                                        alt={item.fund_in.name}
                                                                                        draggable={false}
                                                                                        className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                                                    <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.fund_in.name}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>STP Schedule</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.stp_date}</p>
                                                                            </div>
                                                                            <div className="col-md-2 col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Created on</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 coloreerie-black`}>{item.created}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Amount</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 coloreerie-black`}>{item.amount_text}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Completed on</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-ufo-green`}>{item.completed}</p>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            width > 768 &&
                                                                            activeExpandedRow.stp_id === item.stp_id &&
                                                                            <Fragment>
                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 color-rebecca-purple mb-2 mt-3">Transfer to</p>
                                                                                <div className="e-bg-pastel-purple ">
                                                                                    <div className="col-xl-6 col-5">
                                                                                        <div className="d-flex gap-8px align-items-center">
                                                                                            <img src={item.icon}
                                                                                                width={48}
                                                                                                height={48}
                                                                                                alt={item.name}
                                                                                                draggable={false}
                                                                                                className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                                                            <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Fragment>
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                        data={activeStpList}
                                                        customStyles={TABLE_STYLE}
                                                        expandableRows={true}
                                                        expandableRowsComponent={ExpandedComponent}
                                                        rowClick={(row) => _handleRowClick(row)} /> */}
                                                        </div>
                                                        <ReactPaginate previousLabel={<PrevIcon />}
                                                            nextLabel={<NextIcon />}
                                                            breakLabel={<BreakLabel />}
                                                            pageCount={pageCount}
                                                            pageRangeDisplayed={2}
                                                            marginPagesDisplayed={1}
                                                            onPageChange={(value) => _handlePageChange(value.selected)}
                                                            renderOnZeroPageCount={null}
                                                            forcePage={currentPage - 1}
                                                            containerClassName="e-pagination "
                                                            activeClassName="e-pagination-active" />
                                                    </Fragment>
                                        }
                                    </div>
                }
            </div>
            <div className="d-md-none d-block">
                {
                    stpTabs === 1 ?
                        <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                            {
                                activeStpLoader === true ?
                                    <Loader height="min-h-500px" />
                                    :
                                    activeStpList.length === 0 ?
                                        <EmptyScreen className="my-5"
                                            title="No STPs"
                                            type={3}
                                            description="" />
                                        :
                                        <Fragment>
                                            <div className="mb-4 d-md-none d-block">
                                                {
                                                    activeStpList.map((item, key) => (
                                                        <div
                                                            key={key} // Move key to the outermost element
                                                            className={`p-4 border-bottom-1px border-bright-gray cursor-pointer `}>
                                                            <div className={`row `}>
                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2">Transfer from</p>
                                                                {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                    <Icon icon="external" size={18}
                                                                    /> External
                                                                </div>}
                                                                <div className="col-12 d-flex align-items-center justify-content-between gap-16px">
                                                                    <div className="d-flex gap-8px align-items-center">
                                                                        <img src={item.fund_out.icon}
                                                                            width={36}
                                                                            height={36}
                                                                            alt={item.fund_out.name}
                                                                            draggable={false}
                                                                            className="border-radius-8px e-fund-detail-chart object-fit-contain"
                                                                        />
                                                                        <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">
                                                                            {item.fund_out.name}
                                                                        </p>
                                                                    </div>

                                                                    <Popup
                                                                        trigger={
                                                                            <img src={require("../../Assets/Images/Systematic-transaction/more.png")}
                                                                                alt="More"
                                                                                draggable={false}
                                                                                width={24}
                                                                                height={24}
                                                                                className="cursor-pointer" />
                                                                        }
                                                                        position="bottom right"
                                                                        closeOnDocumentClick
                                                                    >
                                                                        <div className="d-flex flex-column gap-12px justify-content-end bg-white p-2 e-ranking-category">
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black cursor-pointer mb-0"
                                                                                onClick={() => setShowPauseStpModal(item)}>Pause STP</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black cursor-pointer mb-0"
                                                                                onClick={() => _handleEditActiveStp(item)}>Edit</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black cursor-pointer mb-0"
                                                                                onClick={() => setShowCancelSTPModal(item)}>Cancel STP</p>
                                                                        </div>
                                                                    </Popup>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2 mt-3">Transfer to</p>
                                                                    <div className="d-flex gap-8px align-items-center">
                                                                        <img src={item.fund_in.icon}
                                                                            width={36}
                                                                            height={36}
                                                                            alt={item.fund_in.name}
                                                                            draggable={false}
                                                                            className="border-radius-12px e-fund-detail-chart object-fit-contain"
                                                                        />
                                                                        <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">
                                                                            {item.fund_in.name}
                                                                        </p>
                                                                    </div>
                                                                    <div className="d-flex align-items-center justify-content-between my-3">
                                                                        <div className="w-max-content">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>STP Schedule</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0">{item.date_str}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>No. of installment</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 ">{item.number_of_installments}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-between border-top-1px border-gainsboro pt-3">
                                                                    <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Amount</p>
                                                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0">{item.amount_text}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <ReactPaginate
                                                previousLabel={<PrevIcon />}
                                                nextLabel={<NextIcon />}
                                                breakLabel={<BreakLabel />}
                                                pageCount={pageCount}
                                                pageRangeDisplayed={2}
                                                marginPagesDisplayed={1}
                                                onPageChange={(value) => _handlePageChange(value.selected)}
                                                renderOnZeroPageCount={null}
                                                forcePage={currentPage - 1}
                                                containerClassName="e-pagination"
                                                activeClassName="e-pagination-active"
                                            />
                                        </Fragment>
                            }
                        </div>
                        :
                        stpTabs === 2 ?
                            // Executed tab
                            <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                {
                                    activeStpLoader === true ?
                                        <Loader height="min-h-500px" />
                                        :
                                        executedList.length === 0 ?
                                            <EmptyScreen className="my-5"
                                                title="No STPs"
                                                type={3}
                                                description="" />
                                            :
                                            <Fragment>
                                                <div className="mb-4 border-radius-tlr-16px ">
                                                    {
                                                        executedList.map((item, key) =>
                                                            <div className={`p-4 border-bottom-1px border-bright-gray`}>
                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2">Transfer from</p>
                                                                <div className={`row row-gap-16px`}
                                                                    key={key}>
                                                                    <div className="col-md-12">
                                                                        {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                            <Icon icon="external" size={18}
                                                                            /> External
                                                                        </div>}
                                                                        <div className="d-flex gap-8px align-items-center">
                                                                            <img src={item.fund_out.icon}
                                                                                width={36}
                                                                                height={36}
                                                                                alt={item.fund_out.name}
                                                                                draggable={false}
                                                                                className="border-radius-8px e-fund-detail-chart object-fit-contain" />
                                                                            <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.fund_out.name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2">Transfer to</p>
                                                                        <div className="d-flex gap-8px align-items-center">
                                                                            <img src={item.fund_in.icon}
                                                                                width={36}
                                                                                height={36}
                                                                                alt={item.fund_in.name}
                                                                                draggable={false}
                                                                                className="border-radius-12px e-fund-detail-chart object-fit-contain"
                                                                            />
                                                                            <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">
                                                                                {item.fund_in.name}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>STP Schedule</p>
                                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.stp_date}</p>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Amount</p>
                                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 `}>{item.amount_text}</p>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>No.of installment</p>
                                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 `}>{item.number_of_installments}</p>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Status</p>
                                                                        <div className="d-flex gap-4px align-items-center">
                                                                            <img src={item.status === "Successful" ? require("../../Assets/Images/Systematic-transaction/success.svg").default
                                                                                :
                                                                                require("../../Assets/Images/Systematic-transaction/failed.svg").default
                                                                            } alt={item.status}
                                                                                width={20}
                                                                                height={20}
                                                                                draggable={false} />
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 ${item.status === "Successful" ? "color-go-green" : "color-deep-carmine-pink"}`}>{item.status}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeStpList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                </div>
                                                <ReactPaginate previousLabel={<PrevIcon />}
                                                    nextLabel={<NextIcon />}
                                                    breakLabel={<BreakLabel />}
                                                    pageCount={pageCount}
                                                    pageRangeDisplayed={2}
                                                    marginPagesDisplayed={1}
                                                    onPageChange={(value) => _handlePageChange(value.selected)}
                                                    renderOnZeroPageCount={null}
                                                    forcePage={currentPage - 1}
                                                    containerClassName="e-pagination "
                                                    activeClassName="e-pagination-active" />
                                            </Fragment>
                                }
                            </div>
                            :
                            stpTabs === 3 ?
                                // Cancelled tab
                                <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                    {
                                        activeStpLoader === true ?
                                            <Loader height="min-h-500px" />
                                            :
                                            cancelledList.length === 0 ?
                                                <EmptyScreen className="my-5"
                                                    title="No STPs"
                                                    type={3}
                                                    description="" />
                                                :
                                                <Fragment>
                                                    <div className="mb-4 border-radius-tlr-16px ">
                                                        {
                                                            cancelledList.map((item, key) =>
                                                                <div className={`p-4 border-bottom-1px border-bright-gray cursor-pointer ${key === 0 ? "border-radius-tlr-16px" : ""}`}
                                                                    key={key}>
                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2">Transfer from</p>
                                                                    <div className={`row row-gap-16px `}>
                                                                        {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                            <Icon icon="external" size={18}
                                                                            /> External
                                                                        </div>}
                                                                        <div className="col-md-4 d-flex align-items-center gap-16px justify-content-between">
                                                                            <div className="d-flex gap-8px align-items-center">
                                                                                <img src={item.fund_out.icon}
                                                                                    width={36}
                                                                                    height={36}
                                                                                    alt={item.fund_out.name}
                                                                                    draggable={false}
                                                                                    className="border-radius-8px e-fund-detail-chart object-fit-contain" />
                                                                                <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.fund_out.name}</p>
                                                                            </div>
                                                                            <Popup
                                                                                trigger={
                                                                                    <img src={require("../../Assets/Images/Systematic-transaction/more.png")}
                                                                                        alt="More"
                                                                                        draggable={false}
                                                                                        width={24}
                                                                                        height={24}
                                                                                        className="cursor-pointer" />
                                                                                }
                                                                                position="bottom right"
                                                                                closeOnDocumentClick
                                                                            >
                                                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black cursor-pointer mb-0 bg-white p-2 e-ranking-category"
                                                                                    onClick={() => _handleRestartStp(item)}>Restart STP</p>
                                                                            </Popup>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2">Transfer to</p>
                                                                            <div className="d-flex gap-8px align-items-center">
                                                                                <img src={item.fund_in.icon}
                                                                                    width={36}
                                                                                    height={36}
                                                                                    alt={item.fund_in.name}
                                                                                    draggable={false}
                                                                                    className="border-radius-12px e-fund-detail-chart object-fit-contain"
                                                                                />
                                                                                <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">
                                                                                    {item.fund_in.name}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>STP Schedule</p>
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.stp_date}</p>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Created on</p>
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.created}</p>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Amount</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">{item.amount_text}</p>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Cancelled on</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-deep-carmine-pink">{item.cancelled}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeStpList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                    </div>
                                                    <ReactPaginate previousLabel={<PrevIcon />}
                                                        nextLabel={<NextIcon />}
                                                        breakLabel={<BreakLabel />}
                                                        pageCount={pageCount}
                                                        pageRangeDisplayed={2}
                                                        marginPagesDisplayed={1}
                                                        onPageChange={(value) => _handlePageChange(value.selected)}
                                                        renderOnZeroPageCount={null}
                                                        forcePage={currentPage - 1}
                                                        containerClassName="e-pagination "
                                                        activeClassName="e-pagination-active" />
                                                </Fragment>
                                    }
                                </div>
                                :
                                stpTabs === 4 ?
                                    // Paused tab
                                    <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                        {
                                            activeStpLoader === true ?
                                                <Loader height="min-h-500px" />
                                                :
                                                pausedList.length === 0 ?
                                                    <EmptyScreen className="my-5"
                                                        title="No STPs"
                                                        type={3}
                                                        description="" />
                                                    :
                                                    <Fragment>
                                                        <div className="mb-4 border-radius-tlr-16px ">
                                                            {
                                                                pausedList.map((item, key) =>
                                                                    <div className={`p-4 border-bottom-1px border-bright-gray cursor-pointer
                                                                    ${key === 0 ? "border-radius-tlr-16px" : ""}`}
                                                                        key={key}
                                                                        onClick={() => _handleRowClick(item)}

                                                                    ><p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2">Transfer from</p>
                                                                        <div className={`row row-gap-16px`}>
                                                                            {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                                <Icon icon="external" size={18}
                                                                                /> External
                                                                            </div>}
                                                                            <div className="col-md-4 d-flex align-items-center justify-content-between gap-16px">
                                                                                <div className="d-flex gap-8px align-items-center">
                                                                                    <img src={item.fund_out.icon}
                                                                                        width={36}
                                                                                        height={36}
                                                                                        alt={item.fund_out.name}
                                                                                        draggable={false}
                                                                                        className="border-radius-8px e-fund-detail-chart object-fit-contain" />
                                                                                    <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.fund_out.name}</p>
                                                                                </div>
                                                                                <Popup
                                                                                    trigger={
                                                                                        <img src={require("../../Assets/Images/Systematic-transaction/more.png")}
                                                                                            alt="More"
                                                                                            draggable={false}
                                                                                            width={24}
                                                                                            height={24}
                                                                                            className="cursor-pointer" />
                                                                                    }
                                                                                    position="bottom right"
                                                                                    closeOnDocumentClick
                                                                                >
                                                                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black cursor-pointer mb-0 bg-white p-2 e-ranking-category"
                                                                                        onClick={() => _handleRestartStp(item)}>Restart STP</p>
                                                                                </Popup>
                                                                            </div>

                                                                            <div className="col-12">
                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2">Transfer to</p>
                                                                                <div className="d-flex gap-8px align-items-center">
                                                                                    <img src={item.fund_in.icon}
                                                                                        width={36}
                                                                                        height={36}
                                                                                        alt={item.fund_in.name}
                                                                                        draggable={false}
                                                                                        className="border-radius-12px e-fund-detail-chart object-fit-contain"
                                                                                    />
                                                                                    <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">
                                                                                        {item.fund_in.name}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>STP Schedule</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.stp_date}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Created on</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.created}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Amount</p>
                                                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">{item.amount_text}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Paused on</p>
                                                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-cyan-cornflower-blue">{item.paused}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeStpList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                        </div>
                                                        <ReactPaginate previousLabel={<PrevIcon />}
                                                            nextLabel={<NextIcon />}
                                                            breakLabel={<BreakLabel />}
                                                            pageCount={pageCount}
                                                            pageRangeDisplayed={2}
                                                            marginPagesDisplayed={1}
                                                            onPageChange={(value) => _handlePageChange(value.selected)}
                                                            renderOnZeroPageCount={null}
                                                            forcePage={currentPage - 1}
                                                            containerClassName="e-pagination "
                                                            activeClassName="e-pagination-active" />
                                                    </Fragment>
                                        }
                                    </div>
                                    :
                                    // Completed tab
                                    <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                        {
                                            activeStpLoader === true ?
                                                <Loader height="min-h-500px" />
                                                :
                                                completedList.length === 0 ?
                                                    <EmptyScreen className="my-5"
                                                        title="No STPs"
                                                        type={3}
                                                        description="" />
                                                    :
                                                    <Fragment>
                                                        <div className="mb-4 border-radius-tlr-16px ">
                                                            {
                                                                completedList.map((item, key) =>
                                                                    <div className={`p-4 border-bottom-1px border-bright-gray`}
                                                                        key={key}>
                                                                        <p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2">Transfer from</p>
                                                                        <div className={`row row-gap-16px`}>
                                                                            <div className="col-md-12">
                                                                                {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                                    <Icon icon="external" size={18}
                                                                                    /> External
                                                                                </div>}
                                                                                <div className="d-flex gap-8px align-items-center">
                                                                                    <img src={item.fund_out.icon}
                                                                                        width={36}
                                                                                        height={36}
                                                                                        alt={item.fund_out.name}
                                                                                        draggable={false}
                                                                                        className="border-radius-8px e-fund-detail-chart object-fit-contain" />
                                                                                    <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.fund_out.name}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2">Transfer to</p>
                                                                                <div className="d-flex gap-8px align-items-center">
                                                                                    <img src={item.fund_in.icon}
                                                                                        width={48}
                                                                                        height={48}
                                                                                        alt={item.fund_in.name}
                                                                                        draggable={false}
                                                                                        className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                                                    <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.fund_in.name}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>STP Schedule</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.stp_date}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Created on</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 coloreerie-black`}>{item.created}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Amount</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 coloreerie-black`}>{item.amount_text}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4 mt-md-1" : ""}`}>Completed on</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-ufo-green`}>{item.completed}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                        data={activeStpList}
                                                        customStyles={TABLE_STYLE}
                                                        expandableRows={true}
                                                        expandableRowsComponent={ExpandedComponent}
                                                        rowClick={(row) => _handleRowClick(row)} /> */}
                                                        </div>
                                                        <ReactPaginate previousLabel={<PrevIcon />}
                                                            nextLabel={<NextIcon />}
                                                            breakLabel={<BreakLabel />}
                                                            pageCount={pageCount}
                                                            pageRangeDisplayed={2}
                                                            marginPagesDisplayed={1}
                                                            onPageChange={(value) => _handlePageChange(value.selected)}
                                                            renderOnZeroPageCount={null}
                                                            forcePage={currentPage - 1}
                                                            containerClassName="e-pagination "
                                                            activeClassName="e-pagination-active" />
                                                    </Fragment>
                                        }
                                    </div>
                }
            </div>

            {
                showCancelSTPModal !== null &&
                <CancelSTPModal closeModal={() => setShowCancelSTPModal(null)}
                    id={showCancelSTPModal.stp_id}
                    success={() => _activeList()} />
            }
            {
                showEditSTPModal !== null &&
                <EditSTP fund={showEditSTPModal}
                    header="Edit STP"
                    type={1}
                    min_installments={showEditSTPModal.min_max_dates.min_stp_installments}
                    max_installment={showEditSTPModal.min_max_dates.max_stp_installments}
                    title="Customize your STP details"
                    buttonLabel="Save"
                    closeModal={() => _handleEditActiveStp(null)}
                    success={_handleSave} />
            }
            {
                showRestartModal !== null &&
                <EditSTP fund={showRestartModal}
                    header={stpTabs === 3 ? "Restart STP" : "Resume STP"}
                    type={3}
                    min_installments={showRestartModal.min_stp_installments}
                    max_installment={showRestartModal.max_stp_installments}
                    title="Customize your Restart STP details"
                    buttonLabel={stpTabs === 3 ? "Restart STP" : "Resume STP"}
                    closeModal={() => _handleRestartStp(null)}
                    success={_handleSave} />
            }
            {
                showPauseStpModal !== null &&
                <PauseSTPModal close={() => setShowPauseStpModal(null)}
                    id={showPauseStpModal.stp_id}
                    confirm={() => _activeList()} />
            }

        </Fragment>
    )
}
export default STP;