
import { toast } from "react-toastify";
import APIService from "../Services/api-service";
import store from "../Store";

const _getGoalsList = () => {
    return new Promise((resolve, reject) => {
        let url = '/goal/list';

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message)
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// API - get profile data
const _getProfile = (token) => {
    return new Promise((resolve, reject) => {
        const url = "/profile/get";
        APIService(false, url, null, token).then((response) => {
            if (response.status_code === 200) {
                store.dispatch({
                    type: "PROFILE_DATA",
                    payload: response.data
                })
                resolve(response.data);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        }).catch((error) => {
            reject(error);
        });
    })
}

// API - get profile data
const _getSignupProgress = (token) => {
    return new Promise((resolve, reject) => {
        const url = "/profile/signup-progress";
        APIService(false, url, null, token).then((response) => {
            if (response.status_code === 200) {
                store.dispatch({
                    type: "SIGNUP_PROGRESS",
                    payload: response.data
                })
                resolve(response.data);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// API - Get user bank data
const _getGeneralData = async (token) => {
    return new Promise(async (resolve, reject) => {
        const url = '/general/get';
        await APIService(false, url, null, token).then((response) => {
            if (response.status_code === 200) {
                store.dispatch({
                    type: "GENERAL_DATA",
                    payload: response.data
                })
                resolve(response);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        }).catch((error) => {
            reject(error);
        });
    });
};

// API - Get goal detail
function _getGoal(goal_id) {
    return new Promise((resolve, reject) => {
        let url = '/goal/get';
        let payload = JSON.stringify({
            goal_id,
            is_invested: false
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}


function _setGoalDetail(tenure, inflation, goal_id, target_value, existing_investment) {
    return new Promise((resolve, reject) => {
        let url = '/goal/set';
        let payload = JSON.stringify({
            tenure,
            inflation,
            goal_id,
            target_value,
            additional_data: {
                existing_investment,
            }
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}


// API - Get file path
const _getFilePath = async (request) => {
    return new Promise(async (resolve, reject) => {
        const url = '/file/upload';
        await APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                resolve(response);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        }).catch((error) => {
            reject(error);
        });
    });
};

// API - Get file path
const _checkMandate = async (status, mandate_type) => {
    return new Promise(async (resolve, reject) => {
        const url = '/mandate/check';
        const request = {
            create: status,
            platform: "web",
            mandate_type: mandate_type === "UPI" ? "UPI" : "E_MANDATE"
        }
        await APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                resolve(response);
            } else {
                resolve(response);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        }).catch((error) => {
            reject(error);
        });
    });
};

// API - Get financial Status
const _getFinancialStatus = async () => {
    return new Promise(async (resolve, reject) => {
        const url = "/home/get-financial-status";

        await APIService(true, url, null).then((response) => {
            if (response.status_code === 200) {
                resolve(response)
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

// API - resend otp
const _generate2Fa = (value, amount) => {
    return new Promise((resolve, reject) => {
        let url = '/consent/generate-2fa';
        let payload = JSON.stringify({
            type: value,
            amount: amount
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                resolve(response.data);
            } else {
                reject(response.message);
            }
        }).catch((error) => {
            reject(error);
        });
    });
};


// API - notification count
const _getNotificationCount = async () => {
    return new Promise(async (resolve, reject) => {
        const url = "/notification/count";

        await APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                store.dispatch({
                    type: "NOTIFICATION_COUNT",
                    payload: response.data.notification_count
                })
                resolve(response)
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

export {
    _getGoalsList,
    _getProfile,
    _getSignupProgress,
    _getGeneralData,
    _getGoal,
    _setGoalDetail,
    _getFilePath,
    _getFinancialStatus,
    _generate2Fa,
    _checkMandate,
    _getNotificationCount
}

