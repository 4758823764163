
/*
 *   File : bank-verification.js
 *   Author URI : https://evoqins.com
 *   Description : Modal to verify BANK
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import React, { Fragment, useEffect } from "react";
import { toast } from "react-toastify";

// Custom components

// Custom styles
import style from "../../Styles/Components/fund-allocation.module.scss";

// services
import APIService from "../../Services/api-service";
import { _getSignupProgress } from "../../Helper/api";


const BankVerificationModal = React.memo((props) => {

    useEffect(() => {
        var my_modal = document.getElementById("bank-verification");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            _verifyBank();
        }, 1000); // 1000 milliseconds = 1 second

        // Cleanup function to clear the interval when the component is unmounted
        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, []);

    // API - create esign
    const _verifyBank = () => {
        const url = "/kyc/check-bank-verification";

        APIService(false, url).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {

                if (response.data.status === "SUCCESS") {
                    _getSignupProgress().then((kyc_response) => {
                        document.getElementById("close").dispatchEvent(new Event("click"));
                        props.submitInvestment();

                    });
                } else if (response.data.status === "FAILED") {
                    document.getElementById("close").dispatchEvent(new Event("click"));
                    if (response.data.proof_required) {
                        props.reSubmit(true);
                    } else {
                        toast.error(response.data.message, {
                            type: 'error'
                        });
                    }
                }
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
                document.getElementById("close").dispatchEvent(new Event("click"));
            }
        });
    }


    return (
        <Fragment>
            <div className={`modal fade ${style.e_esign_modal}`}
                id="bank-verification"
                tabIndex="-1"
                aria-labelledby="bank-verification"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content text-center padding-12px-lr ${style.e_content} `}>
                        <span id="close"
                            className="d-none"
                            data-bs-dismiss="modal" ></span>
                        <Fragment>
                            <img src={require("../../Assets/Images/KYC/bank_account_verification.gif")}
                                alt="success"
                                draggable={false}
                                width={300}
                                className="mx-auto object-fit-contain" />
                        </Fragment>
                    </div>
                </div>
            </div>
        </Fragment>
    )
})

BankVerificationModal.defaultProps = {
    closeModal: () => { },
    handleNavigate: () => { }
}

export default BankVerificationModal