/*
 *   File : signup.js
 *   Author : https://evoqins.com
 *   Description : Social Signup Container
 *   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";

// import components
import { CustomPhoneInput, CustomTextInput } from "../../Components/FormElements";
import { PrimaryButton } from "../../Components/Buttons";

// import helper
import APIService from "../../Services/api-service";
import { useLocation } from "react-router-dom";
import { Icon } from "../../Components/Icon";


const SocialSignup = (props) => {

    const location = useLocation();
    const [phone, setPhone] = useState(props.phone);
    const [phoneError, setPhoneError] = useState("");

    const [name, setName] = useState(props.name);
    const [nameError, setNameError] = useState("");

    const [email, setEmail] = useState(props.email);
    const [emailError, setEmailError] = useState("");

    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {

        const handleNavigation = () => {
            const modalElement = document.querySelector('.modal');
            const modalBackdrop = document.querySelector('.modal-backdrop');

            if (modalElement) {
                // Hide or remove the modal
                modalElement.style.display = 'none'; // You can also use `modalElement.remove();` if you want to completely remove it
            }

            if (modalBackdrop) {
                modalBackdrop.remove();
            }

            document.body.removeAttribute('style');
            document.body.removeAttribute('class');
        };

        handleNavigation();

        // Add event listener for the 'popstate' event
        window.addEventListener('popstate', handleNavigation);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('popstate', handleNavigation);
        };

    }, []);

    useEffect(() => {
        if (location.state !== null) {
            if (location.state.phone_number) {
                setPhone(location.state.phone_number);
            }
            if (location.state.email) {
                setEmail(location.state.email);
            }
            if (location.state.name) {
                setName(location.state.name);
            }
        }
    }, [location.state]);

    // phone value handler
    function _handlePhoneNumber(input_value) {
        setPhone(input_value);
        setPhoneError("");
    }

    // name handler
    function _handleName(input_value) {
        setName(input_value);
        setNameError("");
    }

    // handle Email
    function _handleEmail(input_value) {
        setEmail(input_value.toLowerCase());
        setEmailError("");
    }

    // phone validation 
    function _handleLogin() {
        let valid = true;


        if (props.is_mobile_verified === false) {
            if (phone === "") {
                setPhoneError("Phone number cannot be empty");
                valid = false;
            }
            if (phone !== "" && phone.length !== 10) {
                setPhoneError("Please enter valid phone number");
                valid = false;
            }
        }
        if (name === "") {
            setNameError("Name cannot be empty");
            valid = false;
        }
        if (props.is_email_verified === false) {
            const email_value = email.trim();
            if (email_value === "") {
                setEmailError("Email cannot be empty");
                valid = false
            }
            // eslint-disable-next-line
            if (email_value !== "" && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email_value)) {
                setEmailError("Please enter valid email id");
                valid = false
            }
        }

        if (valid === true) {
            _sendOTP(null);
        }
    }

    // flow- true :: normal flow

    // API -send signup otp
    const _sendOTP = async () => {
        setApiLoader(true);
        const url = "/auth/sign-up"
        let request = {}
        if (props.is_email_verified === false) {
            request = {
                email: email,
                name: name,
            }
        } else {
            request = {
                country_code: "+91",
                phone_number: phone,
                name: name,
            }

        }
        await APIService(true, url, request, props.token).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("OTP sent successfully", {
                    type: "success",
                });
                if (props.flow === true) {
                    props.signUpStep(4, phone, email, name);
                } else {
                    props.signUpStep(2, phone);
                }

            } else {
                toast.error(response.message, {
                    type: "error",
                });

            }
            setApiLoader(false);
        });
    }



    return (
        <Fragment>
            <h3 className="color-dark-jungle-green mb-1 e-font-24 e-line-height-36 e-montserrat-semi-bold letter-spacing-minus2em">Configure MINTIT account</h3>
            <p className="color-blue-gray e-font-14 e-line-height-24 e-montserrat-regular letter-spacing-minus2em mb-2">Fill out the following details to start account creation</p>

            <div className="position-relative">
                <CustomPhoneInput label="Phone number"
                    postfix="*"
                    placeholder="Enter phone number"
                    value={phone}
                    maxLength={10}
                    error={phoneError}
                    onSubmit={_handleLogin}
                    readOnly={props.is_mobile_verified === true}
                    handleChange={_handlePhoneNumber} />
                {
                    props.is_mobile_verified === true &&
                    <Icon icon="verified"
                        size={20}
                        className="position-absolute top-42px right-12px" />
                }
            </div>
            <CustomTextInput label="Name as per PAN"
                postfix="*"
                placeholder="Enter your name as per PAN"
                value={name}
                error={nameError}
                className="mt-4"
                onSubmit={_handleLogin}
                handleChange={_handleName} />

            <div className="position-relative">
                <CustomTextInput type="email"
                    value={email}
                    error={emailError}
                    postfix="*"
                    className="mt-4"
                    isDisabled={props.is_email_verified === true}
                    label="Enter your email"
                    placeholder="Eg: rahul@gmail.com"
                    inputClass="text-transform-lower"
                    handleChange={_handleEmail}
                    onSubmit={_handleLogin} />
                {
                    props.is_email_verified === true &&
                    <Icon icon="verified"
                        size={20}
                        className="position-absolute top-38px right-12px" />
                }
            </div>


            <PrimaryButton className="w-100 margin-32px-top padding-10px-tb"
                label="Get OTP"
                disabled={apiLoader}
                onPress={_handleLogin} />
        </Fragment>


    )
}

export default SocialSignup