/*
 *   File : create-switch.js
 *   Author URI : https://evoqins.com
 *   Description :Modal to create switch order from portfolio
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

// Components
import { Icon } from "../Icon";
import { CustomAmountInput, CustomPINInput, EditableSlider, RadioGroup } from "../FormElements";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { CustomDropdown } from "../Dropdown";
import { SearchFunds, SIPDateModal } from ".";

// Styles
import style from '../../Styles/Components/switch.module.scss';
import Color from '../../Styles/color.module.scss';

// Services,helper
import APIService from "../../Services/api-service";
import { _generate2Fa } from "../../Helper/api";
import { _findNearestDate, _formatSip, _getTomorrowDate, _getTomorrowDay, useCountdown, WEEKLY_OPTIONS } from "../../Helper/helper";

// Store
import store from "../../Store";

// Search component
const SearchComponent = (props) => {

    function _onFocus() {
        if (props.onFocus) {
            props.onFocus();
        }
    }

    return (
        <div className="position-relative" onClick={_onFocus}>
            {
                props.prefix && <Icon icon="Search"
                    size={props.size}
                    className="position-absolute e-search-icon" />
            }

            <input value={props.inputState}
                placeholder={props.placeholder}
                type='text'
                readOnly={props.disabled}
                className={`color-black e-montserrat-medium e-font-14 e-line-height-20 w-100 no-outline  e-search-fund border-radius-12px  border-0 ${props.class}`}
                onChange={props.onChange}
                onBlur={props.onBlur ? props.onBlur : null} />

            {
                props.suffix && <Icon icon="right-arrow"
                    size={props.size}
                    color={Color.rebecca_purple}
                    className="position-absolute right-16px top-16px rotate-90deg" />
            }
            {
                props.close === true && props.inputState.length > 0 &&
                <Icon icon="close-btn"
                    size={20}
                    color={Color.black}
                    className="position-absolute  top-0 right-8px bottom-0 my-auto cursor-pointer"
                    onClick={props.clearSearch} />
            }
            {
                props.error &&
                <div className='position-absolute d-flex align-items-center margin-6px-top w-max-content'>
                    {/* <Icon icon="info-circle"
                        size={16}
                        className="me-1" /> */}
                    <p className='color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 mb-0 '>
                        {props.error}
                    </p>
                </div>
            }

        </div>
    );
}

// Screen number 
// 1 :: Form elements-> select fund to transfer,folio, amount or units
// 2 :: Enter OTP
// 3 :: Success or Failure message

const CreateSTPOrder = (props) => {
    const navigate = useNavigate();
    const PROFILE_DETAILS = store.getState().Reducer.PROFILE_DETAILS;
    const MASKED_NUMBER = PROFILE_DETAILS.phone.masked_number;
    const inputRef = useRef();
    const { seconds, resetCountdown } = useCountdown(30);

    const [screenNumber, setScreenNumber] = useState(1);
    const [searchFund, setSearchFund] = useState(false);

    const [amount, setAmount] = useState("");
    const [amountError, setAmountError] = useState("");

    const [apiLoader, setApiLLoader] = useState(false);

    const [folioOptions, setFolioOptions] = useState([]);
    const [folio, setFolio] = useState(null);
    const [folioError, setFolioError] = useState(null);

    const [selectedDate, setSelectedDate] = useState(_getTomorrowDate());
    const [selectedDateString, setSelectedDateString] = useState(_formatSip(_getTomorrowDate()));
    const [toggleDateModal, setToggleDateModal] = useState(false);
    const [stpDates, setStpDates] = useState([]);

    const [investments, setInvestments] = useState(props.summary.default_stp_installments);

    const [selectedFundName, setSelectedFundName] = useState("");

    const [selectedFundId, setSelectedFundId] = useState("");
    const [transferError, setTransferError] = useState("");


    const [modalData, setModalData] = useState({});

    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    const [frequencyOptions, setFrequencyOptions] = useState([]);
    const [frequencySelected, setFrequencySelected] = useState(null);

    const [daySelected, setDaySelected] = useState(_getTomorrowDay());
    const [daySelectedError, setDaySelectedError] = useState("");

    useEffect(() => {
        var my_modal = document.getElementById("create-stp");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
            document.body.removeAttribute('style')
        });
        //eslint-disable-next-line
    }, []);



    useEffect(() => {
        const folio_options = props.summary.folio_data.map((item) => {
            return ({
                label: <div className="e-montserrat-medium" dangerouslySetInnerHTML={{ __html: `${item.folio_number} <br /> <span style="font-size:12px"><span class="e-montserrat-regular">Amount</span>: ₹${item.amount}</span>  ${item.units ? `<span style="font-size:12px; display:inline-block"> <span class="e-montserrat-regular">Units:</span> ${item.units}</span> ` : ``}` }} />,
                value: item.folio_number
            })
        })

        setFolioOptions(folio_options);
    }, [props.summary]);

    useEffect(() => {
        if (props.summary.stp_frequencies.length !== 0) {
            const frequencies = props.summary.stp_frequency_options.map((item) => {
                return ({
                    name: item.label, id: item.value
                })
            })
            setFrequencyOptions(frequencies);

            setFrequencySelected(frequencies[0].id);
        }
        // eslint-disable-next-line
    }, [props.summary.stp_frequencies]);


    useEffect(() => {

        if (props.summary.stp_frequency && frequencySelected !== null) {
            if (frequencySelected === "monthly") {
                setSelectedDate(_findNearestDate(props.summary.stp_frequency[`${frequencySelected}`].dates, _getTomorrowDate()));
            } else {
                setSelectedDate(daySelected.value);
            }
            const date_string = _formatSip(_findNearestDate(props.summary.stp_frequency[`${frequencySelected}`].dates, _getTomorrowDate()));
            setSelectedDateString(date_string);
            setStpDates(props.summary.stp_frequency[`${frequencySelected}`].dates)
        }
        // eslint-disable-next-line
    }, [props.summary.stp_frequency, frequencySelected])


    useEffect(() => {
        if (toggleDateModal === true) {
            const modal = new Modal(document.getElementById("sip-date"), {});
            modal.show();
        }
    }, [toggleDateModal]);

    useEffect(() => {
        if (searchFund === true) {
            const modal = new Modal(document.getElementById("search-funds"), {});
            modal.show();
        }
    }, [searchFund]);

    // close sip date modal
    function _handleCloseModal(status) {
        setToggleDateModal(status);
    }

    // close sip date modal
    function _handleCloseFundModal(status) {
        setSearchFund(false);
    }

    function _handleWeeklyDate(dropdown_value) {
        setDaySelected(dropdown_value);
        setSelectedDate(dropdown_value.value);
        setDaySelectedError("");
    }

    // handle amount
    function _handleAmount(input_value) {
        setAmount(input_value);
        setAmountError("");
    }

    function _handleFrequency(select_value) {
        setFrequencySelected(select_value);
    }

    // handle STP date
    function _handleSelectDate(date, dateString) {
        setSelectedDate(date);
        setSelectedDateString(dateString)
    }


    // handle folio number
    function _handleFolio(select_value) {
        setFolio(select_value);
        setFolioError("")
    }

    // switch screen to select fund
    function _handleStpSelect() {
        setSearchFund(true);
        setTransferError("");
    }

    // handle OTP
    function _handleOTP(input_value) {
        if (input_value.length <= 4) {
            setOtp(input_value);
        }
        setOtpError("");
    }

    // resend OTP
    function _handleResend() {
        setOtp("");
        setOtpError("");
        _generate2FaApi();
    }

    // handle submit OTP
    function _submitOTP() {
        let valid = true;
        if (otp === "") {
            setOtpError("OTP is required");
            valid = false;
        }
        if (otp !== "" && otp.length !== 4) {
            setOtpError("Invalid OTP");
            valid = false;
        }
        if (valid) {
            _placeOrder()
        }
    }

    // handle chose selected fund to be switched
    function _handleChoseFund(fund) {
        setSelectedFundName(fund.fund_name);
        setSelectedFundId(fund.id);
        setSearchFund(false);
        setTransferError("");
    }

    // No of installments
    function _handleInvestment(value) {
        setInvestments(value)
    }

    // navigate to orders
    function _handleSTPNavigate() {
        navigate("/systematic-transactions", {
            state: {
                tab_index: 3,
            }
        })
    }

    // handle submit amount
    function _handleSubmit() {
        let valid = true;
        if (selectedFundId === "") {
            inputRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
            setTransferError("Please select a fund to transfer");
            valid = false;
        }
        if (folio === null && props.fundType === 1) {
            inputRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
            setFolioError("Please select folio");
            valid = false;
        }
        if (amount === "") {
            inputRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
            setAmountError("Please enter amount")
            valid = false
        }
        if (daySelected === null && (frequencySelected === "day_in_a_week" || frequencySelected === "day_in_a_fortnight")) {
            valid = false;
            setDaySelectedError("Please select day for STP");
        }

        if (valid === true) {
            _confirmSwitchOrder()
        }
    }


    const _getFrequencyLabel = (frequency) => {
        if (frequency !== null) {
            const index = frequencyOptions.findIndex((item) => item.id === frequency)
            return (frequencyOptions[index].name)
        }
    }


    // API - resend otp
    const _generate2FaApi = () => {
        _generate2Fa("MF", amount)
            .then((response) => {
                resetCountdown();
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    };



    // API - confirm switch order
    const _confirmSwitchOrder = () => {
        setApiLLoader(true);
        const url = props.fundType === 2 ? "/external/confirm-stp" : "/mf/confirm-stp";

        const request = props.fundType === 2 ?
            {
                id: props.id,
                switch_in_mf_id: selectedFundId,
                amount: amount,
                date: frequencySelected === "daily" ? null : selectedDate,
                installments: investments,
                frequency: frequencySelected,
            }
            :
            {
                switch_out_mf_id: props.summary.mf_id,
                switch_in_mf_id: selectedFundId,
                folio_number: folio.value,
                amount: amount,
                date: frequencySelected === "daily" ? null : selectedDate,
                installments: investments,
                frequency: frequencySelected,
            }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setScreenNumber(2);
                setOtp("");
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLLoader(false);
        })
    }

    // API - place switch order
    const _placeOrder = () => {
        setApiLLoader(true);
        const url = props.fundType === 2 ? "/external/create-stp" : "/mf/create-stp";

        const request = props.fundType === 2 ?
            {
                id: props.id,
                switch_in_mf_id: selectedFundId,
                amount: amount,
                date: selectedDate,
                installments: investments,
                frequency: frequencySelected,
                otp: otp
            }
            :
            {
                switch_out_mf_id: props.summary.mf_id,
                switch_in_mf_id: selectedFundId,
                folio_number: folio.value,
                amount: amount,
                date: selectedDate,
                installments: investments,
                frequency: frequencySelected,
                otp: otp
            }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setScreenNumber(3);
                setModalData({
                    status: 1,
                    image: require("../../Assets/Images/Modal/success.png"),
                    title: "STP order placed",

                })
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLLoader(false);
        })
    }

    return (
        <Fragment>
            <div className={`modal fade ${style.e_switch}`}
                id="create-stp"
                tabIndex="-1"
                aria-labelledby="create-stp"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false">
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content pb-4 ${style.e_content} `}>

                        {screenNumber !== 3 && <div className="border-bottom-1px border-ash-gray px-sm-4 px-3">
                            <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
                                {
                                    screenNumber === 1 &&
                                    <h5 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                        STP
                                    </h5>
                                }
                                {/* {
                                    screenNumber === 2 &&
                                    <h5 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                        Select fund to switch to
                                    </h5>
                                } */}
                                {
                                    screenNumber === 2 &&
                                    <h5 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                        <Icon icon="Arrow-Right"
                                            size={22}
                                            className="e-transform-left-arrow cursor-pointer me-2"
                                            onClick={() => setScreenNumber(1)} />  Enter OTP
                                    </h5>
                                }
                                <Icon icon="close-btn"
                                    size={24}
                                    color={Color.eerie_black}
                                    id="close-fund"
                                    className="cursor-pointer"
                                    data-bs-dismiss="modal" />
                            </div>
                        </div>}

                        {
                            screenNumber === 1 ?
                                <div className={style.e_height}>
                                    <div className="border-radius-12px padding-12px-all e-bg-magnolia mx-sm-4 mx-3 mt-3 e-fund-order">
                                        <div className="row position-relative">
                                            <div className="col-10 position-relative z-index-2">
                                                <h5 className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">STP (Systematic Transfer Plan)</h5>
                                                <p className="e-montserrat-medium e-font-12 e-line-height-20 color-jet-gray mb-0 mt-1">
                                                    Transfer fixed amount of money every month
                                                    from one mutual fund to another.
                                                </p>
                                            </div>
                                            <img src={require("../../Assets/Images/Portfolio/stp_image.png")}
                                                alt="stp"
                                                className="object-fit-contain h-100  position-absolute top-0 end-0 w-auto z-index-1" />
                                        </div>
                                    </div>
                                    <div className="box-shadow-black p-3 mx-sm-4 mx-3 border-radius-16px mt-3 ">
                                        <p className="color-outer-space e-font-14 e-line-height-16 e-montserrat-regular margin-12px-bottom">Transfer from</p>
                                        <div className="d-flex align-items-center gap-8px">

                                            <img src={props.summary.icon}
                                                alt="goal"
                                                width={38}
                                                height={38}
                                                className="box-shadow-black border-radius-6px" />
                                            <span className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">{props.summary.name}</span>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mt-3">
                                            <p className="color-outer-space e-font-14 e-line-height-16 e-montserrat-regular mb-1">Available to transfer</p>
                                            <div className="d-block">
                                                <h6 className="color-eerie-black e-font-16 e-line-height-24 e-montserrat-semi-bold mb-0 text-end">{props.summary.available_to_withdraw_text}</h6>
                                                <h6 className="color-eerie-black e-font-14 e-line-height-24 e-montserrat-regular mb-0 text-end">{props.summary.available_to_withdraw_units} Units</h6>
                                            </div>
                                        </div>
                                        <p className="color-outer-space e-font-12 e-line-height-12 mb-1 e-montserrat-medium mb-1 mt-3">*Exit load and capital gain tax may be applicable</p>
                                    </div>
                                    <div className="px-sm-4 px-3 mt-4" ref={inputRef}>
                                        <h5 className='e-font-14  color-eerie-black e-montserrat-semi-bold e-line-height-24 mb-3'>
                                            Transfer To
                                        </h5>
                                        <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text margin-6px-bottom bg-transparent`}>
                                            Fund name
                                            <span className='color-deep-carmine-pink'>
                                                *
                                            </span>
                                        </p>
                                        <SearchComponent placeholder="Select  fund to transfer to"
                                            size={16}
                                            suffix={true}
                                            disabled={true}
                                            inputState={selectedFundName}
                                            error={transferError}
                                            class="cursor-pointer padding-14px-tb ps-3 pe-5"
                                            onFocus={_handleStpSelect} />
                                        {props.fundType === 1 && <CustomDropdown label="Folio"
                                            options={folioOptions}
                                            value={folio}
                                            postfix="*"
                                            isSingleValue={true}
                                            message="No folios found"
                                            error={folioError}
                                            placeholder="Select Folio"
                                            className="mt-4 pb-2"
                                            onSelectChange={_handleFolio} />}
                                        <CustomAmountInput label={`Monthly ${_getFrequencyLabel(frequencySelected)} amount`}
                                            postfix={"*"}
                                            value={amount}
                                            error={amountError}
                                            placeholder="50,000"
                                            className="mt-4"
                                            handleChange={_handleAmount} />

                                        <EditableSlider label="No. of installments"
                                            className="mt-4"
                                            editType={true}
                                            value={investments}
                                            min={props.summary.min_stp_installments}
                                            max={props.summary.max_stp_installments}
                                            step={1}
                                            // suffix={"Yrs"}
                                            // singularSuffix={"Yrs"}
                                            // pluralSuffix="Yrs"
                                            handleChange={_handleInvestment} />
                                        {frequencyOptions.length > 0 && <div className="mt-4 mb-3">
                                            <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-0 bg-transparent`}>
                                                Select Frequency
                                            </p>

                                            <RadioGroup data={frequencyOptions}
                                                selected={frequencySelected}
                                                handleSelect={_handleFrequency} />

                                        </div>}

                                        {
                                            frequencySelected !== "daily" ?
                                                frequencySelected === "monthly" ?
                                                    <Fragment>
                                                        <p className="e-font-14 e-line-height-24 e-montserrat-medium color-eerie-black mb-2 mt-4">
                                                            Select STP date
                                                        </p>
                                                        {/* SIP component */}
                                                        <div className="padding-14px-tb px-3 bg-white box-shadow-charleston border-1px border-light-grayish-blue d-flex justify-content-between align-items-center border-radius-12px cursor-pointer mb-3"
                                                            onClick={() => _handleCloseModal(true)}>
                                                            <span className="color-rebecca-purple e-font-14 e-montserrat-semi-bold e-line-height-18">{selectedDateString} of every month</span>
                                                            <Icon icon="dob"
                                                                size={24} />

                                                        </div>
                                                    </Fragment>
                                                    :
                                                    (frequencySelected === "day_in_a_week" || frequencySelected === "day_in_a_fortnight") &&
                                                    <CustomDropdown label="Select day"
                                                        postfix="*"
                                                        options={WEEKLY_OPTIONS}
                                                        hideValue
                                                        value={daySelected}
                                                        error={daySelectedError}
                                                        placeholder="Select day"
                                                        className="mt-2 pb-2 mb-3"
                                                        isSingleValue={true}
                                                        menuPlacement="top"
                                                        prefix={frequencySelected === "day_in_a_fortnight" ? `Every alternate ${daySelected !== null && daySelected.label}` : `Every ${daySelected !== null && daySelected.label}`}
                                                        onSelectChange={_handleWeeklyDate} />
                                                :
                                                null
                                        }

                                    </div>
                                </div>
                                :
                                screenNumber === 2 ?
                                    <div className="px-sm-4 px-3 padding-14px-top">
                                        <p className="e-font-14 mb-2 color-eerie-black e-montserrat-regular e-line-height-24">
                                            An OTP has been sent to your PAN linked mobile
                                            number.
                                        </p>
                                        <h6 className="e-font-16 mb-3 color-eerie-black e-montserrat-semi-bold e-line-height-24">{MASKED_NUMBER}</h6>
                                        <CustomPINInput id="otp"
                                            autoFocus={true}
                                            pinString={otp}
                                            error={otpError}
                                            postfix="*"
                                            label="Enter OTP"
                                            onSubmit={_submitOTP}
                                            handleChange={_handleOTP} />
                                        <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`no-decoration color-rebecca-purple e-link 
                                ${seconds === 0 ?
                                                'cursor-pointer'
                                                : 'disabled'}`}
                                            onClick={_handleResend}>
                                            {seconds === 0 ?
                                                'Resend OTP'
                                                : `Resend OTP in ${seconds < 10 ? `0${seconds}` : seconds}s`}
                                        </span>
                                        </p>

                                    </div>
                                    :
                                    <div className="text-center px-sm-4 px-3">
                                        <img src={modalData.image}
                                            alt={modalData.title}
                                            draggable={false}
                                            width={144}
                                            height={144}
                                            className="mx-auto" />
                                        <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-0">
                                            {modalData.title}
                                        </h5>

                                        {/* <p className="e-montserrat-medium e-font-16 e-line-height-28 color-charcoal-gray mt-2 mb-0 ">
                                            {modalData.description}
                                        </p> */}
                                    </div>
                        }
                        {
                            screenNumber === 1 ?
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <PrimaryButton label="Confirm STP" className="mt-3 mx-auto w-100"
                                            disabled={apiLoader}
                                            onPress={_handleSubmit} />
                                    </div>
                                </div>
                                :
                                screenNumber === 2 ?
                                    <div className="px-sm-4 px-3 mt-3">
                                        <div className="row">
                                            <div className="col-6">
                                                <SecondaryButton label="Cancel"
                                                    cancel="modal"
                                                    className="padding-12px-tb w-100 e-bg-magnolia" />
                                            </div>
                                            <div className="col-6">
                                                <PrimaryButton label="Verify"
                                                    className="padding-12px-tb  w-100"
                                                    disabled={apiLoader}
                                                    onPress={_submitOTP} />
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    screenNumber === 3 ?
                                        <div className="row justify-content-center px-sm-4 px-3">
                                            <div className="col-lg-6">
                                                <SecondaryButton label="View STP"
                                                    className="w-100 padding-12px-tb mt-3"
                                                    cancel="modal"
                                                    onPress={_handleSTPNavigate} />
                                            </div>

                                            <div className="col-lg-6">
                                                <PrimaryButton label="Portfolio"
                                                    className="w-100 padding-12px-tb mt-3"
                                                    cancel="modal"
                                                    onPress={() => navigate("/portfolio")} />
                                            </div>
                                        </div>
                                        :
                                        null
                        }
                    </div>
                </div>
                {
                    toggleDateModal &&
                    <Fragment>
                        <SIPDateModal apiDates={props.summary.stp_dates !== null ? stpDates : []}
                            selectedSIPDate={selectedDate}
                            handleSelect={_handleSelectDate}
                            backdrop={!toggleDateModal}
                            title="STP"
                            handleCloseDateModal={() => _handleCloseModal(false)} />
                        <div className="position-fixed bg-black opacity-1 w-100 h-100 z-index-4 top-0 start-0">
                        </div>
                    </Fragment>
                }
                {
                    searchFund === true &&
                    <Fragment>
                        <SearchFunds id={props.fundType === 2 ? props.id : props.summary.mf_id}
                            fundType={props.fundType}
                            title="Select fund to transfer to"
                            url={props.fundType === 2 ? "/external/search-stp-schemes" : "/mf/search-stp-schemes"}
                            backdrop={!searchFund}
                            fund={{
                                fund_name: selectedFundName,
                                mf_id: selectedFundId
                            }}
                            handleChooseFund={_handleChoseFund}
                            handleCloseModal={_handleCloseFundModal} />
                        <div className="position-fixed bg-black opacity-1 w-100 h-100 z-index-4 top-0 start-0">
                        </div>
                    </Fragment>
                }
            </div>
        </Fragment >
    )
}

CreateSTPOrder.defaultProps = {
    fundType: 1
}

export default CreateSTPOrder