/*
*   File : swp.js
*   Author : https://evoqins.com
*   Description : A common listing page for all SWPs in the platform. 
*   Version : 1.0.0
*/
// import packages
import { Fragment, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Modal } from "bootstrap";
import Popup from "reactjs-popup";
import { useLocation } from "react-router-dom";

// import components
import { CustomTabBar } from "../../Components/Tab";
import { EmptyScreen, Loader } from "../../Components/Other";
import { Icon } from "../../Components/Icon";
import { PrimaryButton } from "../../Components/Buttons";
import { CancelSWPModal, EditSWP, PauseSWPModal } from "../../Components/Modal";

// import services
import Color from "../../Styles/color.module.scss";

// import services,helper
import APIService from "../../Services/api-service";
import { useWindowSize } from "../../Helper/helper";

const SWP_TABS = [
    {
        label: "Active",
        id: 1,
        icon: require("../../Assets/Images/Systematic-transaction/active-inactive.svg").default,
        active: require("../../Assets/Images/Systematic-transaction/active.svg").default,
    },

    {
        label: "Executed",
        id: 2,
        icon: require("../../Assets/Images/Systematic-transaction/executed-inactive.svg").default,
        active: require("../../Assets/Images/Systematic-transaction/executed-active.svg").default,

    },
    {
        label: "Cancelled",
        id: 3,
        icon: require("../../Assets/Images/Systematic-transaction/cancelled-inactive.svg").default,
        active: require("../../Assets/Images/Systematic-transaction/cancelled-active.svg").default,

    },
    {
        label: "Paused",
        id: 4,
        icon: require("../../Assets/Images/Systematic-transaction/paused-inactive.svg").default,
        active: require("../../Assets/Images/Systematic-transaction/paused-active.svg").default,

    },
    {
        label: "Completed",
        id: 5,
        icon: require("../../Assets/Images/Systematic-transaction/completed-inactive.svg").default,
        active: require("../../Assets/Images/Systematic-transaction/completed-inactive.svg").default,

    }
]

const NextIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple} />
    )
}

const PrevIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple}
            className="e-transform-left-arrow" />
    )
}

const BreakLabel = () => {
    return (
        <span>...</span>
    )
}

const SWP = () => {
    const location = useLocation();
    const { width } = useWindowSize();
    const [swpTabs, setSWPTabs] = useState(SWP_TABS[0].id);
    const [pageCount, setPageCount] = useState(0); //total number of pages
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [activeSwpList, setActiveSwpList] = useState([]);
    const [activeswpLoader, setActiveswpLoader] = useState(true);
    const [activeExpandedRow, setActiveExpandedRow] = useState({});
    const [showCancelSWPModal, setShowCancelSWPModal] = useState(null);

    const [executedList, setExecutedList] = useState([]);
    const [cancelledList, setCancelledList] = useState([]);
    const [showEditSWPModal, setShowEditSWPModal] = useState(null);
    // eslint-disable-next-line
    const [pausedList, setPausedList] = useState([]);
    const [showRestartModal, setShowRestartModal] = useState(null);
    const [completedList, setCompletedList] = useState([]);
    const [showPauseswpModal, setShowPauseswpModal] = useState(null);

    useEffect(() => {
        if (location.state === null) {
            setActiveswpLoader(true);
            _activeList();
        }
        //eslint-disable-next-line
    }, [currentPage, swpTabs]);

    useEffect(() => {
        if (location.state !== null) {
            setActiveswpLoader(true);
            _activeList();
        }
        //eslint-disable-next-line
    }, [currentPage, swpTabs]);

    useEffect(() => {
        if (showCancelSWPModal !== null) {
            const modal = new Modal(document.getElementById("cancel-swp"));
            modal.show();
        }
        //eslint-disable-next-line
    }, [showCancelSWPModal]);

    useEffect(() => {
        if (showEditSWPModal !== null) {
            const modal = new Modal(document.getElementById("edit-swp"));
            modal.show();
        }
    }, [showEditSWPModal]);

    useEffect(() => {
        if (showRestartModal !== null) {
            const modal = new Modal(document.getElementById("edit-swp"));
            modal.show();
        }
    }, [showRestartModal]);

    useEffect(() => {
        if (showPauseswpModal !== null) {
            const modal = new Modal(document.getElementById("pause-swp"));
            modal.show();
        }
    }, [showPauseswpModal]);

    function _handleswpTabs(tab_index) {
        setSWPTabs(tab_index);
        setCurrentPage(1);
        setActiveExpandedRow({});
    }

    // const _handleSelectBox = (value) => {
    //     setSelectedSwp(value);
    //     setCurrentPage(1);
    //     setActiveExpandedRow({});
    // }

    const _handleRowClick = (row) => {
        setActiveExpandedRow(row);
    }

    // Handle page change
    const _handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber + 1);
        window.scrollTo(0, 0)
    };

    // API - Active swp
    const _activeList = (type) => {
        let url = "";
        switch (swpTabs) {
            case 1:
                url = "/swp/active-list"
                break;
            case 2:
                url = "/swp/processed-list"
                break;
            case 3:
                url = "/swp/cancelled-list"
                break;
            case 4:
                url = "/swp/paused-list"
                break;
            case 5:
                url = "/swp/completed-list"
                break;

            default:
                break;
        }
        const request = {
            "page_num": currentPage,
            "page_size": 12,
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                switch (swpTabs) {
                    case 1:
                        setActiveSwpList(response.data.swps);
                        break;
                    case 2:
                        setExecutedList(response.data.swps);
                        break;
                    case 3:
                        setCancelledList(response.data.swps);
                        break;
                    case 4:
                        setPausedList(response.data.swps);
                        break;
                    case 5:
                        setCompletedList(response.data.swps);
                        break;

                    default:
                        break;
                }

                setPageCount(response.data.total_page);
            } else {
                setActiveSwpList([]);
                setExecutedList([]);
                setCancelledList([]);
                setPausedList([]);
                setCompletedList([]);
                setPageCount(0);
            }
            setActiveswpLoader(false);
        })
    }

    const _handleEditActiveswp = (fund) => {
        setShowEditSWPModal(fund);
    }


    const _handleRestartswp = (fund) => {
        setShowRestartModal(fund);
    }

    // API - consent call
    const _handleSave = (investment_type) => {
        setActiveswpLoader(true);
        setActiveExpandedRow({});
        _activeList(investment_type);
    }



    return (
        <Fragment>
            <CustomTabBar data={SWP_TABS}
                selectedTab={swpTabs}
                onSelectTab={_handleswpTabs}
                type={3} />
            <div className="d-flex flex-wrap gap-16px justify-content-between align-items-center border-top-1px border-bright-gray pt-4">
                <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-eerie-black mb-0 ">{SWP_TABS.find(item => item.id === swpTabs).label} SWPs</p>

                {/* <CustomSelectBox placeholder=""
                    className="w-200px"
                    value={selectedSwp}
                    options={SWP_OPTIONS}
                    onSelectChange={_handleSelectBox} /> */}
            </div>
            <div className="d-md-block d-none">
                {
                    swpTabs === 1 ?
                        <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                            {
                                activeswpLoader === true ?
                                    <Loader height="min-h-500px" />
                                    :
                                    activeSwpList.length === 0 ?
                                        <EmptyScreen className="my-5"
                                            title="No SWPs"
                                            type={3}
                                            description="" />
                                        :
                                        <Fragment>
                                            <div className="mb-4 border-radius-tlr-16px d-md-block d-none">
                                                {
                                                    activeSwpList.map((item, key) =>
                                                        <div className={`p-4 border-bottom-1px border-gainsboro cursor-pointer ${activeExpandedRow.swp_id === item.swp_id ? "e-bg-pastel-purple" : ""} ${key === 0 ? "border-radius-tlr-16px" : ""}`}
                                                            key={key}
                                                            onClick={() => _handleRowClick(item)}>
                                                            <div className={`row ${activeExpandedRow.swp_id === item.swp_id ? "border-bottom-1px border-bright-gray pb-3" : ""}`}>
                                                                <div className="col-xl-5 col-5">
                                                                    {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                        <Icon icon="external" size={18}
                                                                        /> External
                                                                    </div>}
                                                                    <div className="d-flex gap-8px align-items-center">
                                                                        <img src={item.icon}
                                                                            width={48}
                                                                            height={48}
                                                                            alt={item.name}
                                                                            draggable={false}
                                                                            className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                                        <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="d-flex  gap-26px align-items-center justify-content-between">
                                                                        <div className="w-max-content">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>SWP Schedule</p>
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.date_str}</p>
                                                                        </div>
                                                                        <img src={require("../../Assets/Images/Systematic-transaction/active-sip-arrow.png")}
                                                                            alt="Arrow"
                                                                            width={28}
                                                                            height={28}
                                                                            draggable={false} />
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="w-max-content ms-auto">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>No. of installment</p>
                                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.number_of_installments}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="w-max-content ms-auto">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Amount</p>
                                                                        <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0">{item.amount_text}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                activeExpandedRow.swp_id === item.swp_id &&
                                                                <div className="d-flex gap-16px justify-content-end pt-3 align-items-center  e-bg-pastel-purple ">
                                                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-deep-carmine-pink mb-0 padding-10px-tb px-2"
                                                                        onClick={() => setShowCancelSWPModal(item)}>Cancel SWP</p>
                                                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-rebecca-purple mb-0 padding-10px-tb px-2"
                                                                        onClick={() => setShowPauseswpModal(item)}>Pause SWP</p>
                                                                    <PrimaryButton label="Edit"
                                                                        className="padding-10px-tb px-4"
                                                                        onPress={() => _handleEditActiveswp(item)} />
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                }
                                                {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeSwpList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                            </div>
                                            <div className="mb-4 d-md-none d-block">
                                                {
                                                    activeSwpList.map((item, key) => (
                                                        <div key={key}
                                                            className={`p-4 border-bottom-1px border-bright-gray cursor-pointer `}>
                                                            <div className={`row `}>
                                                                <div className="col-12">
                                                                    <div className="d-flex gap-8px align-items-center">
                                                                        <img
                                                                            src={item.icon}
                                                                            width={48}
                                                                            height={48}
                                                                            alt={item.name}
                                                                            draggable={false}
                                                                            className="border-radius-12px e-fund-detail-chart object-fit-contain"
                                                                        />
                                                                        <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">
                                                                            {item.name}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="d-flex align-items-center justify-content-between my-3">
                                                                        <div className="w-max-content">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>SWP Schedule</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0">{item.date_str}</p>
                                                                        </div>
                                                                        <img src={require("../../Assets/Images/Systematic-transaction/active-sip-arrow.png")}
                                                                            alt="Arrow"
                                                                            width={28}
                                                                            height={28}
                                                                            draggable={false} />
                                                                        <div>
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>No. of installment</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0">{item.number_of_installments}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-between border-top-1px border-gainsboro pt-3">
                                                                    <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Amount</p>
                                                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0">{item.amount_text}</p>
                                                                </div>
                                                                <div className="d-flex gap-16px flex-wrap justify-content-sm-end pt-3 ">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <ReactPaginate
                                                previousLabel={<PrevIcon />}
                                                nextLabel={<NextIcon />}
                                                breakLabel={<BreakLabel />}
                                                pageCount={pageCount}
                                                pageRangeDisplayed={2}
                                                marginPagesDisplayed={1}
                                                onPageChange={(value) => _handlePageChange(value.selected)}
                                                renderOnZeroPageCount={null}
                                                forcePage={currentPage - 1}
                                                containerClassName="e-pagination"
                                                activeClassName="e-pagination-active"
                                            />
                                        </Fragment>
                            }
                        </div>
                        :
                        swpTabs === 2 ?
                            // Executed tab
                            <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                {
                                    activeswpLoader === true ?
                                        <Loader height="min-h-500px" />
                                        :
                                        executedList.length === 0 ?
                                            <EmptyScreen className="my-5"
                                                title="No SWPs"
                                                type={3}
                                                description="" />
                                            :
                                            <Fragment>
                                                <div className="mb-4 border-radius-tlr-16px ">
                                                    {
                                                        executedList.map((item, key) =>
                                                            <div className={`p-4 border-bottom-1px border-bright-gray`}>
                                                                <div className={`row 3 row-gap-16px`}
                                                                    key={key}>
                                                                    <div className="col-xl-5 col-lg-5 col-md-6">
                                                                        {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                            <Icon icon="external" size={18}
                                                                            /> External
                                                                        </div>}
                                                                        <div className="d-flex gap-8px align-items-center">
                                                                            <img src={item.icon}
                                                                                width={48}
                                                                                height={48}
                                                                                alt={item.name}
                                                                                draggable={false}
                                                                                className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                                            <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>SWP Schedule</p>
                                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.executed_date}</p>
                                                                    </div>
                                                                    <div className="col-sm-2 col-6">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>No. of installment</p>
                                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.number_of_installments}</p>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Amount</p>
                                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.amount_text}</p>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Status</p>
                                                                        <div className="d-flex gap-4px align-items-center">
                                                                            <img src={item.status === "Successful" ? require("../../Assets/Images/Systematic-transaction/success.svg").default
                                                                                :
                                                                                item.status === "Pending" ?
                                                                                    require("../../Assets/Images/Orders/pending.svg").default
                                                                                    :
                                                                                    require("../../Assets/Images/Systematic-transaction/failed.svg").default
                                                                            } alt={item.status}
                                                                                width={20}
                                                                                height={20}
                                                                                draggable={false} />
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 ${item.status === "Successful" ? "color-go-green" : item.status === "Pending" ? "color-golden-orange" : "color-deep-carmine-pink"}`}>{item.status}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeSwpList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                </div>
                                                <ReactPaginate previousLabel={<PrevIcon />}
                                                    nextLabel={<NextIcon />}
                                                    breakLabel={<BreakLabel />}
                                                    pageCount={pageCount}
                                                    pageRangeDisplayed={2}
                                                    marginPagesDisplayed={1}
                                                    onPageChange={(value) => _handlePageChange(value.selected)}
                                                    renderOnZeroPageCount={null}
                                                    forcePage={currentPage - 1}
                                                    containerClassName="e-pagination "
                                                    activeClassName="e-pagination-active" />
                                            </Fragment>
                                }
                            </div>
                            :
                            swpTabs === 3 ?
                                // Cancelled tab
                                <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                    {
                                        activeswpLoader === true ?
                                            <Loader height="min-h-500px" />
                                            :
                                            cancelledList.length === 0 ?
                                                <EmptyScreen className="my-5"
                                                    title="No SWPs"
                                                    type={3}
                                                    description="" />
                                                :
                                                <Fragment>
                                                    <div className="mb-4 border-radius-tlr-16px ">
                                                        {
                                                            cancelledList.map((item, key) =>
                                                                <div className={`p-4 border-bottom-1px border-bright-gray cursor-pointer ${activeExpandedRow.swp_id === item.swp_id && width > 768 ? "e-bg-pastel-purple" : ""} ${key === 0 ? "border-radius-tlr-16px" : ""}`}
                                                                    key={key}
                                                                    onClick={() => _handleRowClick(item)}>
                                                                    <div className={`row 3 row-gap-16px ${activeExpandedRow.swp_id === item.swp_id ? "border-bottom-1px border-bright-gray pb-3" : ""}`}>
                                                                        <div className="col-xl-4">
                                                                            {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                                <Icon icon="external" size={18}
                                                                                /> External
                                                                            </div>}
                                                                            <div className="d-flex gap-8px align-items-center">
                                                                                <img src={item.icon}
                                                                                    width={48}
                                                                                    height={48}
                                                                                    alt={item.name}
                                                                                    draggable={false}
                                                                                    className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                                                <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>SWP Schedule</p>
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.swp_date}</p>
                                                                        </div>
                                                                        <div className="col-md-2 col-6">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Created on</p>
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.created}</p>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Amount</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">{item.amount_text}</p>
                                                                        </div>
                                                                        <div className="col-md-2 col-6">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Cancelled on</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-deep-carmine-pink">{item.cancelled}</p>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        width > 768 ?
                                                                            activeExpandedRow.swp_id === item.swp_id &&
                                                                            <div className={`d-flex justify-content-end pt-3 e-bg-pastel-purple`}>
                                                                                <PrimaryButton label="Restart SWP"
                                                                                    className="padding-10px-tb px-4"
                                                                                    onPress={() => _handleRestartswp(item)} />
                                                                            </div>
                                                                            :
                                                                            <div className={`d-flex justify-content-end pt-3 `}>
                                                                                <PrimaryButton label="Restart SWP"
                                                                                    className="padding-10px-tb px-4"
                                                                                    onPress={() => _handleRestartswp(item)} />
                                                                            </div>
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeSwpList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                    </div>
                                                    <ReactPaginate previousLabel={<PrevIcon />}
                                                        nextLabel={<NextIcon />}
                                                        breakLabel={<BreakLabel />}
                                                        pageCount={pageCount}
                                                        pageRangeDisplayed={2}
                                                        marginPagesDisplayed={1}
                                                        onPageChange={(value) => _handlePageChange(value.selected)}
                                                        renderOnZeroPageCount={null}
                                                        forcePage={currentPage - 1}
                                                        containerClassName="e-pagination "
                                                        activeClassName="e-pagination-active" />
                                                </Fragment>
                                    }
                                </div>
                                :
                                swpTabs === 4 ?
                                    // Paused tab
                                    <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                        {
                                            activeswpLoader === true ?
                                                <Loader height="min-h-500px" />
                                                :
                                                pausedList.length === 0 ?
                                                    <EmptyScreen className="my-5"
                                                        title="No SWPs"
                                                        type={3}
                                                        description="" />
                                                    :
                                                    <Fragment>
                                                        <div className="mb-4 border-radius-tlr-16px ">
                                                            {
                                                                pausedList.map((item, key) =>
                                                                    <div className={`p-4 border-bottom-1px border-bright-gray cursor-pointer ${activeExpandedRow.swp_id === item.swp_id && width > 768 ? "e-bg-pastel-purple" : ""} ${key === 0 ? "border-radius-tlr-16px" : ""}`}
                                                                        key={key}
                                                                        onClick={() => _handleRowClick(item)}>
                                                                        <div className={`row row-gap-16px ${activeExpandedRow.swp_id === item.swp_id && "border-bottom-1px border-bright-gray pb-3"}`}>
                                                                            <div className="col-xl-4">
                                                                                {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                                    <Icon icon="external" size={18}
                                                                                    /> External
                                                                                </div>}
                                                                                <div className="d-flex gap-8px align-items-center">
                                                                                    <img src={item.icon}
                                                                                        width={48}
                                                                                        height={48}
                                                                                        alt={item.name}
                                                                                        draggable={false}
                                                                                        className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                                                    <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>SWP Schedule</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.swp_date}</p>
                                                                            </div>
                                                                            <div className="col-md-2 col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Created on</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.created}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Amount</p>
                                                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">{item.amount_text}</p>
                                                                            </div>
                                                                            <div className="col-md-2 col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Paused on</p>
                                                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-cyan-cornflower-blue">{item.paused}</p>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            width > 768 ?
                                                                                activeExpandedRow.swp_id === item.swp_id &&
                                                                                <div className="d-flex justify-content-end pt-3  e-bg-pastel-purple ">
                                                                                    <PrimaryButton label="Resume SWP"
                                                                                        className="padding-10px-tb px-4"
                                                                                        onPress={() => _handleRestartswp(item)} />
                                                                                </div>
                                                                                :
                                                                                <div className="d-flex justify-content-end pt-3 border-top-1px border-gainsboro mt-3 ">
                                                                                    <PrimaryButton label="Resume SWP"
                                                                                        className="padding-10px-tb px-4"
                                                                                        onPress={() => _handleRestartswp(item)} />
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeSwpList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                        </div>
                                                        <ReactPaginate previousLabel={<PrevIcon />}
                                                            nextLabel={<NextIcon />}
                                                            breakLabel={<BreakLabel />}
                                                            pageCount={pageCount}
                                                            pageRangeDisplayed={2}
                                                            marginPagesDisplayed={1}
                                                            onPageChange={(value) => _handlePageChange(value.selected)}
                                                            renderOnZeroPageCount={null}
                                                            forcePage={currentPage - 1}
                                                            containerClassName="e-pagination "
                                                            activeClassName="e-pagination-active" />
                                                    </Fragment>
                                        }
                                    </div>
                                    :
                                    // Completed tab
                                    <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                        {
                                            activeswpLoader === true ?
                                                <Loader height="min-h-500px" />
                                                :
                                                completedList.length === 0 ?
                                                    <EmptyScreen className="my-5"
                                                        title="No SWPs"
                                                        type={3}
                                                        description="" />
                                                    :
                                                    <Fragment>
                                                        <div className="mb-4 border-radius-tlr-16px ">
                                                            {
                                                                completedList.map((item, key) =>
                                                                    <div className={`p-4 border-bottom-1px border-bright-gray`}
                                                                        key={key}>
                                                                        <div className={`row 3 row-gap-16px`}>
                                                                            <div className="col-md-5">
                                                                                {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                                    <Icon icon="external" size={18}
                                                                                    /> External
                                                                                </div>}
                                                                                <div className="d-flex gap-8px align-items-center">
                                                                                    <img src={item.icon}
                                                                                        width={48}
                                                                                        height={48}
                                                                                        alt={item.name}
                                                                                        draggable={false}
                                                                                        className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                                                    <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>SWP Schedule</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.swp_date}</p>
                                                                            </div>
                                                                            <div className="col-md-2 col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Created on</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.created}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Amount</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.amount_text}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Completed on</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-ufo-green`}>{item.completed_on}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                        data={activeSwpList}
                                                        customStyles={TABLE_STYLE}
                                                        expandableRows={true}
                                                        expandableRowsComponent={ExpandedComponent}
                                                        rowClick={(row) => _handleRowClick(row)} /> */}
                                                        </div>
                                                        <ReactPaginate previousLabel={<PrevIcon />}
                                                            nextLabel={<NextIcon />}
                                                            breakLabel={<BreakLabel />}
                                                            pageCount={pageCount}
                                                            pageRangeDisplayed={2}
                                                            marginPagesDisplayed={1}
                                                            onPageChange={(value) => _handlePageChange(value.selected)}
                                                            renderOnZeroPageCount={null}
                                                            forcePage={currentPage - 1}
                                                            containerClassName="e-pagination "
                                                            activeClassName="e-pagination-active" />
                                                    </Fragment>
                                        }
                                    </div>
                }
            </div>
            <div className="d-md-none d-block">
                {
                    swpTabs === 1 ?
                        <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                            {

                                activeswpLoader === true ?

                                    <Loader height="min-h-500px" />

                                    :
                                    activeSwpList.length === 0 ?

                                        <EmptyScreen className="my-5"
                                            title="No SWPs"
                                            type={3}
                                            description="" />

                                        :
                                        <Fragment>

                                            <div className="mb-4 d-md-none d-block">
                                                {
                                                    activeSwpList.map((item, key) => (
                                                        <div
                                                            key={key} // Move key to the outermost element
                                                            className={`p-4 border-bottom-1px border-bright-gray cursor-pointer `}>
                                                            <div className={`row`}>
                                                                {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                    <Icon icon="external" size={18}
                                                                    /> External
                                                                </div>}
                                                                <div className="col-12 d-flex align-items-center justify-content-between gap-16px">
                                                                    <div className="d-flex gap-8px align-items-center">
                                                                        <img src={item.icon}
                                                                            width={36}
                                                                            height={36}
                                                                            alt={item.name}
                                                                            draggable={false}
                                                                            className="border-radius-8px e-fund-detail-chart object-fit-contain"
                                                                        />
                                                                        <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">
                                                                            {item.name}
                                                                        </p>
                                                                    </div>
                                                                    <Popup
                                                                        trigger={
                                                                            <img src={require("../../Assets/Images/Systematic-transaction/more.png")}
                                                                                alt="More"
                                                                                draggable={false}
                                                                                width={24}
                                                                                height={24}
                                                                                className="cursor-pointer" />
                                                                        }
                                                                        position="bottom right"
                                                                        closeOnDocumentClick
                                                                    >
                                                                        <div className="d-flex flex-column gap-12px justify-content-end bg-white p-2 e-ranking-category">
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black cursor-pointer mb-0"
                                                                                onClick={() => _handleEditActiveswp(item)}>Edit</p>

                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black cursor-pointer mb-0"
                                                                                onClick={() => setShowPauseswpModal(item)}>Pause SWP</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black cursor-pointer mb-0"
                                                                                onClick={() => setShowCancelSWPModal(item)}>Cancel SWP</p>
                                                                        </div>
                                                                    </Popup>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="d-flex align-items-center justify-content-between my-3">
                                                                        <div className="w-max-content">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>SWP Schedule</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0">{item.date_str}</p>
                                                                        </div>
                                                                        <img
                                                                            src={require("../../Assets/Images/Systematic-transaction/active-sip-arrow.png")}
                                                                            alt="Arrow"
                                                                            width={28}
                                                                            height={28}
                                                                            draggable={false}
                                                                        />
                                                                        <div>
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>No. of installment</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0">{item.number_of_installments}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-between border-top-1px border-gainsboro pt-3">
                                                                    <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Amount</p>
                                                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0">{item.amount_text}</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                            <ReactPaginate
                                                previousLabel={<PrevIcon />}
                                                nextLabel={<NextIcon />}
                                                breakLabel={<BreakLabel />}
                                                pageCount={pageCount}
                                                pageRangeDisplayed={2}
                                                marginPagesDisplayed={1}
                                                onPageChange={(value) => _handlePageChange(value.selected)}
                                                renderOnZeroPageCount={null}
                                                forcePage={currentPage - 1}
                                                containerClassName="e-pagination"
                                                activeClassName="e-pagination-active"
                                            />
                                        </Fragment>
                            }
                        </div>

                        :
                        swpTabs === 2 ?
                            // Executed tab
                            <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                {
                                    activeswpLoader === true ?
                                        <Loader height="min-h-500px" />
                                        :
                                        executedList.length === 0 ?
                                            <EmptyScreen className="my-5"
                                                title="No SWPs"
                                                type={3}
                                                description="" />
                                            :
                                            <Fragment>
                                                <div className="mb-4 border-radius-tlr-16px ">
                                                    {
                                                        executedList.map((item, key) =>
                                                            <div className={`p-4 border-bottom-1px border-bright-gray`}>
                                                                <div className={`row row-gap-16px`}
                                                                    key={key}>
                                                                    <div className="col-md-6">
                                                                        {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                            <Icon icon="external" size={18}
                                                                            /> External
                                                                        </div>}
                                                                        <div className="d-flex gap-8px align-items-center">
                                                                            <img src={item.icon}
                                                                                width={36}
                                                                                height={36}
                                                                                alt={item.name}
                                                                                draggable={false}
                                                                                className="border-radius-8px e-fund-detail-chart object-fit-contain" />
                                                                            <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>SWP Schedule</p>
                                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.executed_date}</p>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Created on</p>
                                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 `}>{item.created}</p>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Amount</p>
                                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 `}>{item.amount_text}</p>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Status</p>
                                                                        <div className="d-flex gap-4px align-items-center">
                                                                            <img src={item.status === "Successful" ? require("../../Assets/Images/Systematic-transaction/success.svg").default
                                                                                :
                                                                                item.status === "Pending" ?
                                                                                    require("../../Assets/Images/Orders/pending.svg").default
                                                                                    :
                                                                                    require("../../Assets/Images/Systematic-transaction/failed.svg").default
                                                                            } alt={item.status}
                                                                                width={20}
                                                                                height={20}
                                                                                draggable={false} />
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 ${item.status === "Successful" ? "color-go-green" : item.status === "Pending" ? "color-golden-orange" : "color-deep-carmine-pink"}`}>{item.status}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeSwpList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                </div>

                                                <ReactPaginate previousLabel={<PrevIcon />}
                                                    nextLabel={<NextIcon />}
                                                    breakLabel={<BreakLabel />}
                                                    pageCount={pageCount}
                                                    pageRangeDisplayed={2}
                                                    marginPagesDisplayed={1}
                                                    onPageChange={(value) => _handlePageChange(value.selected)}
                                                    renderOnZeroPageCount={null}
                                                    forcePage={currentPage - 1}
                                                    containerClassName="e-pagination "
                                                    activeClassName="e-pagination-active" />
                                            </Fragment>

                                }

                            </div>
                            :
                            swpTabs === 3 ?
                                // Cancelled tab
                                <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                    {
                                        activeswpLoader === true ?
                                            <Loader height="min-h-500px" />
                                            :
                                            cancelledList.length === 0 ?
                                                <EmptyScreen className="my-5"
                                                    title="No SWPs"
                                                    type={3}
                                                    description="" />
                                                :
                                                <Fragment>
                                                    <div className="mb-4 border-radius-tlr-16px ">
                                                        {
                                                            cancelledList.map((item, key) =>
                                                                <div className={`p-4 border-bottom-1px border-bright-gray cursor-pointer ${key === 0 && "border-radius-tlr-16px"}`}
                                                                    key={key}>
                                                                    <div className={`row row-gap-16px `}>
                                                                        <div className="col-md-4 d-flex align-items-center gap-16px justify-content-between">
                                                                            {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                                <Icon icon="external" size={18}
                                                                                /> External
                                                                            </div>}
                                                                            <div className="d-flex gap-8px align-items-center">
                                                                                <img src={item.icon}
                                                                                    width={36}
                                                                                    height={36}
                                                                                    alt={item.name}
                                                                                    draggable={false}
                                                                                    className="border-radius-8px e-fund-detail-chart object-fit-contain" />
                                                                                <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                            </div>
                                                                            <Popup
                                                                                trigger={
                                                                                    <img src={require("../../Assets/Images/Systematic-transaction/more.png")}
                                                                                        alt="More"
                                                                                        draggable={false}
                                                                                        width={24}
                                                                                        height={24}
                                                                                        className="cursor-pointer" />
                                                                                }
                                                                                position="bottom right"
                                                                                closeOnDocumentClick
                                                                            >
                                                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black cursor-pointer mb-0 bg-white p-2 e-ranking-category"
                                                                                    onClick={() => _handleRestartswp(item)}>Restart SWP</p>
                                                                            </Popup>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>SWP Schedule</p>
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.swp_date}</p>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Created on</p>
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.created}</p>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Amount</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">{item.amount_text}</p>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Cancelled on</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-deep-carmine-pink">{item.cancelled}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeSwpList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                    </div>

                                                    <ReactPaginate previousLabel={<PrevIcon />}
                                                        nextLabel={<NextIcon />}
                                                        breakLabel={<BreakLabel />}
                                                        pageCount={pageCount}
                                                        pageRangeDisplayed={2}
                                                        marginPagesDisplayed={1}
                                                        onPageChange={(value) => _handlePageChange(value.selected)}
                                                        renderOnZeroPageCount={null}
                                                        forcePage={currentPage - 1}
                                                        containerClassName="e-pagination "
                                                        activeClassName="e-pagination-active" />
                                                </Fragment>

                                    }

                                </div>
                                :
                                swpTabs === 4 ?
                                    // Paused tab
                                    <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                        {
                                            activeswpLoader === true ?
                                                <Loader height="min-h-500px" />
                                                :
                                                pausedList.length === 0 ?
                                                    <EmptyScreen className="my-5"
                                                        title="No SWPs"
                                                        type={3}
                                                        description="" />
                                                    :
                                                    <Fragment>
                                                        <div className="mb-4 border-radius-tlr-16px ">
                                                            {
                                                                pausedList.map((item, key) =>
                                                                    <div className={`p-4 border-bottom-1px border-bright-gray cursor-pointer
                                                                    ${key === 0 && "border-radius-tlr-16px"}`}
                                                                        key={key}
                                                                        onClick={() => _handleRowClick(item)}

                                                                    >
                                                                        <div className={`row row-gap-16px`}>
                                                                            <div className="col-md-4 d-flex align-items-center justify-content-between gap-16px">
                                                                                {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                                    <Icon icon="external" size={18}
                                                                                    /> External
                                                                                </div>}
                                                                                <div className="d-flex gap-8px align-items-center">
                                                                                    <img src={item.icon}
                                                                                        width={36}
                                                                                        height={36}
                                                                                        alt={item.name}
                                                                                        draggable={false}
                                                                                        className="border-radius-8px e-fund-detail-chart object-fit-contain" />
                                                                                    <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                                </div>
                                                                                <Popup
                                                                                    trigger={
                                                                                        <img src={require("../../Assets/Images/Systematic-transaction/more.png")}
                                                                                            alt="More"
                                                                                            draggable={false}
                                                                                            width={24}
                                                                                            height={24}
                                                                                            className="cursor-pointer" />
                                                                                    }
                                                                                    position="bottom right"
                                                                                    closeOnDocumentClick
                                                                                >
                                                                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black cursor-pointer mb-0 bg-white p-2 e-ranking-category"
                                                                                        onClick={() => _handleRestartswp(item)}>Resume SWP</p>
                                                                                </Popup>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>SWP Schedule</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.swp_date}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Created on</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.created}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Amount</p>
                                                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">{item.amount_text}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Paused on</p>
                                                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-cyan-cornflower-blue">{item.paused}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeSwpList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                        </div>

                                                        <ReactPaginate previousLabel={<PrevIcon />}
                                                            nextLabel={<NextIcon />}
                                                            breakLabel={<BreakLabel />}
                                                            pageCount={pageCount}
                                                            pageRangeDisplayed={2}
                                                            marginPagesDisplayed={1}
                                                            onPageChange={(value) => _handlePageChange(value.selected)}
                                                            renderOnZeroPageCount={null}
                                                            forcePage={currentPage - 1}
                                                            containerClassName="e-pagination "
                                                            activeClassName="e-pagination-active" />
                                                    </Fragment>

                                        }

                                    </div>
                                    :
                                    // Completed tab
                                    <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                        {
                                            activeswpLoader === true ?
                                                <Loader height="min-h-500px" />
                                                :
                                                completedList.length === 0 ?
                                                    <EmptyScreen className="my-5"
                                                        title="No SWPs"
                                                        type={3}
                                                        description="" />
                                                    :
                                                    <Fragment>
                                                        <div className="mb-4 border-radius-tlr-16px ">
                                                            {
                                                                completedList.map((item, key) =>
                                                                    <div className={`p-4 border-bottom-1px border-bright-gray`}
                                                                        key={key}>
                                                                        <div className={`row row-gap-16px`}>
                                                                            <div className="col-md-5">
                                                                                {item.is_external === true && <div className="d-flex align-items-center gap-2px color-bright-periwinkle e-font-14 line-height-20px e-montserrat-medium mb-2">
                                                                                    <Icon icon="external" size={18}
                                                                                    /> External
                                                                                </div>}
                                                                                <div className="d-flex gap-8px align-items-center">
                                                                                    <img src={item.icon}
                                                                                        width={36}
                                                                                        height={36}
                                                                                        alt={item.name}
                                                                                        draggable={false}
                                                                                        className="border-radius-8px e-fund-detail-chart object-fit-contain" />
                                                                                    <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>SWP Schedule</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.swp_date}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Created on</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 coloreerie-black`}>{item.created}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Amount</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 coloreerie-black`}>{item.amount_text}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className={`e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ${item.is_external === true ? "pt-md-4" : ""}`}>Completed on</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-ufo-green`}>{item.completed_on}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>

                                                        <ReactPaginate previousLabel={<PrevIcon />}
                                                            nextLabel={<NextIcon />}
                                                            breakLabel={<BreakLabel />}
                                                            pageCount={pageCount}
                                                            pageRangeDisplayed={2}
                                                            marginPagesDisplayed={1}
                                                            onPageChange={(value) => _handlePageChange(value.selected)}
                                                            renderOnZeroPageCount={null}
                                                            forcePage={currentPage - 1}
                                                            containerClassName="e-pagination "
                                                            activeClassName="e-pagination-active" />
                                                    </Fragment>

                                        }

                                    </div>
                }
            </div>

            {
                showCancelSWPModal !== null &&
                <CancelSWPModal closeModal={() => setShowCancelSWPModal(null)}
                    id={showCancelSWPModal.swp_id}
                    success={() => _activeList()} />
            }
            {
                showEditSWPModal !== null &&
                <EditSWP fund={showEditSWPModal}
                    header="Edit SWP"
                    type={1}
                    title="Customize your SWP details"
                    buttonLabel="Save"
                    closeModal={() => _handleEditActiveswp(null)}
                    success={_handleSave} />
            }

            {
                showRestartModal !== null &&
                <EditSWP fund={showRestartModal}
                    header={swpTabs === 3 ? "Restart SWP" : "Resume SWP"}
                    type={3}
                    title="Customize your Restart SWP details"
                    buttonLabel={swpTabs === 3 ? "Restart SWP" : "Resume SWP"}
                    closeModal={() => _handleRestartswp(null)}
                    success={_handleSave} />
            }



            {
                showPauseswpModal !== null &&
                <PauseSWPModal close={() => setShowPauseswpModal(null)}
                    id={showPauseswpModal.swp_id}
                    confirm={() => _activeList()} />
            }
        </Fragment>
    )
}

export default SWP;