
/*
 *   File : manage-bank.js
 *   Author URI : https://evoqins.com
 *   Description : Confirm invest modal 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";

// Components
import { Icon } from "../Icon";
import { CustomFileInput, CustomTextInput, RadioGroup } from "../FormElements";
import { PrimaryButton, SecondaryButton } from "../Buttons";


// Services , helper
import APIService from "../../Services/api-service";
import { _getOptions } from "../../Helper/helper";

// Store
import store from "../../Store";

// Styles
import style from "../../Styles/Components/manage-bank.module.scss"
import Color from "../../Styles/color.module.scss";
import { _uploadDocumentToS3 } from "../../Services/aws-service";
import { _getFilePath } from "../../Helper/api";


const ManageBank = (props) => {

    const GENERAL_DATA = store.getState().Reducer.GENERAL_DATA;
    const PROFILE_DATA = store.getState().Reducer.PROFILE_DETAILS;

    const [accountTypes, setAccountTypes] = useState([]);
    const [accountNumber, setAccountNumber] = useState("");
    const [accountNumberError, setAccountNumberError] = useState("");
    const [name, setName] = useState("");
    // const [nameError, setNameError] = useState("");

    const [accountType, setAccountType] = useState(null);
    const [ifsc, setIfsc] = useState("");
    const [ifscError, setIfscError] = useState("");
    const [isPrimary, setIsPrimary] = useState(false);
    const [createMandate, setCreateMandate] = useState(true);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [bankStatus, setBankStatus] = useState(false);
    const [proofRequired, setProofRequired] = useState(false);
    const [frontUrl, setFrontUrl] = useState("");
    const [bankStatementName, setBankStatementName] = useState("");

    const [frontUrlError, setFrontUrlError] = useState("");
    const [fileUploader, setFileUploader] = useState(false);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("manage-bank");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });

        if (Object.keys(props.data).length !== 0) {
            setAccountNumber(props.data.account_number);
            setName(props.data.account_holder);
            setAccountType(props.data.account_type_id);
            setIfsc(props.data.ifsc_code);
            setIsPrimary(props.data.primary);
            setEdit(true);
        }

        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        // account types options
        let account_types = [];
        if (PROFILE_DATA.is_nri) {
            account_types = _getOptions(GENERAL_DATA.nri_bank_account_types, 1);
        }
        else {
            account_types = _getOptions(GENERAL_DATA.ri_bank_account_types, 1);
        }

        setAccountTypes(account_types);
        if (Object.keys(props.data).length === 0) {
            setAccountType(account_types[0].id);
        }
        //eslint-disable-next-line
    }, [GENERAL_DATA, props.data]);

    useEffect(() => {
        if (bankStatus === true) {
            const intervalId = setInterval(() => {
                _verifyBank();
            }, 1000); // 1000 milliseconds = 1 second

            // Cleanup function to clear the interval when the component is unmounted
            return () => clearInterval(intervalId);
        }
        // eslint-disable-next-line
    }, [bankStatus]);

    // API - create esign
    const _verifyBank = () => {
        const url = "/kyc/check-bank-verification";

        APIService(false, url).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                if (response.data.status === "SUCCESS") {
                    document.getElementById("close").dispatchEvent(new Event("click"));
                    props.submitInvestment();
                } else if (response.data.status === "FAILED") {
                    if (response.data.proof_required === true) {
                        setProofRequired(response.data.proof_required);
                        setBankStatus(false);
                    } else {
                        toast.error(response.data.message, {
                            type: 'error'
                        });
                        document.getElementById("close").dispatchEvent(new Event("click"));
                        props.reSubmit();
                    }
                }
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
                document.getElementById("close").dispatchEvent(new Event("click"));
            }
        });
    }

    // handle Acc number
    function _handleAccNumber(input_value) {
        setAccountNumber(input_value);
        setAccountNumberError("");
    }

    // handle Acc holder name
    // function _handleName(input_value) {
    //     setName(input_value);
    //     setNameError("");
    // }

    // handle Acc type
    function _handleAccType(id) {
        setAccountType(id);
    }

    // handle IFSC
    function _handleIFSC(input_value) {
        setIfsc(input_value);
        setIfscError("");
    }

    function _handlePrimary() {
        setIsPrimary(!isPrimary);
    }

    function _handleMandate() {
        setCreateMandate(!createMandate)
    }

    // function handle PAN

    function _handleBankUpload(file) {
        if (file.size > 5242880) {
            setFrontUrlError("Upload Statement below 5MB");
        } else {
            _uploadBankStatement(file, 2);
            setFrontUrlError("");
        }
    }

    function _validate() {
        let valid = true;
        if (accountNumber === "") {
            setAccountNumberError("Enter correct account number");
            valid = false;
        }
        // if (name === "") {
        //     setNameError("Enter account holder name");
        //     valid = false;
        // }
        if (ifsc === "") {
            setIfscError("Enter IFSC code");
            valid = false;
        }
        if (valid === true) {
            setButtonLoader(true);
            _updateBank();
        }
    }

    // API - update bank details
    function _updateBank() {

        const url = "/profile/bank/update";

        const request = {
            account_number: accountNumber,
            account_holder_name: name,
            account_type: accountType,
            ifsc: ifsc,
            primary: isPrimary,
            update_sips: createMandate,
            bank_id: props.data.id ? props.data.id : null
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                if (response.data.should_verify) {
                    setBankStatus(true);
                } else if (response.data.proof_required === true) {
                    setProofRequired(true);
                } else {
                    toast.dismiss();
                    toast.success("Bank details updated", {
                        type: "success"
                    });
                    props.submitInvestment();
                    document.getElementById("close-btn").dispatchEvent(new Event("click"));
                }
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setButtonLoader(false);
        });
    }

    // API - Save additional details
    const _saveAdditionalDetails = () => {
        if (frontUrl === "") {
            setFrontUrlError("Bank statement is required");
            return false;
        }
        setButtonLoader(true);
        const url = "/kyc/add-bank-proof";

        const request = {
            "bank_proof_url": frontUrl,
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Additional details submitted", {
                    type: "success"
                });
                props.submitInvestment();
                document.getElementById("close-btn").dispatchEvent(new Event("click"));
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setButtonLoader(false);
        });
    }

    // API - get file path
    const _uploadBankStatement = async (file, type) => {
        setFileUploader(true);
        const request = {
            type: "image",
            file: file.name,
            is_public: false
        }

        await _getFilePath(request).then((response) => {
            toast.dismiss();
            _uploadDocumentToS3(response.data, file).then((result) => {
                setBankStatementName(file.name);
                setFrontUrl(response.data.view_info);;
                setFileUploader(false);
            }).catch((error) => {
                setFileUploader(false);
                toast.error(error, {
                    type: "error"
                });
            });
        });
    }
    return (
        <div className={`modal fade ${style.e_manage_bank}`}
            id="manage-bank"
            tabIndex="-1"
            aria-labelledby="select-career"
            aria-hidden="true"
            data-bs-backdrop="true">
            {
                bankStatus === true ?
                    <div className={`modal-dialog position-relative modal-dialog-centered  ${style.e_dialog}`}>

                        <div className={`modal-content padding-12px-lr ${style.e_content} h-240px d-flex align-items-center justify-content-center`}>
                            <span id="close"
                                className="d-none"
                                data-bs-dismiss="modal" ></span>
                            <Fragment>
                                <img src={require("../../Assets/Images/KYC/bank_account_verification.gif")}
                                    alt="success"
                                    draggable={false}
                                    width={200}
                                    className="mx-auto object-fit-contain" />
                            </Fragment>
                        </div>
                    </div>
                    :
                    <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                        <div className={`modal-content pb-4 ${style.e_content} `}>


                            {
                                proofRequired === true ?
                                    <div className="d-block px-4 pb-3 ">
                                        <div className="d-flex align-items-start pt-3">
                                            <Icon icon="Arrow-Right"
                                                size={24}
                                                className="margin-12px-tb rotate-left cursor-pointer"
                                                onClick={() => setProofRequired(false)} />
                                            <img src={require("../../Assets/Images/Modal/failed.png")}
                                                alt="failed"
                                                width={112}
                                                height={112}
                                                className="mx-auto d-flex justify-content-center" />
                                        </div>
                                        <h6 className="color-eerie-black e-font-16 e-line-height-32 e-montserrat-semi-bold mb-0 text-center">Couldn’t verify your bank </h6>
                                        <p className="color-gunmental e-font-14 e-line-height-24 e-montserrat-regular mb-0">
                                            We were unable to verify your bank account through the penny drop method. To complete the verification, kindly upload your bank proof. Once submitted, our team will manually verify the documents provided.
                                        </p>
                                        <Icon icon="close-btn"
                                            id="close-btn"
                                            size={24}
                                            color={Color.eerie_black}
                                            className="cursor-pointer d-none"
                                            data-bs-dismiss="modal"
                                            onClick={props.closeModal} />
                                    </div>
                                    :
                                    <div className="pt-4 px-4 pb-3 d-flex justify-content-between align-items-center border-bottom-1px border-ash-gray">
                                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-eerie-black mb-0">
                                            {edit === true ? "Edit" : "Add"} Bank Details
                                        </p>
                                        <Icon icon="close-btn"
                                            id="close-btn"
                                            size={24}
                                            color={Color.eerie_black}
                                            className="cursor-pointer"
                                            data-bs-dismiss="modal"
                                            onClick={props.closeModal} />
                                    </div>
                            }

                            <div className="ps-4">
                                {
                                    proofRequired ?
                                        <div className="mt-3 pe-4 pb-2">
                                            <CustomFileInput label="Upload Bank Statement(Max.file size 5mb)"
                                                postfix="*"
                                                file={bankStatementName}
                                                loader={fileUploader}
                                                error={frontUrlError}
                                                accept=".png, .jpg, .jpeg"
                                                onFileChange={_handleBankUpload}
                                                clear={() => {
                                                    setFrontUrl("");
                                                    setFrontUrlError("");
                                                }} />
                                        </div>
                                        :
                                        <div className={style.e_fields}>
                                            <div className="mt-3">
                                                <CustomTextInput label="Account Number"
                                                    postfix="*"
                                                    type="number"
                                                    value={accountNumber}
                                                    error={accountNumberError}
                                                    inputClass="e-kyc-input"
                                                    placeholder="Enter Account Number"
                                                    handleChange={_handleAccNumber} />
                                            </div>
                                            {/* <div className="mt-4">
                                        <CustomTextInput label="Account Holder"
                                            postfix="*"
                                            value={name}
                                            error={nameError}
                                            inputClass="e-kyc-input"
                                            placeholder="Account Holder Name"
                                            handleChange={_handleName} />
                                    </div> */}
                                            <div className="mt-4 mb-3">
                                                <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-0 bg-transparent`}>
                                                    Account Type
                                                    <span className='color-deep-carmine-pink'>
                                                        *
                                                    </span>
                                                </p>
                                                <RadioGroup data={accountTypes}
                                                    selected={accountType}
                                                    handleSelect={_handleAccType} />
                                            </div>
                                            <CustomTextInput label="IFSC Code"
                                                postfix="*"
                                                value={ifsc}
                                                error={ifscError}
                                                inputClass="text-transform-upper e-kyc-input"
                                                placeholder="Eg:KKBKK000592"
                                                handleChange={_handleIFSC} />
                                            <div className="d-flex align-items-center mt-4 gap-8px">
                                                <Icon icon={isPrimary ? "checked" : "unchecked"}
                                                    size={24}
                                                    className="cursor-pointer"
                                                    onClick={_handlePrimary} />
                                                <span className="e-montserrat-medium e-font-14 e-line-height-24 color-eerie-black mb-0 cursor-pointer"
                                                    onClick={_handlePrimary}>Set as primary</span>
                                            </div>
                                            {isPrimary === true && <div className="d-flex align-items-center my-4 gap-8px">
                                                <Icon icon={createMandate ? "checked" : "unchecked"}
                                                    size={24}
                                                    className="cursor-pointer"
                                                    onClick={_handleMandate} />
                                                <span className="e-montserrat-medium e-font-14 e-line-height-24 color-eerie-black mb-0 cursor-pointer"
                                                    onClick={_handleMandate}>Update my existing SIPs with new bank mandate</span>
                                            </div>}
                                        </div>
                                }
                                {/* <div className="d-flex gap-8px e-bg-ivory-bridge p-2 pe-4 border-radius-12px">
                            <span>
                                <Icon icon="info-circle-yellow"
                                    size={20} />
                            </span>
                            <p className="e-montserrat-regular e-font-14 e-line-height-20 color-vivid-orange mb-0">We'll debit <span className="e-montserrat-bold">₹1</span> from your  account to verify the details. We’ll refund this once the
                                verification is completer .</p>
                        </div> */}
                                <div className="row mt-4 pe-4">
                                    <div className="col-6">
                                        <SecondaryButton label="Cancel"
                                            cancel="modal"
                                            className="w-100 padding-12px-tb" />
                                    </div>
                                    <div className="col-6">
                                        <PrimaryButton label={edit === true ? "Update" : "Verify"}
                                            className="w-100 padding-12px-tb"
                                            onPress={proofRequired ? _saveAdditionalDetails : _validate}
                                            disabled={buttonLoader}
                                            showLoader={buttonLoader} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default ManageBank