/*
 *   File : edit-sip.js
 *   Author : https://evoqins.com
 *   Description : Modal component to get data for Edit/Restart SIP.
 *   Integrations : lodash
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

// Components
import { CustomAmountInput, CustomPINInput, RadioGroup } from "../FormElements";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { SIPDateModal } from ".";
import { Icon } from "../Icon";
import { CustomDropdown } from "../Dropdown";

// Styles
import style from "../../Styles/Components/edit-sip.module.scss";
import Color from "../../Styles/color.module.scss";

// Services, helper
import APIService from "../../Services/api-service";
import { _findNearestDate, _formatSip, _getTomorrowDate, useCountdown, WEEKLY_OPTIONS } from "../../Helper/helper";
import { _generate2Fa } from "../../Helper/api";

const EditSIP = (props) => {

    const PROFILE_DETAILS = useSelector(state => state.Reducer.PROFILE_DETAILS);
    const MASKED_NUMBER = PROFILE_DETAILS.phone.masked_number;

    const [sipAmount, setSipAmount] = useState("");
    const [sipAmountError, setSipAmountError] = useState("");
    const [toggleDateModal, setToggleDateModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDateString, setSelectedDateString] = useState("");
    const [screenNumber, setScreenNumber] = useState(1);
    const { seconds, resetCountdown } = useCountdown(30);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [editModalData, setEditModalData] = useState({});
    const [modalLoader, setModalLoader] = useState(false);
    const [frequencyOptions, setFrequencyOptions] = useState([]);
    const [frequencySelected, setFrequencySelected] = useState(null);

    const [daySelected, setDaySelected] = useState(null);
    const [daySelectedError, setDaySelectedError] = useState("");

    useEffect(() => {
        var my_modal = document.getElementById("edit-sip");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
            document.body.removeAttribute("style")
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.fund.sip_frequency_options && props.fund.sip_frequency_options.length !== 0) {
            const frequencies = props.fund.sip_frequency_options.map((item) => {
                return ({
                    name: item.label, id: item.value
                })
            })
            setFrequencyOptions(frequencies);
            setFrequencySelected(frequencies[0].id);
        }
    }, [props.fund.sip_frequency_options]);

    useEffect(() => {
        setSipAmount(props.fund.amount);
        setFrequencySelected(props.fund.frequency);
        if (props.fund.frequency === "day_in_a_week" || props.fund.frequency === "day_in_a_fortnight") {
            const get_weekly_object = WEEKLY_OPTIONS.find((item) => item.value === props.fund.date);
            if (get_weekly_object) {
                setDaySelected(get_weekly_object)
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (frequencySelected !== null && props.fund.sip_frequency) {
            setSelectedDate(_findNearestDate(props.fund.sip_frequency[`${frequencySelected}`].dates, props.fund.date ? props.fund.date : _getTomorrowDate()));

            const date_string = _formatSip(_findNearestDate(props.fund.sip_frequency[`${frequencySelected}`].dates, props.fund.date ? props.fund.date : _getTomorrowDate()));
            setSelectedDateString(date_string);
        } else {
            setSelectedDate(_findNearestDate(props.fund.sip_dates, props.fund.date ? props.fund.date : _getTomorrowDate()));

            const date_string = _formatSip(_findNearestDate(props.fund.sip_dates, props.fund.date ? props.fund.date : _getTomorrowDate()));
            setSelectedDateString(date_string);
        }

        // eslint-disable-next-line
    }, [frequencySelected, props.fund.sip_frequency]);

    useEffect(() => {
        if (toggleDateModal === true) {
            const modal = new Modal(document.getElementById("sip-date"), {});
            modal.show();
        }
    }, [toggleDateModal]);

    // handle amount
    function _handleAmount(input_value) {
        setSipAmount(input_value);
        setSipAmountError("");
    }

    // show sip date modal
    function _handleCloseModal(status) {
        setToggleDateModal(status);
    }

    function _handleWeeklyDate(dropdown_value) {
        setDaySelected(dropdown_value);
        setSelectedDate(dropdown_value.value);
        setDaySelectedError("");
    }

    function _handleFrequency(select_value) {
        setFrequencySelected(select_value);
    }

    // handle sip date
    function _handleSelectDate(date, dateString) {
        setSelectedDate(date);
        setSelectedDateString(dateString);
    }

    const _validate = () => {
        let valid = true;
        if (sipAmount.length === 0) {
            setSipAmountError(`${_getFrequencyLabel(frequencySelected)} SIP amount cannot be empty`);
            valid = false;
        }
        if (daySelected === null && (frequencySelected === "day_in_a_week" || frequencySelected === "day_in_a_fortnight")) {
            valid = false;
            setDaySelectedError("Please select day for SIP");
        }
        if (valid) {
            // document.getElementById("close-btn").dispatchEvent(new Event("click"));
            // props.save(sipAmount, selectedDate, props.fund.sip_id, props.type);
            _save();
        }
    }

    // API - consent call
    const _save = () => {
        _generate2Fa("Edit SIP", sipAmount)
            .then((response) => {
                setEditModalData({
                    sip_id: props.fund.sip_id,
                    amount: sipAmount,
                    date: selectedDate,
                    type: props.fund.investment_type
                })
                setScreenNumber(2);
                resetCountdown();

            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    }

    // handle OTP
    function _handleOTP(input_value) {
        if (input_value.length <= 4) {
            setOtp(input_value);
        }
        setOtpError("");
    }

    // resend OTP
    function _handleResend() {
        setOtp("");
        setOtpError("");
        _generate2FaApi();
    }

    // API - resend otp
    const _generate2FaApi = () => {
        _generate2Fa("Edit SIP", sipAmount)
            .then((response) => {
                resetCountdown();
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    };

    // handle submit otp validation
    function _submitOTP() {
        let valid = true;
        if (otp === "") {
            setOtpError("OTP is required");
            valid = false;
        }
        if (otp !== "" && otp.length !== 4) {
            setOtpError("Invalid OTP");
            valid = false;
        }
        if (valid) {
            setModalLoader(true);
            _editActiveSIP();
        }
    }

    const _getFrequencyLabel = (frequency) => {
        let label = "Monthly"
        if (frequency !== null && frequencyOptions.length > 0) {
            const index = frequencyOptions.findIndex((item) => item.id === frequency)
            label = (frequencyOptions[index].name)
        }

        return label
    }

    // API -edit SIP / step up SIP
    const _editActiveSIP = () => {
        let url = "/sip/edit";
        let request = {
            "sip_id": editModalData.sip_id,
            "sip_date": frequencySelected === "daily" ? null : editModalData.date,
            "amount": editModalData.amount,
            "type": props.type === 1 ? null : "ACTIVATE",
            "otp": otp,
            "frequency": frequencySelected,
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setEditModalData({});
                document.getElementById("close-btn").dispatchEvent(new Event("click"));
                props.success(editModalData.type);
                toast.success(response.message, {
                    type: "success",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setModalLoader(false);
        })
    };


    const _handleBack = () => {
        setScreenNumber(1);
        setOtp("");
        setOtpError("");
    }

    return (
        <Fragment>
            <div className={`modal fade ${style.e_edit_sip}`}
                id="edit-sip"
                tabIndex="-1"
                aria-labelledby="edit-sip"
                data-bs-backdrop="static"
                aria-hidden="true"

                data-bs-keyboard="false">
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content ${style.e_content} pb-4`}>

                        <div className="pt-4 px-4 pb-3 d-flex justify-content-between align-items-center border-bottom-1px border-ash-gray">
                            {
                                screenNumber === 1 ?
                                    <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-eerie-black mb-0">{props.header}</p>
                                    :
                                    <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-eerie-black mb-0">
                                        <Icon icon="Arrow-Right"
                                            size={22}
                                            className="e-transform-left-arrow cursor-pointer me-2"
                                            onClick={_handleBack} />
                                        Enter OTP
                                    </p>
                            }
                            <Icon icon="close-btn"
                                id="close-btn"
                                size={24}
                                color={Color.eerie_black}
                                className="cursor-pointer"
                                data-bs-dismiss="modal"
                                onClick={props.closeModal} />
                        </div>
                        {
                            screenNumber === 1 ?
                                <Fragment>
                                    <div className={`${style.e_height} px-4 pt-3`}>                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black">Customize your SIP details</p>
                                        <div className={`${style.e_fund_border} p-3 border-radius-16px`}>
                                            <p className="e-montserrat-medium e-font-12 e-line-height-16 color-black mb-2">{props.filterType.label}</p>
                                            <div className="d-flex align-items-center gap-8px">
                                                <img src={props.fund.icon}
                                                    alt={props.fund.name}
                                                    width={38}
                                                    height={38}
                                                    draggable={false}
                                                    className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                                <p className="e-montserrat-medium e-font-14 e-line-height-20 color-eerie-black mb-0">{props.fund.name}</p>
                                            </div>
                                        </div>
                                        <CustomAmountInput className="mt-3"
                                            value={sipAmount}
                                            postfix="*"
                                            error={sipAmountError}
                                            label={`${_getFrequencyLabel(frequencySelected)} SIP Amount`}
                                            placeholder="50,000"
                                            handleChange={_handleAmount} />

                                        {frequencyOptions.length > 0 && <div className="mt-4 mb-3">
                                            <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-0 bg-transparent`}>
                                                Select Frequency
                                            </p>

                                            <RadioGroup data={frequencyOptions}
                                                selected={frequencySelected}
                                                handleSelect={_handleFrequency} />

                                        </div>}
                                        {
                                            frequencySelected !== "daily" ?
                                                frequencySelected === "monthly" ?
                                                    <Fragment>
                                                        <p className="e-font-14 e-line-height-24 e-montserrat-medium color-eerie-black mb-2 mt-3">
                                                            Select SIP date
                                                        </p>
                                                        {/* SIP component */}
                                                        <div className="padding-14px-tb px-3 bg-white box-shadow-charleston border-1px border-light-grayish-blue d-flex justify-content-between align-items-center border-radius-12px cursor-pointer"
                                                            onClick={() => _handleCloseModal(true)}>
                                                            <span className="color-rebecca-purple e-font-14 e-montserrat-semi-bold e-line-height-18">{selectedDateString} {frequencySelected === "monthly" && "of every month"}</span>
                                                            <Icon icon="dob"
                                                                size={24} />
                                                        </div>
                                                    </Fragment>
                                                    :
                                                    (frequencySelected === "day_in_a_week" || frequencySelected === "day_in_a_fortnight") &&
                                                    <CustomDropdown label="Select day"
                                                        postfix="*"
                                                        options={WEEKLY_OPTIONS}
                                                        value={daySelected}
                                                        placeholder="Select day"
                                                        className="mt-2 pb-4"
                                                        isSingleValue={true}
                                                        menuPlacement="top"
                                                        hideValue
                                                        error={daySelectedError}
                                                        prefix={frequencySelected === "day_in_a_fortnight" ? `Every alternate ${daySelected !== null && daySelected.label}` : `Every ${daySelected !== null && daySelected.label}`}
                                                        onSelectChange={_handleWeeklyDate} />
                                                :
                                                null
                                        }
                                    </div>
                                    <div className="row mt-4 d-flex justify-content-center px-4">
                                        <div className="col-6">
                                            <PrimaryButton label={props.buttonLabel}
                                                className="padding-10px-tb  w-100"
                                                onPress={_validate} />
                                        </div>
                                    </div>
                                </Fragment>
                                :
                                <div className="px-4 padding-14px-top">
                                    <p className="e-font-14 mb-2 color-eerie-black e-montserrat-regular e-line-height-24">
                                        An OTP has been sent to your PAN linked mobile
                                        number.
                                    </p>
                                    <h6 className="e-font-16 mb-3 color-eerie-black e-montserrat-semi-bold e-line-height-24">{MASKED_NUMBER}</h6>
                                    <CustomPINInput id="otp"
                                        autoFocus={true}
                                        pinString={otp}
                                        error={otpError}
                                        postfix="*"
                                        label="Enter OTP"
                                        onSubmit={_submitOTP}
                                        handleChange={_handleOTP} />
                                    <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`no-decoration color-rebecca-purple e-link 
                                    ${seconds === 0 ?
                                            'cursor-pointer'
                                            : 'disabled'}`}
                                        onClick={_handleResend}>
                                        {seconds === 0 ?
                                            'Resend OTP'
                                            : `Resend OTP in ${seconds < 10 ? `0${seconds}` : seconds}s`}
                                    </span>
                                    </p>
                                    <div className="row mt-4">
                                        <div className="col-6">
                                            <SecondaryButton label="Cancel"
                                                className="padding-12px-tb w-100 e-bg-magnolia"
                                                cancel="modal" />
                                        </div>
                                        <div className="col-6">
                                            <PrimaryButton label="Verify"
                                                className="padding-12px-tb  w-100"
                                                disabled={modalLoader}
                                                onPress={_submitOTP} />
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>


                {
                    toggleDateModal === true &&
                    <Fragment>
                        <SIPDateModal apiDates={props.fund.sip_frequency ? frequencySelected !== null ? props.fund.sip_frequency[`${frequencySelected}`].dates : props.fund.sip_dates : []}
                            selectedSIPDate={selectedDate}
                            handleSelect={_handleSelectDate}
                            backdrop={!toggleDateModal}
                            handleCloseDateModal={() => _handleCloseModal(false)} />
                        <div className="position-fixed bg-black opacity-1 w-100 h-100 z-index-4 top-0 start-0"></div>
                    </Fragment>
                }
            </div>

        </Fragment>
    );
}

EditSIP.defaultProps = {
    fund: {
        sip_frequency_options: []
    }
}

export default EditSIP;
