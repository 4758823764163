/*
*   File : bank-details.js
*   Author : https://evoqins.com
*   Description : Screen to fill the bank details of User
*   Version : 1.0.0
*/

// import packages
import { toast } from "react-toastify";
import { Fragment, useState, useEffect } from "react";
import { Modal } from "bootstrap";

// import components
import { StepProgress } from "../../../Components/Other";
import { CustomFileInput, CustomTextInput, RadioGroup } from "../../../Components/FormElements";
import { KYCProgressCard } from "../../../Components/Card";
import { Icon } from "../../../Components/Icon";
import { PrimaryButton } from "../../../Components/Buttons";
import { KYCErrorCard } from "../../../Components/Cards";
import { BankVerificationModal, BankSubmissionSuccess } from "../../../Components/Modal";

// import services
import APIService from "../../../Services/api-service";
import { _getFilePath } from "../../../Helper/api";
import { _uploadDocumentToS3 } from "../../../Services/aws-service";
import { _getFileName, _getOptions } from "../../../Helper/helper";

// import store
import store from "../../../Store";

const BankDetails = (props) => {

    const PROGRESS_DATA = store.getState().Reducer.SIGNUP_PROGRESS;
    const GENERAL_DATA = store.getState().Reducer.GENERAL_DATA;

    const [accountTypes, setAccountTypes] = useState([]);
    const [accountNumber, setAccountNumber] = useState("");
    const [accountNumberError, setAccountNumberError] = useState("");

    const [name, setName] = useState("");
    // const [nameError, setNameError] = useState("");

    const [accountType, setAccountType] = useState(null);

    const [ifsc, setIfsc] = useState("");
    const [ifscError, setIfscError] = useState("");

    const [bankUrl, setBankUrl] = useState("");
    const [bankUrlError, setBankUrlError] = useState("");
    const [fileName, setFileName] = useState("");

    const [kycUser, setKycUser] = useState(false);

    const [fileUploader, setFileUploader] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);

    const [bankStatus, setBankStatus] = useState(false);

    const [errors, setErrors] = useState([]);
    const [bankVerificationPending, setBanKVerificationPending] = useState(false);

    const [frontUrl, setFrontUrl] = useState("");
    const [bankStatementName, setBankStatementName] = useState("");

    const [frontUrlError, setFrontUrlError] = useState("");

    const [detailsSubmitted, setDetailsSubmitted] = useState(false);

    useEffect(() => {
        // account types options
        let account_types = [];
        if (PROGRESS_DATA.kyc_data.is_nri === true) {
            account_types = _getOptions(GENERAL_DATA.nri_bank_account_types, 1);
        }
        else {
            account_types = _getOptions(GENERAL_DATA.ri_bank_account_types, 1);
        }
        setAccountType(account_types[0].id);
        setAccountTypes(account_types);

        //eslint-disable-next-line
    }, [GENERAL_DATA]);

    useEffect(() => {
        if (bankStatus === true) {
            const modal = new Modal(document.getElementById("bank-verification"));
            modal.show();
        }
    }, [bankStatus]);

    useEffect(() => {
        if (detailsSubmitted === true) {
            const modal = new Modal(document.getElementById("bank-submission"));
            modal.show();
        }
    }, [detailsSubmitted]);

    useEffect(() => {
        const signup_progress = PROGRESS_DATA
        setKycUser(signup_progress.kyc_data.is_kyc_compliant);
        if (signup_progress.step_detail[5].error === true) {
            setErrors(signup_progress.step_detail[5].message);
        }
        if (Object.keys(signup_progress.kyc_data.bank_details).length > 0) {
            setAccountNumber(signup_progress.kyc_data.bank_details.account_number !== null ? signup_progress.kyc_data.bank_details.account_number : "");
            setName(signup_progress.kyc_data.bank_details.account_holder_name !== null ? signup_progress.kyc_data.bank_details.account_holder_name : "");
            setAccountType(signup_progress.kyc_data.bank_details.account_type_id !== null ? signup_progress.kyc_data.bank_details.account_type_id : 1);
            setIfsc(signup_progress.kyc_data.bank_details.ifsc !== null ? signup_progress.kyc_data.bank_details.ifsc : "");
            if (signup_progress.kyc_data.bank_proof_url !== null) {
                setBankUrl(signup_progress.kyc_data.bank_proof_url);
                setFileName(_getFileName(signup_progress.kyc_data.bank_proof_url));
            }
        }
    }, [PROGRESS_DATA]);

    // handle Acc number
    function _handleAccNumber(input_value) {
        setAccountNumber(input_value);
        setAccountNumberError("");
    }

    // handle Acc holder name
    // function _handleName(input_value) {
    //     setName(input_value);
    //     setNameError("");
    // }

    // handle Acc type
    function _handleAccType(id) {
        setAccountType(id);
    }

    // handle IFSC
    function _handleIFSC(input_value) {
        setIfsc(input_value);
        setIfscError("");
    }

    // handle esign modal
    function _verifyBank(status, is_pending) {
        setBankStatus(status);
        if (is_pending !== undefined) {
            setBanKVerificationPending(is_pending)
        }
    }

    // function handle PAN
    function _handleFileUpload(file) {
        if (file.size > 5242880) {
            setBankUrlError("Upload Statement below 5MB");
        } else {
            _uploadBankStatement(file, 1);
            setBankUrlError("");
        }
    }

    function _handleBankUpload(file) {
        if (file.size > 5242880) {
            setFrontUrlError("Upload Statement below 5MB");
        } else {
            _uploadBankStatement(file, 2);
            setFrontUrlError("");
        }
    }



    // handle form validtion 
    function _submitBankDetails() {
        let valid = true;
        const ifsc_value = ifsc.trim()
        // const name_value = name.trim()
        if (accountNumber === "") {
            setAccountNumberError("Account number cannot be empty");
            valid = false
        }
        // if (name_value === "") {
        //     setNameError("Account holder name cannot be empty");
        //     valid = false
        // }
        if (ifsc_value === "") {
            setIfscError("IFSC code cannot be empty");
            valid = false
        }
        if (kycUser === false && bankUrl === "") {
            setBankUrlError("Upload a bank statement");
            valid = false
        }

        if (valid === true) {
            _saveBank();
        }

    }

    // submit additional details
    function _submitAdditionalDetails() {
        let valid = true;
        // if (selectedDocument === null) {
        //     setSelectedDocumentError("Document is required");
        //     valid = false
        // }
        if (frontUrl === "") {
            setFrontUrlError("Bank statement is required");
            valid = false
        }
        if (valid === true) {
            _saveAdditionalDetails();
        }
    }

    // API - Save bank details
    const _saveBank = () => {
        setApiLoader(true);
        const url = "/kyc/save-bank";

        const request = {
            "account_number": accountNumber,
            "account_holder_name": name,
            "account_type": accountType,
            "ifsc": ifsc.toLocaleUpperCase(),
            "bank_proof_url": kycUser ? null : bankUrl
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                if (response.data.bank_verification_required === true) {
                    _verifyBank(true);
                } else if (response.data.proof_required === true) {
                    setBanKVerificationPending(true);
                }
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }

    // API - Save additional details
    const _saveAdditionalDetails = () => {
        setApiLoader(true);
        const url = "/kyc/add-bank-proof";

        const request = {
            "bank_proof_url": frontUrl,
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                setDetailsSubmitted(true);
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }

    // API - get file path
    const _uploadBankStatement = async (file, type) => {
        setFileUploader(true);
        const request = {
            type: "image",
            file: file.name,
            is_public: false
        }

        await _getFilePath(request).then((response) => {
            toast.dismiss();
            _uploadDocumentToS3(response.data, file).then((result) => {
                if (type === 1) {
                    setFileName(file.name);
                    setBankUrl(response.data.view_info);
                } else {
                    setBankStatementName(file.name);
                    setFrontUrl(response.data.view_info);
                }
                setFileUploader(false);
            }).catch((error) => {
                setFileUploader(false);
                toast.error(error, {
                    type: "error"
                });
            });
        });
    }



    return (
        <Fragment>
            <Icon icon="Arrow-Right"
                size={32}
                className="margin-12px-tb rotate-left cursor-pointer"
                onClick={() => bankVerificationPending === true ? setBanKVerificationPending(false) : props.navigateBack()} />



            {
                bankVerificationPending === true ?
                    <div className="row pt-lg-3 pt-0 justify-content-center">
                        <div className="col-xl-6 col-lg-7 col-md-8 offset-md-1">
                            <img src={require("../../../Assets/Images/Modal/failed.png")}
                                alt="failed"
                                width={114}
                                height={114}
                                className="mx-auto d-flex justify-content-center" />
                            <h6 className="color-eerie-black e-font-16 e-line-height-32 e-montserrat-semi-bold mb-2 mt-4 text-center">Couldn’t verify your bank </h6>
                            <p className="color-gunmental e-font-16 e-line-height-32 e-montserrat-regular mb-0">
                                We were unable to verify your bank account through the penny drop method. To complete the verification, kindly upload your bank proof. Once submitted, our team will manually verify the documents provided.
                            </p>
                            <div className="row padding-20px-top">


                                <div className="col-12 pb-4">
                                    <CustomFileInput label="Upload Bank Statement(Max.file size 5mb)"
                                        postfix="*"
                                        file={bankStatementName}
                                        loader={fileUploader}
                                        error={frontUrlError}
                                        accept=".png, .jpg, .jpeg"
                                        onFileChange={_handleBankUpload}
                                        clear={() => {
                                            setFrontUrl("");
                                            setFrontUrlError("");
                                        }} />
                                </div>

                                <div className="border-top-1px border-bright-gray my-lg-4 my-3" />
                                <div className="col-12">
                                    <PrimaryButton label="Save & Continue"
                                        className="padding-12px-tb w-100"
                                        disabled={apiLoader}
                                        onPress={_submitAdditionalDetails} />
                                </div>

                            </div>
                        </div>
                    </div>
                    :
                    <div className="row pt-lg-3 pt-0">
                        <div className="col-lg-7">

                            <StepProgress type={2}
                                step={props.completed}
                                stepNumber={kycUser ? 5 : 6}
                                maxStep={kycUser ? 6 : 8} />
                            <h6 className="color-eerie-black e-font-16 e-line-height-32 e-montserrat-semi-bold mb-0 mt-4">Bank details</h6>
                            {
                                errors.length > 0 &&
                                <KYCErrorCard errors={errors} />
                            }
                            <div className="row padding-20px-top">
                                <div className="col-lg-5 col-md-6 col-sm-6 pb-4">
                                    <CustomTextInput label="Account number"
                                        postfix="*"
                                        type="number"
                                        value={accountNumber}
                                        error={accountNumberError}
                                        inputClass="e-kyc-input"
                                        placeholder="Eg:10230400056789"
                                        handleChange={_handleAccNumber} />
                                </div>
                                {/* <div className="col-lg-5 col-md-6 col-sm-6 pb-4">
                            <CustomTextInput label="Account holder name"
                                postfix="*"
                                value={name}
                                error={nameError}
                                inputClass="e-kyc-input"
                                placeholder="Eg:James Bond"
                                handleChange={_handleName} />
                        </div> */}
                                <div className="col-lg-12 col-md-6 col-sm-6 pb-3">
                                    <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-0 bg-transparent`}>
                                        Account type
                                        <span className='color-deep-carmine-pink'>
                                            *
                                        </span>
                                    </p>
                                    <RadioGroup data={accountTypes}
                                        selected={accountType}
                                        handleSelect={_handleAccType} />
                                </div>
                                <div className="col-lg-5 col-md-6 col-sm-6 pb-4">
                                    <CustomTextInput label="IFSC code"
                                        postfix="*"
                                        value={ifsc}
                                        error={ifscError}
                                        inputClass="text-transform-upper e-kyc-input"
                                        placeholder="Eg:KKBK0000593"
                                        handleChange={_handleIFSC} />
                                </div>
                                {kycUser === false && <div className="col-lg-5 col-md-6 col-sm-6 pb-4">
                                    <CustomFileInput label="Upload Bank Statement"
                                        postfix="*"
                                        file={fileName}
                                        loader={fileUploader}
                                        error={bankUrlError}
                                        accept=".png, .jpg, .jpeg"
                                        onFileChange={_handleFileUpload}
                                        clear={() => {
                                            setFileName("");
                                            setBankUrl("");
                                        }} />
                                </div>}

                                <div className="border-top-1px border-bright-gray my-lg-4 my-3" />
                                <div className="row">
                                    <div className="col-xl-3 col-lg-5 d-lg-block d-none">
                                        <PrimaryButton label="Save & Continue"
                                            className="padding-12px-tb w-100"
                                            disabled={apiLoader}
                                            onPress={_submitBankDetails} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <KYCProgressCard steps={props.investment_steps}
                                completedStep={props.completedStep} />

                        </div>
                    </div>
            }

            {bankVerificationPending === false && <div className="row justify-content-center">
                <div className="col-lg-5 col-sm-6 col-6 d-lg-none d-block  pt-3">
                    <PrimaryButton label="Save & Continue"
                        className="padding-12px-tb w-100"
                        disabled={apiLoader}
                        onPress={_submitBankDetails} />
                </div>
            </div>}



            {
                bankStatus === true &&
                <BankVerificationModal
                    closeModal={() => _verifyBank(false)}
                    submitInvestment={() => props.handleNavigate()}
                    reSubmit={(status) => _verifyBank(false, status)}
                />
            }

            {
                detailsSubmitted === true && <BankSubmissionSuccess title={"Successfully submitted"}
                    type={1}
                    showButton={true}
                    content={"Your bank proof has been successfully uploaded. Our team will review it and get in touch with you shortly."}
                    handleNavigate={() => props.handleNavigate()}
                    closeModal={() => setDetailsSubmitted(false)} />
            }
        </Fragment>
    )
}

export default BankDetails